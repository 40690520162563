import React, { useState, useEffect } from 'react';
import { getCities, addCity, removeCity, getCountries, getSuburbs } from '../actions/categoryActions';
import { validate_multiple_roles } from '../validations';
import { Textbox } from 'react-inputs-validation';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link } from 'react-router-dom';

function CountriesScreen(props) {
  const [add_city_dialog, set_add_city_dialog] = React.useState(false);

  const add_city_dialog_open = () => {
    set_add_city_dialog(true);
  };

  const add_city_dialog_close = () => {
    set_add_city_dialog(false);
  };

  const [remove_city_dialog, set_remove_city_dialog] = React.useState(false);

  const remove_city_dialog_open = () => {
    set_remove_city_dialog(true);
  };

  const remove_city_dialog_close = () => {
    set_remove_city_dialog(false);
  };



  // eslint-disable-next-line
  let user_profile;
  const permitted_roles = ['admin', 'technical-support'];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = 'access-forbidden';
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  // eslint-disable-next-line
  const id = props.match.params.id ? props.match.params.id : '';

  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');

  const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);

  const [add_city, set_add_city] = useState('');


  const [remove_city, set_remove_city] = useState('');

  const [remove_city_name, set_remove_city_name] = useState('');

  const [cities, set_cities] = useState([]);
  const [countries, set_countries] = useState([]);

  const [suburbs, set_suburbs] = useState([]);

  // eslint-disable-next-line
  const [add_city_invalid, set_add_city_invalid] = useState(true);

  const load_page_data = async () => {
    let get_countries;
    try {
      get_countries = await getCountries('id', 'meta', 'status');
      if (get_countries.success) {
        const { data } = get_countries;
        set_countries(data);
      } else {
        set_error_message('No countries found');
      }
    } catch (error) {
      set_error_message(`An error occured fetching countries: ${error.message} `);
    }

    let get_cities;
    try {
      get_cities = await getCities('id', 'meta', 'status');
      if (get_cities.success) {
        const { data } = get_cities;
        set_cities(data);
      } else {
        set_error_message('No cities found');
      }
    } catch (error) {
      set_error_message(`An error occured fetching cities: ${error.message} `);
    }


    let get_suburbs;
    try {
      get_suburbs = await getSuburbs('id', 'meta', 'city', 'status');
      if (get_suburbs.success) {
        const { data } = get_suburbs;
        set_suburbs(data);
      } else {
        set_error_message('No suburbs found');
      }
    } catch (error) {
      set_error_message(`An error occured fetching suburbs: ${error.message} `);
    }


    return;
  }

  /**
   * Removes a city
   * 
   */
  const removeCityDetails = async () => {
    // e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    const response = await removeCity(remove_city);
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      let get_cities;

      try {
        get_cities = await getCities('id', 'meta', 'status');
        if (get_cities.success) {
          const { data } = get_cities;
          set_cities(data);
        } else {
          set_error_message('No cities found');
        }
      } catch (error) {
        set_error_message(`An error occured fetching cities: ${error.message} `);
      }
      set_add_city('');
      set_add_city_invalid(true);
      set_success_message('City successfully removed');
    }
    remove_city_dialog_close();
    set_action_loading(false);

    return;
  }

  /**
   * Updates user profile
   * @param {*} e 
   */
  const addNewCity = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    const response = await addCity(add_city);
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      let get_cities;

      try {
        get_cities = await getCities('id', 'meta', 'status');
        if (get_cities.success) {
          const { data } = get_cities;
          set_cities(data);
        } else {
          set_error_message('No cities found');
        }
      } catch (error) {
        set_error_message(`An error occured fetching cities: ${error.message} `);
      }
      set_add_city('');
      set_add_city_invalid(true);
      set_success_message('New city successfully added.');
    }
    add_city_dialog_close();
    set_action_loading(false);

    return;
  }

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);



  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2><span class="green-text">Countries</span> <span class="orange-text"></span></h2>
          </div>


          {!page_loaded ? ( // so that only products will be loading at this time 
            <div>Loading ... </div>
          ) : (
            <>
              <div class="row">
                <div class="col-12">
                  <div class="form-group col-12 my-3 text-left">
                    <button
                      class="btn btn-primary"
                      type="submit"
                      onClick={add_city_dialog_open}
                      disabled={action_loading}
                    >
                      {
                        !action_loading ? ('Add country') : (
                          <>
                            <div class="spinner-border text-orange" role="status">
                            </div>
                            &nbsp;Please wait
                          </>
                        )
                      }
                    </button>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="row">
                    <div class="col-12">
                      {error_message !== '' ? (
                        <div class="alert alert-danger" role="alert">
                          {error_message}
                        </div>
                      ) : ('')}
                      {success_message !== '' ? (
                        <div class="alert alert-success" role="alert">
                          {success_message}
                        </div>
                      ) : ('')}
                    </div>

                    <div class="col-12">
                      <div class="row">
                        <div class="table-responsive">
                          <table class="table table-bordered table-striped">
                            {countries.length > 0 ? (
                              countries.map((country) => (
                                <tr key={country._id} class="my-5">
                                  <td>
                                    {/* <Link
                                      to={`/distance-table/country/${country._id}`}
                                      type="submit"
                                    > */}
                                      {country.country}
                                    {/* </Link> */}
                                    </td>
                                  <td>
                                    <table class="table table-bordered">
                                      {cities.length > 0 ? (
                                        cities.map((city) => (
                                          city.country === country.meta ? (
                                            <tr>
                                              <td>
                                                {/* <Link
                                                  to={`/distance-table/city/${city._id}`}
                                                  type="submit"
                                                > */}
                                                  {city.city}
                                                {/* </Link> */}
                                              </td>
                                              <td>
                                                <table class="table table-bordered">
                                                  {suburbs.length > 0 ? (
                                                    suburbs.map((suburb) => (
                                                      suburb.city === city.meta ? (
                                                        <tr>
                                                          <td>
                                                            
                                                              {suburb.suburb}
                                                            
                                                          </td>
                                                        </tr>) : (<></>)
                                                    ))) : (<></>)}
                                                </table>
                                              </td>
                                            </tr>) : (<></>)
                                        ))) : (<></>)}
                                    </table>
                                  </td>
                                </tr>
                              ))
                            ) : ('No countries found.')
                            }
                          </table>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </>
          )}

        </div>

        <Dialog open={add_city_dialog} onClose={add_city_dialog_close} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Enter city details</DialogTitle>
          <DialogContent>
            {/* <DialogContentText> */}
            <form onSubmit={addNewCity}>

              <div class="form-group col-12 my-3">
                <label for="">City name</label>
                <Textbox
                  attributesWrapper={{}}
                  attributesInput={{
                    id: "city",
                    name: "City name",
                    type: "text",
                    placeholder: ""
                  }}
                  // validate={validate}
                  validationCallback={res =>
                    set_add_city_invalid(res)
                  }
                  value={add_city}
                  disabled={false}
                  classNameInput=""
                  classNameWrapper=""
                  classNameContainer=""
                  customStyleInput={{}}
                  customStyleWrapper={{}}
                  customStyleContainer={{}}
                  onChange={(add_city, e) => {
                    set_add_city(e.target.value);
                  }}
                  onBlur={e => { }}
                  validationOption={{
                    name: "City name",
                    check: true,
                    required: true,
                    type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                  }}
                />

              </div>
            </form>
            {/* </DialogContentText> */}
          </DialogContent>
          <DialogActions>
            <button
              class="btn btn-lg btn-success"
              type="submit"
              onClick={add_city_dialog_close} color="primary">
              Cancel
            </button>

            <button
              class="btn btn-lg btn-success"
              type="submit"
              onClick={addNewCity} color="primary"
              disabled={action_loading || add_city.length < 2} >
              Add city
            </button>
          </DialogActions>
        </Dialog>


        <Dialog open={remove_city_dialog} onClose={remove_city_dialog_close} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Are you sure you want to delete <b>{remove_city_name}</b> </DialogTitle>
          <DialogContent>
            <DialogContentText>
              This action will delete the city and you cannot undo this action.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              class="btn btn-lg btn-success"
              type="submit"
              onClick={remove_city_dialog_close} color="primary">
              Cancel
            </button>

            <button
              class="btn btn-lg btn-success"
              type="submit"
              onClick={removeCityDetails} color="primary">
              Yes, I'm sure
            </button>
          </DialogActions>
        </Dialog>
      </section>



    </>
  );
}
export default CountriesScreen;
