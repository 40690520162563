import React, { useState, useEffect } from 'react';
import { validate_multiple_roles } from '../validations';
import { getOrderItems, updateOrderItem } from '../actions/orderActions';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { Textbox } from 'react-inputs-validation';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

function AdminOrderItemScreen(props) {

  const id = props.match.params.id ? props.match.params.id : '';

  // eslint-disable-next-line
  let user_profile;
  const permitted_roles = ['admin', 'sales-support'];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = 'access-forbidden';
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  const [invite_seller_dialog, set_invite_seller_dialog] = React.useState(false);

    const invite_seller_dialog_open = () => {
      set_invite_seller_dialog(true);
    };
    
    const invite_seller_dialog_close = () => {
      set_invite_seller_dialog(false);
    };

    const [accept_invite_dialog, set_accept_invite_dialog] = React.useState(false);

    const accept_invite_dialog_open = () => {
      set_accept_invite_dialog(true);
    };
    
    const accept_invite_dialog_close = () => {
        set_accept_invite_dialog(false);
    };

  const [error_message, set_error_message] = useState('');
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState('');
  const [page_loaded, set_page_loaded] = useState(false);

  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);
  
  // eslint-disable-next-line
  const [order_details, set_order_details] = useState('');

  const [add_seller, set_add_seller] = useState('');
  // eslint-disable-next-line 
  const [add_seller_invalid, set_add_seller_invalid] = useState(false);

  const [acceptance_details, set_acceptance_details] = useState({});
  const [preparation_details, set_preparation_details] = useState({});
  const [ready_details, set_ready_details] = useState({});
  const [collected_details, set_collected_details] = useState({});
  const [paid_details, set_paid_details] = useState({});

    

  const load_page_data = async () => { 

    set_action_loading(true);
    let get_seller_orders;

     try {
        get_seller_orders = await getOrderItems(id, 'order_id', user_profile._id, 'product', 'status');
        if (get_seller_orders.success) {
          const { data } = get_seller_orders;
          set_order_details(data[0]);
          set_acceptance_details(data[0].acceptance);
          set_preparation_details(data[0].preparation);
          set_ready_details(data[0].ready);
          set_collected_details(data[0].collected);
          set_paid_details(data[0].paid);        
        } else {
          set_error_message(get_seller_orders.message);
        }
      } catch (error) {
        set_error_message(`An error occured fetching seller orders: ${error.message} `);
      }

    // set_orders_to_display(_orders_to_display);

    set_action_loading(false);
    return;
  }


    /**
   * Makes an action
   * 
   */
  const inviteSeller = async () =>  {
    // e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    const update_data = {
        seller_id: add_seller,
        status: 'invited',
    }

    const response = await updateOrderItem(update_data, id, 'invited'); 
    if (response.success === false) {
        set_error_message(response.message); 
    } else {    
      set_success_message('Invitation successfully sent');
      load_page_data();
    }

    invite_seller_dialog_close();
    set_action_loading(false);

    return; 
  }

  /**
   * Accept seller invite
   * 
   */
  const acceptSeller = async () =>  {
    // e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    const update_data = {
        status: 'accepted',
    }

    const response = await updateOrderItem(update_data, id, 'accepted'); 
    if (response.success === false) {
        set_error_message(response.message); 
    } else {    
      set_success_message('Invitation accepted');
      load_page_data();
    }

    accept_invite_dialog_close();
    set_action_loading(false);

    return; 
  }

  useEffect(() => {
    if (page_loaded === false ) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  

  return (
    <>
    <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
  <h2><span class="black-text">Viewing Order Item</span> <br/> <span class="green-text">{order_details.quantity} x {order_details.name}</span></h2>
        </div>       


        {!page_loaded  ? ( // so that only products will be loading at this time 
          <div>Loading ... </div>
        ) : (
          <>
            <div class="row">
              <div class="col-12">
                  {error_message !== '' ? (
                      <div class="alert alert-danger" role="alert">
                        {error_message}
                      </div>
                    ): ('')}
                    {success_message !== '' ? (
                      <div class="alert alert-success" role="alert">
                          {success_message}
                      </div>
                    ): ('')}     
                </div>
            </div>
          
          <div class="row">
                
            </div>


            <div class="row">

              <div class="col-3"></div>
              <div class="col-6">
              <div class="table-responsive mb-5">
                <table class="table table-bordered">
                  {/* <thead>
                    <tr>
                      <th>Item</th>
                      <th>Details</th>
                    </tr>
                  </thead> */}

                  <tbody>
                    <tr>
                      <th>Order Number</th>
                    <th>{order_details.display_order_number}</th>
                    </tr>

                    <tr>
                      <th>Product</th>
                    <th>{order_details.name}</th>
                    </tr>

                    <tr>
                      <th>Quantity Required</th>
                    <th>{order_details.quantity}</th>
                    </tr>

                    <tr>
                      <th>Invited seller</th>
                        <th>
                        { order_details.status === 'invited' || order_details.status === 'accepted' || order_details.status === 'preparing' ? (
                                <>
                                   <button onClick={() => invite_seller_dialog_open() } class="btn btn-primary" >Invite seller</button>
                                   <br/>
                                </>
                            ):('') }
                            {order_details.seller_name}
                            
                            { order_details.status === 'invited' ? (
                                <>
                                   <button onClick={() => accept_invite_dialog_open() } class="btn btn-primary" >Accept invite on behalf of seller</button>
                                </>
                            ):('') }
                        </th>
                    </tr>

                    <tr>
                      <th>Next Activity</th>
                      <th>
                        { order_details.status === 'invited' ? ('Accept'):('') }
                        { order_details.status === 'accepted' ? ('Preparing'):('') }
                        { order_details.status === 'preparing' ? ('Completed'):('') }
                        { order_details.status === 'completed' ? ('Collected'):('') }
                        { order_details.status === 'collected' ? ('Paid'):('') }
                      </th>
                    </tr>

                    <tr>
                      <th>Scheduled Date of Next Activity</th>
                      <th>
                      { order_details.status === 'invited' ? (ready_details.acceptance):('') }
                        { order_details.status === 'accepted' ? (ready_details.preparation):('') }
                        { order_details.status === 'preparing' ? (ready_details.ready):('') }
                        { order_details.status === 'completed' ? (ready_details.collected):('') }
                        { order_details.status === 'collected' ? (ready_details.paid):('') }
                      </th>
                    </tr>

                    <tr>
                      <th>Scheduled Date of Collection</th>
                    <th>{ready_details.date_time}</th>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
              

              <h3>Timing of Activities for Order {order_details.display_order_number}</h3>
              <div class="table-responsive mb-5">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Activity</th>
                      <th>Status</th>
                      <th>Date and Time</th>
                      <th>Time Type</th>
                    </tr>
                    
                  </thead>

                  <tbody>
                    <tr>
                      <th>1</th>
                      <th>Acceptance</th>
                      <td>{acceptance_details.status}</td>
                      <td>{acceptance_details.date_time}</td>
                      <td>{acceptance_details.time_type}</td>
                    </tr>

                    <tr>
                      <th>2</th>
                      <th>Preparation</th>
                      <td>{preparation_details.status}</td>
                      <td>{preparation_details.date_time}</td>
                      <td>{preparation_details.time_type}</td>
                    </tr>

                    <tr>
                      <th>3</th>
                      <th>Ready for collection</th>
                      <td>{ready_details.status}</td>
                      <td>{ready_details.date_time}</td>
                      <td>{ready_details.time_type}</td>
                    </tr>

                    <tr>
                      <th>4</th>
                      <th>Collected</th>
                      <td>{collected_details.status}</td>
                      <td>{collected_details.date_time}</td>
                      <td>{collected_details.time_type}</td>
                    </tr>

                    <tr>
                      <th>5</th>
                      <th>Paid</th>
                      <td>{paid_details.status}</td>
                      <td>{paid_details.date_time}</td>
                      <td>{paid_details.time_type}</td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
              </div>
              <div class="col-3"></div>
                
                </div>

                <div class="row">
                
                </div>


            </>
        ) }
        
      </div>
    </section>

    <Dialog open={invite_seller_dialog} onClose={invite_seller_dialog_close} aria-labelledby="form-dialog-title">
												<DialogTitle id="form-dialog-title">Enter ID of seller to invite</DialogTitle>
												<DialogContent>
												{/* <DialogContentText> */}
                                                <form onSubmit={inviteSeller}>

                                                    <div class="form-group col-12 my-3"> 
                                                    <label for="">Invite seller</label>
                                                    <Textbox
                                                        attributesWrapper={{}}
                                                        attributesInput={{
                                                        id: "add_seller",
                                                        name: "Seller ID",
                                                        type: "text",
                                                        placeholder: "Seller ID"
                                                        }}
                                                        // validate={validate}
                                                        validationCallback={res =>
                                                        set_add_seller_invalid(res)
                                                        } 
                                                        value={add_seller}
                                                        disabled={false}
                                                        classNameInput=""
                                                        classNameWrapper=""
                                                        classNameContainer=""
                                                        customStyleInput={{}}
                                                        customStyleWrapper={{}}
                                                        customStyleContainer={{}}
                                                        onChange={(add_seller, e) => {
                                                        set_add_seller(e.target.value);
                                                        }}
                                                        onBlur={e => {}}
                                                        validationOption={{
                                                          name: "Seller ID",
                                                          check: true,
                                                          required: true,
                                                          type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                                                        }}
                                                    />

                                                    </div>
                                                    </form>
                                                    {/* </DialogContentText> */}
												</DialogContent>
												<DialogActions>
                          <button 
                            class="btn btn-lg btn-success" 
                            type="submit"
                            onClick={invite_seller_dialog_close} color="primary">
                            Cancel
                          </button>

                          <button 
                            class="btn btn-lg btn-success" 
                            type="submit"
                            onClick={inviteSeller} color="primary"
                            disabled={action_loading || add_seller.length < 2 } >
                              Invite
                          </button>
												</DialogActions>
											</Dialog>

                                            <Dialog open={accept_invite_dialog} onClose={accept_invite_dialog_close} aria-labelledby="form-dialog-title">
												<DialogTitle id="form-dialog-title">Are you sure?</DialogTitle>
												<DialogContent>
												{/* <DialogContentText> */}
                                                <form onSubmit={acceptSeller}>
                                                        Only continue if you've recently talked to seller and they confirmed they can supply the product
                                                    </form>
                                                    {/* </DialogContentText> */}
												</DialogContent>
												<DialogActions>
                          <button 
                            class="btn btn-lg btn-success" 
                            type="submit"
                            onClick={accept_invite_dialog_close} color="primary">
                            Cancel
                          </button>

                          <button 
                            class="btn btn-lg btn-success" 
                            type="submit"
                            onClick={acceptSeller} color="primary"
                            disabled={action_loading } >
                              Accept
                          </button>
												</DialogActions>
											</Dialog>
    </>
  );
}
export default AdminOrderItemScreen;
