import React, { useEffect, useState } from 'react';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { Link } from 'react-router-dom';


function HowItWorksScreen(props) {

  const [action_loading, set_action_loading] = useState(false);

  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');
  
  // eslint-disable-next-line
  useEffect(() => {
      if (action_loading === false) {
        set_action_loading(true);
        loadPage();
      }        
  });

  /**
   * Checks if a supplied token in link can reset password and is valid.
   */
  const loadPage = async () =>  {
    set_success_message('');
    set_error_message(''); 
  }

  return <section id="about" class="about main-content mt-5 login-content">
    <div class="container-fluid" data-aos="fade-up">
      <div class="row mt-5">
        {/* <div class="col-md-4"></div> */}
        <div class="col-md-12 text-center">
          <h3 class="green-text">Here is how Farm-Fresh Delights works</h3>
          {error_message !== '' ? (
          <div class="col-12">
            <div class="alert alert-danger" role="alert">
              {error_message}
            </div>
          </div>): ('')}

          {success_message !== '' ? (
          <div class="col-12">
            <div class="alert alert-success" role="alert">
              {success_message}
            </div>
          </div>): ('')}
        </div>


        <div class="col-md-12 mt-5 how-to-steps">
            <div class="container px-4 py-5" id="featured-3">
                <h2 class="pb-2 border-bottom">There are 6 simple steps followed for you to start earning more with Farm-Fresh Delights</h2>
                <div class="row g-4 py-5 text-center">
                    <div class="feature col-md-3">
                        <div class="feature-icon bg-primary bg-gradient">
                        </div>
                        <h2>1. Create account</h2>
                        <img src="assets/img/peleka-how-to-1.jpg" alt="How to" class="how-to-steps-img" />
                        <p>For you to start listing you need to create an account with Farm-Fresh Delights and update keep your contact details updated so that we can easily reach out to you in case of any issue.</p>
                        <Link to="/register-as-seller" class="icon-link">
                            Register now&nbsp;
                        <i class="bi bi-person" width="1em" height="1em"></i>
                        </Link>
                    </div>
                    <div class="col-md-1"></div>
                    <div class="feature col-md-3">
                        <div class="feature-icon bg-primary bg-gradient">
                        </div>
                        <h2>2. List your product</h2>
                        <img src="assets/img/peleka-how-to-2.jpg" alt="How to" class="how-to-steps-img" />
                        <p>List your product and reach out to more customers for less. We support a wide range of farm fresh product categories that you can list. You can check our product listing policy to check the different products you can list</p>
                        <Link to="/product-listing-policy" class="icon-link">
                        Check listing policy&nbsp;
                        <i class="bi bi-bag-check" width="1em" height="1em"></i>
                        </Link>
                    </div>
                    <div class="col-md-1"></div>
                    <div class="feature col-md-3">
                        <div class="feature-icon bg-primary bg-gradient">
                        </div>
                        <h2>3. Customer buys</h2>
                        <img src="assets/img/peleka-how-to-3.jpg" alt="How to" class="how-to-steps-img" />
                        <p>Customers will search for your product directly on our site of through a search engine like Google. If they are interested in your product they have an option to contact you via your supplied email or phone number.</p>
                        <Link to="/shop" class="icon-link">
                            Check shop page
                        <i class="bi bi-cart" width="1em" height="1em"></i>
                        </Link>
                    </div>
                </div>

                
                
            </div>

            <p class="m-5">If you still have more questions you can checkout our <Link to="/frequently-asked-questions">frequently asked questions</Link> section</p>
        </div>
           
        
    </div>
    </div>
  </section> 
}
export default HowItWorksScreen;