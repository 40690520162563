import React, { useState, useEffect } from 'react';
import { getAdminsV2, updateAdminDetails } from '../actions/userActions';
import { validate_role } from '../validations';
import { Textbox, Checkbox } from 'react-inputs-validation';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { get_display_date } from '../config';

import { FFD_STAFF_ROLES } from '../config'

function ViewStaffMemberScreen(props) {
  const [delete_admin_dialog, set_delete_admin_dialog] = React.useState(false);

  const delete_admin_dialog_open = () => {
    set_delete_admin_dialog(true);
  };

  const delete_admin_dialog_close = () => {
    set_delete_admin_dialog(false);
  };

  // eslint-disable-next-line
  let user_profile;
  const permitted_role = 'admin';
  const check_permissions = validate_role(permitted_role);
  if (check_permissions.permitted === false) {
    let login_link = 'login';
    if (permitted_role === 'admin') login_link = 'access-forbidden'
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  const id = props.match.params.id ? props.match.params.id : '';

  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');


  const [roles, set_roles] = useState([]);

  const [update_roles_error_message, set_update_roles_error_message] = useState('');
  const [update_roles_success_message, set_update_roles_success_message] = useState('');

  // eslint-disable-next-line
  const [status, set_status] = useState('status');

  const [audit_actions, set_audit_actions] = useState([]);

  const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);

  const [first_name, set_first_name] = useState('');
  const [last_name, set_last_name] = useState('');
  const [phone, set_phone] = useState('');
  const [email, set_email] = useState('');
  // eslint-disable-next-line
  const [password, set_password] = useState('');
  // eslint-disable-next-line
  const [re_password, set_re_password] = useState('');
  // eslint-disable-next-line
  const [add_role, set_add_role] = useState('');

  const [first_name_invalid, set_first_name_invalid] = useState(true);
  const [last_name_invalid, set_last_name_invalid] = useState(true);
  const [phone_invalid, set_phone_invalid] = useState(true);
  const [email_invalid, set_email_invalid] = useState(true);
  // eslint-disable-next-line
  const [password_invalid, set_password_invalid] = useState(true);
  // eslint-disable-next-line
  const [re_password_invalid, set_re_password_invalid] = useState(true);

  const [admin_details, set_admin_details] = useState({});

  const [admin_role, set_admin_role] = useState(false);
  const [technical_support_role, set_technical_support_role] = useState(false);
  const [sales_support_role, set_sales_support_role] = useState(false);
  const [delivery_role, set_delivery_role] = useState(false);
  const [field_support_role, set_field_support_role] = useState(false);


  /**
   * Deactivates a admin
   * 
   * @param {*} e 
   */
  const deactivateAdmin = async (e) => {
    e.preventDefault();
    set_success_message('');
    set_error_message('');
    set_update_roles_success_message('');
    set_update_roles_error_message('');

    set_action_loading(true);

    const update_data = {
      status: 'deactivated',
    };

    const response = await updateAdminDetails(update_data, id, 'status');
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      props.history.push('/staff-members');
    }
    set_action_loading(false);

    return;
  }

  /**
   * Activates a admin
   * 
   * @param {*} e 
   */
  const activateAdmin = async (e) => {
    e.preventDefault();
    set_success_message('');
    set_error_message('');
    set_update_roles_success_message('');
    set_update_roles_error_message('');

    set_action_loading(true);

    const update_data = {
      status: 'active',
    };

    const response = await updateAdminDetails(update_data, id, 'status');
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      props.history.push('/staff-members');
    }
    set_action_loading(false);

    return;
  }



  const load_page_data = async () => {
    let get_all_users;


    try {
      get_all_users = await getAdminsV2(id, 'role', status);
      if (get_all_users.success === false) {
        set_error_message(get_all_users.message);
      }
      else {
        const { data } = get_all_users;

        set_first_name(data[0].first_name);
        set_last_name(data[0].last_name);
        set_phone(data[0].phone);
        set_email(data[0].email);
        set_admin_details(data[0]);

        const user_account_roles = data[0].roles;
        // console.log(data[0].roles,  user_account_roles)
        set_roles(user_account_roles)
        set_audit_actions(data[0].audit);




        // set_admin_role(user_account_roles.includes('admin'));
        // set_technical_support_role(user_account_roles.includes('technical-support'));
        // set_sales_support_role(user_account_roles.includes('sales-support'));
        // set_delivery_role(user_account_roles.includes('delivery'));
        // set_field_support_role(user_account_roles.includes('field-support'));

        set_first_name_invalid(false);
        set_last_name_invalid(false);
        set_phone_invalid(false);
        set_email_invalid(false);
      }
    } catch (error) {
      set_error_message(`An error occured fetching admin details: ${error.message} `);
    }

    return;
  }

  /**
   * Updates user profile
   * @param {*} e 
   */
  const updateRoles = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');
    set_update_roles_success_message('');
    set_update_roles_error_message('');

    set_action_loading(true);

    // let _roles = [];

    // for (let r = 0; r < FFD_STAFF_ROLES.length; r++) {
    //   let ro = FFD_STAFF_ROLES[r]
    //   if (`${ro.role}_role`) roles.push(ro.role);
    // };

    console.log('roles', roles);

    const update_data = {
      roles,
    };

    // console.log('update data', update_data);

    const response = await updateAdminDetails(update_data, id, 'roles');
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      set_success_message('Account roles successfully updated');
    }
    set_action_loading(false);

    return;
  }

  /**
   * Updates a user roles
   * @param {*} e 
   */
  const updateStaffMember = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');
    set_update_roles_success_message('');
    set_update_roles_error_message('');

    set_action_loading(true);

    const update_data = {
      first_name,
      last_name,
      email,
      phone,
    };

    const response = await updateAdminDetails(update_data, id, 'basic_detail');
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      let get_all_users;
      try {
        get_all_users = await getAdminsV2(id, 'role', status);
        if (get_all_users.success === false) {
          set_error_message(get_all_users.message);
        }
        else {
          const { data } = get_all_users;

          set_audit_actions(data[0].audit);
          set_first_name(data[0].first_name);
          set_last_name(data[0].last_name);
          set_phone(data[0].phone);
          set_email(data[0].email);
          set_admin_details(data[0]);

          set_first_name_invalid(false);
          set_last_name_invalid(false);
          set_phone_invalid(false);
          set_email_invalid(false);
        }
      } catch (error) {
        set_error_message(`An error occured fetching admin details: ${error.message} `);
      }

      set_success_message('Administrator details successfully updated');
    }
    set_action_loading(false);

    return;
  }

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  const roles_updated = async (_roles, _role) => {
    console.log('roles', _roles, _role)

    // let _roles = roles
    // console.log('_roles',_roles)

    // if (_roles.includes(_role)) {
    //   let __roles = []
    //   for (let i = 0; i < _roles.length; i++) {
    //     if (_roles[i] !== _role) {
    //       __roles.push(_roles[i])
    //     }
    //   }
    //   _roles = __roles
    //   console.log('__roles', __roles)
    // } else {
    //   _roles.push(_role)
    // }

    // set_roles(_roles)
  }

  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2><span class="green-text">Viewing</span> <span class="orange-text">{admin_details.first_name} {admin_details.last_name}</span></h2>
          </div>


          {!page_loaded ? ( // so that only products will be loading at this time 
            <div>Loading ... </div>
          ) : (
            <>
              <div class="row">
                <div class="col-12">
                  {error_message !== '' ? (
                    <div class="alert alert-danger" role="alert">
                      {error_message}
                    </div>
                  ) : ('')}
                  {success_message !== '' ? (
                    <div class="alert alert-success" role="alert">
                      {success_message}
                    </div>
                  ) : ('')}
                </div>
              </div>
              <div class="row">


                <div class="col-12">
                  <h2>Basic details</h2>
                  <form onSubmit={updateStaffMember}>
                    <div class="row">
                      <div class="form-group col-md-6 my-3">
                        <label for="">First name</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "Name",
                            name: "Name",
                            type: "text",
                            placeholder: "First name"
                          }}
                          // validate={validate}
                          validationCallback={res =>
                            set_first_name_invalid(res)
                          }
                          value={first_name}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(first_name, e) => {
                            set_first_name(e.target.value);
                          }}
                          onBlur={e => {
                            console.log(e);
                          }}
                          // onFocus={(e) => {console.log(e);}}
                          // onClick={(e) => {console.log(e);}}
                          validationOption={{
                            name: "First name",
                            check: true,
                            required: true,
                            type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2, 
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{18}|\d{15}$/,
                            // regMsg: 'failed in reg.test(${value})',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-6 my-3">
                        <label for="">Surname</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "Surname",
                            name: "Surname",
                            type: "text",
                            placeholder: "Surname"
                          }}
                          // validate={validate}
                          validationCallback={res =>
                            set_last_name_invalid(res)
                          }
                          value={last_name}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(last_name, e) => {
                            set_last_name(e.target.value);
                          }}
                          onBlur={e => {
                            console.log(e);
                          }}
                          // onFocus={(e) => {console.log(e);}}
                          // onClick={(e) => {console.log(e);}}
                          validationOption={{
                            name: "Surname",
                            check: true,
                            required: true,
                            type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2, 
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{18}|\d{15}$/,
                            // regMsg: 'failed in reg.test(${value})',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-6 my-3">
                        <label for="">Phone number</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "Phone",
                            name: "Phone",
                            type: "text",
                            placeholder: "Phone number. eg 263774756502"
                          }}
                          // validate={validate}
                          validationCallback={res =>
                            set_phone_invalid(res)
                          }
                          value={phone}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(phone, e) => {
                            set_phone(e.target.value);
                          }}
                          onBlur={e => {
                            console.log(e);
                          }}
                          // onFocus={(e) => {console.log(e);}}
                          // onClick={(e) => {console.log(e);}}
                          validationOption={{
                            name: "Phone number",
                            check: true,
                            required: true,
                            type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2, 
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            reg: /^\d{12}|\d{14}$/,
                            regMsg: 'Invalid phone number',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>


                      <div class="form-group col-md-6 my-3">
                        <label for="">Email</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "email",
                            name: "email",
                            type: "text",
                            placeholder: "Email"
                          }}
                          // validate={validate}
                          validationCallback={res =>
                            set_email_invalid(res)
                          }
                          value={email}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(email, e) => { set_email(e.target.value); }}
                          onBlur={e => { console.log(e); }}
                          // onFocus={(e) => {console.log(e);}}
                          // onClick={(e) => {console.log(e);}}
                          validationOption={{
                            name: "Email address",
                            check: true,
                            required: true,
                            //type: 'email', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2, 
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{18}|\d{15}$/,
                            // regMsg: 'failed in reg.test(${value})',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>




                    </div>

                    <div class="form-group col-12 my-3 text-center">
                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        disabled={action_loading || first_name_invalid || last_name_invalid || email_invalid || phone_invalid}
                      // check invalids 
                      >
                        <i class="glyphicon glyphicon-plus"></i> Update staff details
                      </button>
                    </div>
                  </form>

                  <hr />
                </div>



                <div class="col-md-12">
                  <h2>Roles</h2>
                  <div class="row">
                    <div class="col-12">
                      {update_roles_error_message !== '' ? (
                        <div class="alert alert-danger" role="alert">

                          {update_roles_error_message}
                        </div>
                      ) : ('')}
                      {update_roles_success_message !== '' ? (
                        <div class="alert alert-success" role="alert">

                          {update_roles_success_message}
                        </div>
                      ) : ('')}
                    </div>

                    <div class="col-12">


                      {FFD_STAFF_ROLES.map(r => (
                        <Checkbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: `${r.role}_role`,
                            name: `${r.role}_role`
                          }}
                          value={r.role}
                          checked={roles.includes(r.role)}
                          disabled={false}
                          // validationCallback={res => {
                          //   set_admin_role_invalid(res);
                          // }} 
                          classNameWrapper=""
                          classNameInputBox=""
                          classNameContainer=""
                          customStyleWrapper={{}}
                          customStyleInputBox={{}}
                          customStyleContainer={{}}
                          onBlur={() => { }}
                          onChange={(e) => {
                            console.log(roles)
                            let _roles = []
                            if (roles.includes(r.role)) {
                              let __roles = []
                              for (let i = 0; i < roles.length; i++) {
                                if (roles[i] !== r.role) {
                                  _roles.push(roles[i])
                                }
                              }
                            } else {
                              _roles = roles
                              _roles.push(r.role)
                            }
                            set_roles(_roles)
                          }}
                          labelHtml={
                            <div style={{ marginTop: "2px" }}>
                              {r.display_name}
                            </div>
                          } // Required.[Html].Default: none.
                          validationOption={{
                            name: `${r.role}_role`, // Optional.[String].Default: "". To display in the Error message. i.e Please check the ${name}.
                            check: false, // Optional.[Bool].Default: true. To determin if you need to validate.
                            // required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                            // showMsg: true, // Optional.[Bool].Default: true. To determin display the error message or not.
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", // Optional.[String].Default: "". Show your custom error message no matter what when it has error if it is provied.
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." // Optional.[String].Default: "". Show your custom success message no matter what when it has error if it is provied.
                          }}
                        />

                      ))}



                      <Checkbox
                        attributesWrapper={{}}
                        attributesInput={{
                          id: "technical_support_role",
                          name: "technical_support_role"
                        }}
                        value={technical_support_role}
                        checked={technical_support_role}
                        disabled={false}
                        // validationCallback={res => {
                        //   set_technical_support_role_invalid(res);
                        // }} 
                        classNameWrapper=""
                        classNameInputBox=""
                        classNameContainer=""
                        customStyleWrapper={{}}
                        customStyleInputBox={{}}
                        customStyleContainer={{}}
                        onBlur={() => { }}
                        onChange={(technical_support_role, e) => {
                          set_technical_support_role(technical_support_role);
                          console.log(e);
                        }}
                        labelHtml={
                          <div style={{ marginTop: "2px" }}>
                            Technical support
                          </div>
                        } // Required.[Html].Default: none.
                        validationOption={{
                          name: "technical_support_role", // Optional.[String].Default: "". To display in the Error message. i.e Please check the ${name}.
                          check: false, // Optional.[Bool].Default: true. To determin if you need to validate.
                          // required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                          // showMsg: true, // Optional.[Bool].Default: true. To determin display the error message or not.
                          // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", // Optional.[String].Default: "". Show your custom error message no matter what when it has error if it is provied.
                          // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." // Optional.[String].Default: "". Show your custom success message no matter what when it has error if it is provied.
                        }}
                      />
                      <Checkbox
                        attributesWrapper={{}}
                        attributesInput={{
                          id: "sales_support_role",
                          name: "sales_support_role"
                        }}
                        value={sales_support_role}
                        checked={sales_support_role}
                        disabled={false}
                        // validationCallback={res => {
                        //   set_sales_support_role_invalid(res);
                        // }} 
                        classNameWrapper=""
                        classNameInputBox=""
                        classNameContainer=""
                        customStyleWrapper={{}}
                        customStyleInputBox={{}}
                        customStyleContainer={{}}
                        onBlur={() => { }}
                        onChange={(sales_support_role, e) => {
                          set_sales_support_role(sales_support_role);
                          console.log(e);
                        }}
                        labelHtml={
                          <div style={{ marginTop: "2px" }}>
                            Sales support
                          </div>
                        } // Required.[Html].Default: none.
                        validationOption={{
                          name: "sales_support_role", // Optional.[String].Default: "". To display in the Error message. i.e Please check the ${name}.
                          check: false, // Optional.[Bool].Default: true. To determin if you need to validate.
                          // required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                          // showMsg: true, // Optional.[Bool].Default: true. To determin display the error message or not.
                          // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", // Optional.[String].Default: "". Show your custom error message no matter what when it has error if it is provied.
                          // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." // Optional.[String].Default: "". Show your custom success message no matter what when it has error if it is provied.
                        }}
                      />

                      <Checkbox
                        attributesWrapper={{}}
                        attributesInput={{
                          id: "field_support_role",
                          name: "field_support_role"
                        }}
                        value={field_support_role}
                        checked={field_support_role}
                        disabled={false}
                        // validationCallback={res => {
                        //   set_field_support_role_invalid(res);
                        // }} 
                        classNameWrapper=""
                        classNameInputBox=""
                        classNameContainer=""
                        customStyleWrapper={{}}
                        customStyleInputBox={{}}
                        customStyleContainer={{}}
                        onBlur={() => { }}
                        onChange={(field_support_role, e) => {
                          set_field_support_role(field_support_role);
                          console.log(e);
                        }}
                        labelHtml={
                          <div style={{ marginTop: "2px" }}>
                            Field support
                          </div>
                        } // Required.[Html].Default: none.
                        validationOption={{
                          name: "field_support_role", // Optional.[String].Default: "". To display in the Error message. i.e Please check the ${name}.
                          check: false, // Optional.[Bool].Default: true. To determin if you need to validate.
                          // required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                          // showMsg: true, // Optional.[Bool].Default: true. To determin display the error message or not.
                          // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", // Optional.[String].Default: "". Show your custom error message no matter what when it has error if it is provied.
                          // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." // Optional.[String].Default: "". Show your custom success message no matter what when it has error if it is provied.
                        }}
                      />
                      <Checkbox
                        attributesWrapper={{}}
                        attributesInput={{
                          id: "delivery_role",
                          name: "delivery_role"
                        }}
                        value={delivery_role}
                        checked={delivery_role}
                        disabled={false}
                        // validationCallback={res => {
                        //   set_delivery_role_invalid(res);
                        // }} 
                        classNameWrapper=""
                        classNameInputBox=""
                        classNameContainer=""
                        customStyleWrapper={{}}
                        customStyleInputBox={{}}
                        customStyleContainer={{}}
                        onBlur={() => { }}
                        onChange={(delivery_role, e) => {
                          set_delivery_role(delivery_role);
                          console.log(e);
                        }}
                        labelHtml={
                          <div style={{ marginTop: "2px" }}>
                            Delivery
                          </div>
                        } // Required.[Html].Default: none.
                        validationOption={{
                          name: "delivery_role", // Optional.[String].Default: "". To display in the Error message. i.e Please check the ${name}.
                          check: false, // Optional.[Bool].Default: true. To determin if you need to validate.
                          // required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                          // showMsg: true, // Optional.[Bool].Default: true. To determin display the error message or not.
                          // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", // Optional.[String].Default: "". Show your custom error message no matter what when it has error if it is provied.
                          // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." // Optional.[String].Default: "". Show your custom success message no matter what when it has error if it is provied.
                        }}
                      />

                      <br />
                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        onClick={updateRoles}
                        disabled={action_loading}
                      >
                        {
                          !action_loading ? ('Update roles') : (
                            <>
                              <div class="spinner-border text-orange" role="status">
                              </div>
                              &nbsp;Please wait
                            </>
                          )
                        }
                      </button>






                    </div>


                  </div>
                  <hr />

                  {admin_details.status !== 'active' ? (
                    <div class="form-group col-12 my-3 text-left">
                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        onClick={activateAdmin}
                        disabled={action_loading}
                      >
                        {
                          !action_loading ? ('Activate account') : (
                            <>
                              <div class="spinner-border text-orange" role="status">
                              </div>
                              &nbsp;Please wait
                            </>
                          )
                        }
                      </button>
                    </div>
                  ) : (
                    <div class="form-group col-12 my-3 text-left">
                      <button
                        class="btn btn-lg btn-danger"
                        type="submit"
                        onClick={delete_admin_dialog_open}
                        disabled={action_loading}
                      >
                        {
                          !action_loading ? ('Deactivate account') : (
                            <>
                              <div class="spinner-border text-orange" role="status">
                              </div>
                              &nbsp;Please wait
                            </>
                          )
                        }
                      </button>
                    </div>
                  )}



                  <Dialog open={delete_admin_dialog} onClose={delete_admin_dialog_close} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Are you sure you want to deactivate this admin?</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        This action will not entirely delete the admin but will make the admin become inactive and the product will no longer show when adding products.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        onClick={delete_admin_dialog_close} color="primary">
                        Cancel
                      </button>

                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        onClick={deactivateAdmin} color="primary">
                        Yes, I'm sure
                      </button>
                    </DialogActions>
                  </Dialog>

                  <hr />
                  <div class="form-group col-12 my-3 text-left">
                    <h2>Staff account actions audit</h2>

                    {audit_actions.length > 0 ? (
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <tr>
                            <th>Timestamp</th>
                            <th>Note</th>
                          </tr>

                          {audit_actions.map((c) => (
                            <tr key={c._id}>
                              <td>{get_display_date(c.date_time)}</td>
                              <td>{c.note}</td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    ) : ('No edit acitons made on staff member.')}
                  </div>
                </div>

              </div>
            </>
          )}

        </div>
      </section>
    </>
  );
}
export default ViewStaffMemberScreen;
