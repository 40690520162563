import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { signinAdmin } from '../actions/userActions';


function LoginAdminScreen(props) {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const userSignin = useSelector(state => state.userSignin);
  const { loading, userInfo, error } = userSignin;
  const dispatch = useDispatch();
  const redirect = props.location.search ? props.location.search.split("=")[1] : '/';

  useEffect(() => {
    if (userInfo) {
      props.history.push(redirect);
    }
    return () => {
      //
    };
    // eslint-disable-next-line
  }, [userInfo]);

  // console.log('props', props);

  const submitHandler = (e) => {
    e.preventDefault();
    // console.log(userInfo);
    dispatch(signinAdmin(email, password, props, redirect));
    // console.log('after dispatch', userInfo);
  }
  return <section id="about" class="about main-content mt-5 login-content">
    <div class="container-fluid" data-aos="fade-up">
      <div class="row mt-5">
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center">
          <h3 class="green-text">SIGN IN</h3>
          {error && <div class="col-12">
            <div class="alert alert-danger" role="alert">
              {error}
            </div>
          </div> }
                            

          <form onSubmit={submitHandler} >
            <div class="form-group my-4">
              <input class="form-control" type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
            </div>
            <div class="form-group my-4">
              <input class="form-control" type="password" id="password" name="password" onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
            </div>
            <div class="form-group my-4 text-end">
              {/* <div class="row">
                <div class="col-6 text-middle">
                  <input type="checkbox" class="form-check-input" id="remember-me-check" /> <label class="form-check-label" for="remember-me-check">Remember me</label>
                </div>
                <div class="col-6">
                <Link to="forgot-password" className="green-text" >Forgot password ?</Link>
                </div>
              </div> */}
              {/* <input type="checkbox" class="form-check-input" id="remember-me-check" /> <label class="form-check-label" for="remember-me-check">Remember me</label> */}
              <Link to="forgot-password" className="green-text" >Forgot password ?</Link>
            </div>
            <div class="form-group my-4">
              <button type="submit" class="btn btn-primary form-control login-button" disabled={loading}>Sign in</button>              
            </div>
            <div class="form-group">
              <Link to={redirect === "/" ? "register-as-seller" : "register-as-seller?redirect=" + redirect} className="green-text" >Not a member? Register</Link>
            </div>
          </form>
        </div>
        <div class="col-md-4"></div>
    </div>
    </div>
  </section> 
}
export default LoginAdminScreen;