import React, { useEffect, useState } from 'react';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';


function ListingPolicyScreen(props) {

  const [action_loading, set_action_loading] = useState(false);

  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');
  
  // eslint-disable-next-line
  useEffect(() => {
      if (action_loading === false) {
        set_action_loading(true);
        loadPage();
      }      
  });

  /**
   * Checks if a supplied token in link can reset password and is valid.
   */
  const loadPage = async () =>  {
    set_success_message('');
    set_error_message(''); 
  }

  return <section id="about" class="about main-content mt-5 login-content">
    <div class="container-fluid" data-aos="fade-up">
      <div class="row mt-5">
        <div class="col-md-12 text-center">
          <h3 class="green-text">Product Listing Policy</h3>
          {error_message !== '' ? (
          <div class="col-12">
            <div class="alert alert-danger" role="alert">
              {error_message}
            </div>
          </div>): ('')}

          {success_message !== '' ? (
          <div class="col-12">
            <div class="alert alert-success" role="alert">
              {success_message}
            </div>
          </div>): ('')}
        </div>


        <div class="col-md-12">
        <p>
    This <b> product Listing Policy </b> describes the terms of listing your products on <b>
    farmfreshdelights.com </b>. Please read carefully before listing your product(s) on  farmfreshdelights.com.
</p>

<p>
            <ul>
                <li>
                     You may not post, sell or buy any item that is restricted or prohibited by the Republic of Zimbabwe, the jurisdiction of Farm-Fresh Delights.
                </li><br/>
                <li>
                     Please be aware that Farm-Fresh Delights does not permit buying or selling of products that are illegal,
                     infringe upon the intellectual property rights of others, or may easily be used for illegal purposes.
                </li><br/>
                <li>
                     As a member of Farm-Fresh Delights, you are responsible for ensuring that the products you have
                     posted are legal or permissible. Thus, before posting any product, selling lead and/or buying lead,
                     you need to make sure that your trading items are NOT against this 'Product Listing Policy'.
                     Farm-Fresh Delights reserves the right to remove any products listed by a user that violate this 'Product Posting Policy'
                     without providing any intimation to users. Farm-Fresh Delights also reserves the right to disable the accounts of any members
                      who continue to violate the 'Product Posting Policy' after having been warned.
                </li>
           </ul>
       </p>


<p>
    <b>The following is a list of products/product types that are prohibited on Farm-Fresh Delights:</b>
</p>

<ol>
            <li>
                <b> Illegal drugs, Precursors and drug paraphernalia </b>
                <ul>
                    <li>
                    Any and all listing or sale of narcotics, tranquilizers, psychotropic drugs, natural drugs,
                    synthetic drugs, steroids and other controlled substances is barred</li>

                    <li>The listing or sale of all drug precursor chemicals is not allowed.</li>
                </ul>
            </li>

            <li>
                <b>Medical Drugs and Devices</b>
                <ul>
                    <li>
                        The posting of prescription drugs, psychotropic drugs and narcotics is strictly prohibited.
                    </li>
                    <li>
                        The listing or sale of orally administered or ingested sexual enhancement foods and supplements is prohibited.
                    </li>
                    <li>
                        Prescription drugs or devices, controlled substances, unapproved drugs, unapproved medical devices.
                    </li>
                </ul>
            </li>

            <li>
                <b> Intellectual Property Rights (IPR) Infringing Products </b>
                <ul>
                    <li>
                        Replica and Counterfeit Items are not permitted to be posted
                    </li>
                    <li>
                        Unauthorized Copies of Intellectual Property are not allowed to be listed
                    </li>
                </ul>
            </li>

            <li>
                <b> Gold dust, gold bullion and other precious metals (not including jewellery) </b>
                <ul>
                    <li>
                        Listings that offer the sale or buying of gold, silver and other precious metals (not including
                        jewelry) are prohibited.
                    </li>
                    <li>
                        Rough diamonds and “conflict minerals” originating from non-compliant countries may not be listed.
                    </li>
                </ul>
            </li>

            <li>
                <b> Counterfeit currency and stamps </b>
            </li>

            <li>
                <b>Tobacco Products </b>
                <ul>
                    <li>
                        The posting of tobacco products, including but not limited to cigars, cigarettes, cigarette tobacco,
                        pipe tobacco, hookah tobacco, chewing tobacco and tobacco leaf is strictly prohibited.
                    </li>
                    <li>
                        The posting of electronic cigarettes and accessories is permitted, however nicotine and other
                        liquids (e-liquids) for use in electronic cigarettes is forbidden
                    </li>
                </ul>
            </li>

            {/* <li>
                <b> Alcoholic Beverages </b>
                <ol>
                    <li>
                        The posting of alcoholic beverages, including but not limited to Beer, Wine, Whisky, Gin, Vodka, and Rum
                        is strictly prohibited.
                    </li>
                </ol>
            </li>  */}

            <li>
                <b>Fake documents</b><br/>
                (e.g. Degrees or Diplomas, Government and transit documents, Government IDs and licenses)
            </li>

            <li>
                <b>Wildlife and related animal products</b><br/>
                (e.g. pelts, skins, internal organs, teeth, claws, shells, bones, tusks, ivory and other parts)
            </li>

            <li>
                <b>Financial services</b><br/> including money transfers, issuing bank guarantees and letters of credit,
                     loans, fundraising and funding for personal investment purposes, etc. are strictly prohibited
            </li>

            <li>
                <b> Others</b>
                <ul>
                    <li>
                        Adult subscription services or chatting services
                    </li>
                    <li>
                        Contracts and tickets
                    </li>
                    <li>
                        Credit card, credit repair Services, applications for financial services or loans,
                        collection services
                    </li>
                    <li>
                        Firearms, ammunition, high capacity magazines, stun guns, air guns, military weapons
                    </li>
                    <li>
                        Human body parts, fluids and remains
                    </li>
                    <li>
                        Job postings are not allowed
                    </li>
                    <li>
                        Lottery, raffle, contests related
                    </li>
                    <li>
                        Mailing lists and personal information
                    </li>
                    <li>
                        Pornographic, erotic &amp; adult contents including but not limited to: pictures, videos,
                        DVDs, magazines, toys, etc.
                    </li>
                    <li>
                        Posts promoting hatred, racism or religious persecution products or images showcasing nudity.
                    </li>
                    <li>
                        Requests for donations
                    </li>
                    <li>
                        Sanctioned and prohibited items
                    </li>
                    <li>
                        Software - Unauthorized, OEM or bundled copies of software are not allowed to be listed
                    </li>
                    <li>
                        Spyware, Spamware, e-mail advertising, opt-in or any commercial messaging services
                    </li>
                </ul>
            </li>

            <li>
                <b>Restricted Countries</b><br/>
                In addition to the above product listing policy, Farm-Fresh Delights does not allow users to sell from any
                country that is not Zimbabwe. Any user profile from such geographic
                location will be automatically deleted without any prior notice to the user.
            </li>
</ol>
        </div>
           
        
    </div>
    </div>
  </section> 
}
export default ListingPolicyScreen;