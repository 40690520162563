    import axios from 'axios';
  import Axios from 'axios';
  
  import { API_URL, API_KEY } from "../config";
  
  let token_string = localStorage.getItem('token');
  
  /**
   * Adds a new notification
   * @param {Object} add_data 
   */
  const addNotification = async (add_data) => {
    if (token_string === null) { token_string = localStorage.getItem('token') }
    try {
      const { data } = await Axios.post(
        `${API_URL}/notifications`, 
        { 
          api_key: API_KEY, 
          ...add_data,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token_string
          }
        }
      );
      return data;
    } catch (error) {
      return {
        success: false,
        message: 'Sorry we could not add notification. Please try again. Please try again',
      }
    }
  }
  
  const getNotifications = async (id, receiver, sender, read) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/notifications?id=${id}&receiver=${receiver}&sender=${sender}&read=${read}`
      );
      return data;
    } catch (error) {
      return {
        success: false,
        message: 'Failed to retrieve notifications details. Please reload page',
      }
    }
  }

  /**
   * Updates notification details
   * @param {Object} update_data 
   * @param {String} id
   * @param {String} update_key
   */
  const updateNotification = async (update_data, id, update_key) => {
    if (token_string === null) { token_string = localStorage.getItem('token') }
    try {
      const { data } = await Axios.patch(
        `${API_URL}/notifications`, 
        { 
          api_key: API_KEY, 
          update_data,
          id,
          update_key,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token_string
          }
        }
      );
      return data;
    } catch (error) {
      return {
        success: false,
        message: `Sorry we could not update notification details. ${error.message}`,
      }
    }
  }
  
  
  export {
    getNotifications,
    addNotification,
    updateNotification,
  };
  