import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts, getProductsV3 } from '../actions/productActions';
import { getCategories } from '../actions/categoryActions';
import { createOrderV2 } from '../actions/orderActions';
import { validate_role } from '../validations';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { addToCart, addToWishList, updateQuantity } from '../actions/cartActions';
import { Link } from 'react-router-dom';
import { Checkbox } from 'react-inputs-validation';
import { checkLoggedIn } from '../actions/userActions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { API_URL, buyer_city } from '../config';

function OrderProductScreen(props) {
  const dispatch = useDispatch();

  const cart = useSelector(state => state.cart);
  const { cartItems } = cart;

  // eslint-disable-next-line
  let user_profile;
  const permitted_role = 'seller';
  const check_permissions = validate_role(permitted_role);
  if (check_permissions.permitted === false) {
    // do nothing
  } else {
    user_profile = check_permissions.user_object;
  }

  const id = props.match.params.id ? props.match.params.id : '';
  const meta = props.match.params.meta ? props.match.params.meta : '';


  const [login_register_dialog, set_login_register_dialog] = React.useState(false);

  const login_register_dialog_open = () => {
    set_login_register_dialog(true);
  };

  const login_register_dialog_close = () => {
    set_login_register_dialog(false);
  };

  const [error_message, set_error_message] = useState('');
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState('');

  const [page_loaded, set_page_loaded] = useState(false);
  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);

  const [product_details, set_product_details] = useState([]);
  // eslint-disable-next-line
  const [product_sales, set_product_sales] = useState([]);
  const [product_pictures, set_product_pictures] = useState([]);
  // eslint-disable-next-line
  const [all_categories, set_all_categories] = useState([]);
  // eslint-disable-next-line
  const [added_to_cart_message, set_added_to_cart_message] = useState('');
  // eslint-disable-next-line
  const [added_to_wishlist_message, set_added_to_wishlist_message] = useState('');
  // eslint-disable-next-line
  const [item_added_to_cart_or_wishlist, set_item_added_to_cart_or_wishlist] = useState('');
  const [in_cart_products, set_in_cart_products] = useState([]);
  // eslint-disable-next-line 
  const [in_wish_list_products, set_in_wish_list_products] = useState([]);
  // eslint-disable-next-line
  const [cart_items, set_cart_items] = useState([]);

  const [related_products, set_related_products] = useState([]);


  const [include_delivery_cost, set_include_delivery_cost] = useState(false);
  const [quantity, set_quantity] = useState(1);

  // eslint-disable-next-line
  const [display_sub_categories, set_display_sub_categories] = useState([]);
  const [sub_category_name, set_sub_category_name] = useState('');

  const [expanded_image, set_expanded_image] = useState('');

  const add_to_cart = async (product_id, product_name) => {
    set_item_added_to_cart_or_wishlist('');

    set_added_to_cart_message('');
    set_added_to_wishlist_message('');

    await dispatch(addToCart(product_id, 1));

    const new_cart_items = JSON.parse(localStorage.getItem('shopping_cart_items'));

    let _in_cart_products = [];
    if (new_cart_items.length > 0) {
      set_cart_items(new_cart_items);
      for (let i = 0; i < new_cart_items.length; i++) {
        _in_cart_products.push(new_cart_items[i].name);
      }
    }
    set_in_cart_products(_in_cart_products);

    set_item_added_to_cart_or_wishlist(product_id);
    set_added_to_cart_message(`${product_name} added to cart`);
  }

  // eslint-disable-next-line 
  const add_to_wishlist = (product_id, product_name) => {
    set_item_added_to_cart_or_wishlist('');

    set_added_to_wishlist_message('');
    set_added_to_cart_message('');

    dispatch(addToWishList(product_id, 1));

    set_item_added_to_cart_or_wishlist(product_id);
    set_in_wish_list_products(JSON.parse(localStorage.getItem('wish_list_items')));
    set_added_to_wishlist_message(`${product_name} added to wishlist`);
  }

  const update_quantity = async (product_id, action) => {
    set_item_added_to_cart_or_wishlist('');

    set_added_to_cart_message('');
    set_added_to_wishlist_message('');

    await dispatch(updateQuantity(product_id, action));

    const new_cart_items = JSON.parse(localStorage.getItem('shopping_cart_items'));

    let _in_cart_products = [];
    if (new_cart_items.length > 0) {
      set_cart_items(new_cart_items);
      for (let i = 0; i < new_cart_items.length; i++) {
        _in_cart_products.push(new_cart_items[i].name);
      }
    }
    set_in_cart_products(_in_cart_products);


    set_item_added_to_cart_or_wishlist(product_id);
    set_added_to_cart_message(`Quantity updated`);
  }

  const get_quantity_in_cart = (_product_name) => {
    let _product_quantity = '';
    if (cartItems.length > 0) {
      for (let i = 0; i < cartItems.length; i++) {
        if (cartItems[i].name === _product_name) {
          _product_quantity = cartItems[i].quantity;
          break;
        }
      }
    }
    return _product_quantity;
  }

  const search_related_products = async () => {
    set_error_message('');
    // moved to in page load data
    return;
  }

  const load_page_data = async () => {
    let get_all_categories;
    let get_product_details;
    // eslint-disable-next-line
    let _product_details;

    try {
      get_product_details = await getProducts(id, 'seller', 'farm', 'product_meta', 'sub_category_meta', 'category_meta', 'product_name', 'quantity', 'quantity_units', 'min_price', 'max_price', 'delivery_zone', 'featured', 'on_sale', 'sort_order');
      if (get_product_details.success === false) {
        set_error_message(get_product_details.message);
      }
      else {
        // console.log(get_product_details);
        const { data } = get_product_details;
        _product_details = data[0];
        set_product_details(data[0]);
        set_product_sales(data[0].sales);
        set_product_pictures(data[0].pictures);

        set_expanded_image(data[0].pictures[0]);

        set_item_added_to_cart_or_wishlist(id)
        set_sub_category_name((data[0].sub_category.replace(/-/g, ' ')))
      }
    } catch (error) {
      set_error_message(`An error occured fetching categories: ${error.message} `);
    }

    try {
      get_all_categories = await getCategories('id', 'meta', 'active');
      if (get_all_categories.success === false) {
        set_error_message(get_all_categories.message);
      }
      else {
        const { data } = get_all_categories;
        set_all_categories(data);
      }
    } catch (error) {
      set_error_message(`An error occured fetching categories: ${error.message} `);
    }

    let get_retrieved_products;
    let _search_term = 'search_term';
    let _product_meta = 'product_meta';
    let _sub_category_meta = 'sub_category_meta';
    // eslint-disable-next-line 
    let _category_meta = 'category_meta';
    let _product_name = 'product_name';
    let _quantity = 0;
    let _quantity_units = 'quantity_units';
    let _min_price = 'min_price';
    let _max_price = 'max_price';
    let city = 'city';

    if (!(buyer_city === null || buyer_city === '')) {
      city = buyer_city;
    }


    try {
      get_retrieved_products = await getProductsV3('id', 'seller', 'farm', _product_meta, _sub_category_meta, _product_details.category_meta, _product_name, _quantity, _quantity_units, _min_price, _max_price, 'delivery_zone', 'featured', 'on_sale', 'sort_order', 'sort_by', _search_term, 'latitude', 'longitude', city);
      if (get_retrieved_products.success === false) {
        // set_error_message(get_retrieved_products.message);
      }
      else {
        const { data } = get_retrieved_products;
        let _data = []
        for (let i = 0; i < 4; i++) {
          if (_data.length < 3 && data[i]._id !== id) {
            _data.push(data[i])
          }
        }
        set_related_products(_data);
      }
    } catch (error) {
      // set_error_message(`An error occured fetching product types: ${error.message} `);
    }

    return;
  }

  const submitHandler = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    try {
      const response = await createOrderV2(id, quantity);
      if (response.success) {
        set_success_message(response.message + response.message);
        props.history.push('/my-purchases');
      } else {
        set_error_message(response.message);
      }
    } catch (error) {
      console.log(`${error.message}`);
      set_error_message('An error occured in completing your order. Please try again');
    }

    set_action_loading(false);

    return;
  }

  useEffect(() => {
    if (page_loaded === false) {
      search_related_products()
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);



  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2><span class="green-text">Order</span> <span class="orange-text">{product_details.product_name}</span></h2>

            <img src={`${API_URL}/${expanded_image}`} alt={product_details.product_name} class="main-product-image" />
          </div>


          {action_loading ? ( // so that only products will be loading at this time 
            <div>
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only"></span>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div class="row">
                <div class="col-12">
                  {error_message !== '' ? (
                    <div class="alert alert-danger" role="alert">
                      {error_message}
                    </div>
                  ) : ('')}
                  {success_message !== '' ? (
                    <div class="alert alert-success" role="alert">
                      {success_message}
                    </div>
                  ) : ('')}
                </div>
              </div>


              <div class="row">
                <div class="col-md-4"></div>

                <div class="col-md-4">

                  <div class="form-group mt-5">
                    <label>Specify required quantity</label>
                    <div class="input-group">
                      <input type="number" min="1" class="form-control" value={quantity} onChange={(e) => set_quantity(e.target.value)} />
                      <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2">&nbsp;&nbsp;{product_details.quantity_units === 'each' ? product_details.product_name : product_details.quantity_units}</span>
                      </div>
                    </div>
                  </div>

                  <div class="form-group mt-5">
                    <label>Total cost (US$)</label>
                    <input type="text" class="form-control" disabled value={(parseFloat(quantity * (product_details.price))).toFixed(2)} />
                  </div>

                  {product_details.offer_delivery ? (
                    <div class="form-group mt-5">
                      <Checkbox
                        attributesInput={{
                          id: 'include_delivery_cost',
                          name: 'include_delivery_cost',
                        }}
                        onBlur={() => { }}
                        checked={include_delivery_cost}
                        onChange={(e) => {
                          set_include_delivery_cost(e);
                        }}
                        labelHtml={
                          <div style={{ color: '#4a4a4a', marginTop: '2px' }}>
                            Include delivery cost
                          </div>
                        } // Required.[Html].Default: none.
                        validationOption={{
                          name: 'include_delivery_cost', // Optional.[String].Default: "". To display in the Error message. i.e Please check the .
                          check: false, // Optional.[Bool].Default: true. To determin if you need to validate.
                          required: false, // Optional.[Bool].Default: true. To determin if it is a required field.
                        }}
                      />
                    </div>
                  ) : ('')}

                  <div class="form-group my-4">
                    {checkLoggedIn() !== '' ? (
                      <form onSubmit={submitHandler} >
                        <button
                          type="submit"
                          class="btn btn-primary"
                          disabled={action_loading || quantity < 0}
                        //  || lastNameInvalid || phoneInvalid || emailInvalid 
                        >
                          {
                            !action_loading ? ('Send request to seller') : (
                              <>
                                <div class="spinner-border text-orange" role="status">
                                  {/* <span class="sr-only">Loading...</span> */}
                                </div>
                                &nbsp;Please wait
                              </>
                            )
                          }

                        </button>
                      </form>
                    ) : (
                      <button
                        type="button"
                        class="btn btn-primary"
                        disabled={action_loading || quantity < 0}
                        onClick={() => login_register_dialog_open()}
                      >
                        {
                          !action_loading ? ('Send request to seller') : (
                            <>
                              <div class="spinner-border text-orange" role="status">
                                {/* <span class="sr-only">Loading...</span> */}
                              </div>
                              &nbsp;Please wait
                            </>
                          )
                        }

                      </button>
                    )}

                  </div>





                </div>
              </div>
            </>
          )}

        </div>

      </section>

      <Dialog open={login_register_dialog} onClose={login_register_dialog_close} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Login/register to continue</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please log in (if you have an account) or register (if you don’t already have an account)so that we can contact you with seller’s response to your request.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link to={`/login?search=/order-product/${meta}/${id}`}
            class="btn btn-lg btn-success"
            color="primary">
            Login
          </Link>

          <Link to={`/register?search=/order-product/${meta}/${id}`}
            class="btn btn-lg btn-success"
            color="primary">
            Register
          </Link>

          {/* <button 
                            class="btn btn-lg btn-success" 
                            type="submit"
                            onClick={removeCityDetails} color="primary">
                              Yes, I'm sure
                          </button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default OrderProductScreen;
