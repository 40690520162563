import React, { useState, useEffect } from 'react';
import { validate_role } from '../validations';
import { getInventoryAlerts } from '../actions/productActions';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { Link } from 'react-router-dom';

function SellerInventoryAlertsScreen(props) {
  // eslint-disable-next-line 
  let user_profile;
  const permitted_role = 'seller';
  const check_permissions = validate_role(permitted_role);
  if (check_permissions.permitted === false) {
    let login_link = 'login';
    if (permitted_role === 'seller') login_link = 'access-forbidden'
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  // eslint-disable-next-line 
  const [error_message, set_error_message] = useState('');
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState('');
  const [page_loaded, set_page_loaded] = useState(false);

  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);
  
  const [products_to_display, set_products_to_display] = useState('');

  const [inventory_alerts, set_inventory_alerts] = useState(0);
    

  const load_page_data = async () => { 
    set_action_loading(true);
    let get_inventory_alerts;

    
    
    let _products_to_display = [];
    // console.log(get_inventory_alerts);
     try {
        get_inventory_alerts = await getInventoryAlerts();
        if (get_inventory_alerts.success) {
          const { count, data } = get_inventory_alerts;
          set_inventory_alerts(count);
          if (count === 0) {
            // set_success_message('You are properly stocked.');
          } else {
            // set_inventory_alerts(data);
            for (let i = 0; i < data.length; i++) {
              _products_to_display.push(
                <tr>
                  <td>{data[0].product_name}</td>
                  <td>{data[0].quantity} {data[0].quantity_units}</td>
                  <td>${data[0].price}</td>
                  <td><Link to={`/seller-product/${data[i]._id}`}>Update</Link></td>
                </tr>
              );
            }
          }         
        } else {
          // set_error_message(get_inventory_alerts.message);
        }
      } catch (error) {
        // set_error_message(`An error occured fetching cities: ${error.message} `);
      }

    set_products_to_display(_products_to_display);

    set_action_loading(false);
    return;
  }

  useEffect(() => {
    if (page_loaded === false ) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  

  return (
    <>
    <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2><span class="black-text">Inventory Alerts</span></h2>

          { inventory_alerts > 0 ? (<h3>The following products are running low</h3>):('') }
          
        </div>       


        {!page_loaded  ? ( // so that only products will be loading at this time 
          <div>Loading ... </div>
        ) : (
          <>
            <div class="row">
              <div class="col-12">
                  {error_message !== '' ? (
                      <div class="alert alert-danger" role="alert">
                        {error_message}
                      </div>
                    ): ('')}
                    {success_message !== '' ? (
                      <div class="alert alert-success" role="alert">
                          {success_message}
                      </div>
                    ): ('')}     
                </div>
            </div>
          
          <div class="row">

          
          { inventory_alerts > 0 ? (<div class="col-12 table-responsive">
            
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>Product</th>
                <th>Quantity remaining</th>
                <th>Price/Unit</th>
                <th></th>
              </tr>

              </thead>
              
                        <tbody>{products_to_display}</tbody>
              
                    
              
            </table>
          </div>):('') }
                
          </div>


          <div class="row">
          { inventory_alerts > 0 ? (<p>Please update or replace these products soon.</p>):('') }
          </div>
            </>
        ) }
        
      </div>
    </section>
    </>
  );
}
export default SellerInventoryAlertsScreen;
