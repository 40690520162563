import axios from 'axios';
import Axios from 'axios';

import { API_URL, API_KEY } from "../config";

let token_string = localStorage.getItem('token');

/**
 * Adds a new verification
 * @param {Object} add_data 
 */
const addVerification = async (add_data) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await Axios.post(
      `${API_URL}/verifications`,
      { 
        api_key: API_KEY, 
        ...add_data,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Sorry we could not add verification. Please try again. Please try again',
    }
  }
}

const getVerifications = async (id, verifier, seller_id, status) => {
  try {
    const { data } = await axios.get(
      `${API_URL}/verifications?id=${id}&verifier=${verifier}&seller_id=${seller_id}&status=${status}`
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Failed to retrieve verifications details. Please reload page',
    }
  }
}

/**
 * Updates verification details
 * @param {Object} update_data 
 * @param {String} id
 * @param {String} update_key
 */
const updateVerification = async (update_data, id, update_key) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await Axios.patch(
      `${API_URL}/verifications`, 
      { 
        api_key: API_KEY, 
        update_data,
        id,
        update_key,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: `Sorry we could not update verification details. ${error.message}`,
    }
  }
}


export {
  getVerifications,
  addVerification,
  updateVerification,
};
