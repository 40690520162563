import axios from 'axios';
import Axios from 'axios';

import { API_URL, API_KEY } from "../config";

let token_string = localStorage.getItem('token');

/**
 * Adds a new message
 * @param {Object} add_data 
 */
const addMessage = async (add_data) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await Axios.post(
      `${API_URL}/messages`, 
      { 
        api_key: API_KEY, 
        ...add_data,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Sorry we could not add message. Please try again. Please try again',
    }
  }
}

const getMessages = async (id, receiver, sender, read) => {
  try {
    const { data } = await axios.get(
      `${API_URL}/messages?id=${id}&receiver=${receiver}&sender=${sender}&read=${read}`
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Failed to retrieve messages details. Please reload page',
    }
  }
}

/**
 * Updates message details
 * @param {Object} update_data 
 * @param {String} id
 * @param {String} update_key
 */
const updateMessage = async (update_data, id, update_key) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await Axios.patch(
      `${API_URL}/messages`, 
      { 
        api_key: API_KEY, 
        update_data,
        id,
        update_key,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: `Sorry we could not update message details. ${error.message}`,
    }
  }
}


export {
  getMessages,
  addMessage,
  updateMessage,
};
