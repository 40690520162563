import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { validate_multiple_roles } from '../validations';
import { getBuyers } from '../actions/userActions';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

function BuyersScreen(props) {
  // eslint-disable-next-line
  let user_profile;
  const permitted_roles = ['admin', 'technical-support'];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = 'access-forbidden';
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  // eslint-disable-next-line
  const [error_message, set_error_message] = useState('');
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState('');
  const [page_loaded, set_page_loaded] = useState(false);
  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);

  const [buyers, set_buyers] = useState([]);

  // eslint-disable-next-line
  const [status, set_status] = useState('active');


  const productList = useSelector((state) => state.productList);
  const { loading, error } = productList;

  const link_cell_formatter= (cell, row) => {
    return (
    <div>
      <Link to={`${cell}/${row.age}`}>{cell}</Link>
    </div>);
  }

  const load_page_data = async () => { 
    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    set_buyers([])

    let get_retrieved_buyers;
    try {
    //   console.log('in try')
      get_retrieved_buyers = await getBuyers('id', 'buyer', 'status');
      // console.log('ret prod', get_retrieved_buyers)
      if (get_retrieved_buyers.success === false) {
        set_error_message(get_retrieved_buyers.message);
      }
      else {
        const { data } = get_retrieved_buyers;
        // console.log('retrieved buyers', data);
        let buyers_ = []

        for (let i = 0; i < data.length; i++) {
          buyers_.push({
            full_name: data[i].first_name + ' ' + data[i].last_name,
            id: data[i]._id,
            // display_name: data[i].display_name,
            phone: data[i].phone,
            email: data[i].email,
            // sell_from: data[i].buyer_details[0].buyer_type,
            city: data[i].buyer_details[0].city,
            action: <Link class="" to={`/buyer/${data[i]._id}`}><i class="bi bi-eye"></i> expand</Link>
          })
        }

        set_buyers(buyers_)
      }
    } catch (error) {
      console.log('error', error.message)
      set_error_message(`An error occured fetching buyers: ${error.message} `);
    }

    set_action_loading(false);
 }

 

 // eslint-disable-next-line
  const dispatch = useDispatch();
  useEffect(() => {
    if (page_loaded === false ) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
    <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2><span class="green-text">Buyers</span> <span class="orange-text"></span></h2>
        </div>

        <div class="row">
          {/* <div class="col-12 mb-1">
            <Link to="/add-staff-member" class="btn btn-primary"><i class="bi bi-plus"> </i>Add staff member</Link>
          </div> */}

          {/* <div class="col-12 mb-5">
            {
              status === 'active' ? (
                <button 
                          class="btn btn-primary" 
                          type="button"
                          onClick={ () => {  set_status('status'); toggle_staff('status') }}
                          disabled={action_loading}
                        >
                         <i class="bi bi-eye"> </i>  Show all
                        </button>
              ):(
                <button 
                          class="btn btn-primary" 
                          type="submit"
                          onClick={ () => {  set_status('active'); toggle_staff('active') }}
                          disabled={action_loading}
                        >
                          <i class="bi bi-eye"> </i>  Show active only
                        </button>
              )
            }
          </div> */}
        </div>

        


        {loading ? ( // so that only buyers will be loading at this time 
          <div>Loading ... </div>
        ) : error ? (
        <div class="row">
          <div class="col-12">
            <div class="alert alert-danger" role="alert">
                No buyers found.
            </div>
          </div>
        </div>
        ) : (
          <div class="row">
            
            <BootstrapTable
                                data={ buyers }
                                search
                                pagination>
                                    <TableHeaderColumn dataField='full_name'>Full name</TableHeaderColumn>
                                    {/* <TableHeaderColumn dataField='display_name'>Display name</TableHeaderColumn> */}
                                    <TableHeaderColumn dataField='phone' >Phone</TableHeaderColumn>
                                    <TableHeaderColumn dataField='email'>Email</TableHeaderColumn>
                                    <TableHeaderColumn dataField='city'>City</TableHeaderColumn>
                                    {/* <TableHeaderColumn dataField='sell_from'>Selling from</TableHeaderColumn> */}
                                    {/* <TableHeaderColumn dataField='status'>Status</TableHeaderColumn> */}
                                    <TableHeaderColumn dataField='action' dataFormat={link_cell_formatter} isKey={ true }></TableHeaderColumn>
                                </BootstrapTable>
        </div>
        )}
        
      </div>
    </section>
    </>
  );
}
export default BuyersScreen;
