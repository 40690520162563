import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { forgotPassword, verifyUserAccount, loginAfterAccountVerification } from '../actions/userActions';
import jwt_decode from "jwt-decode";
import 'react-inputs-validation/lib/react-inputs-validation.min.css';


function VerifyAccountScreen(props) {
  const [email, setEmail] = useState('');

  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');

  const [action_loading, set_action_loading] = useState(false);

  const [page_loaded, set_page_loaded] = useState(false);

  const [enter_email_again, set_enter_email_again] = useState(false);

  const dispatch = useDispatch();
  const token = props.match.params.token ? props.match.params.token : '';
  
  // eslint-disable-next-line
  useEffect(() => {
      if (page_loaded === false) {
        set_action_loading(true);
        checkSuppliedToken();
        set_page_loaded(true);
      }        
  });

  /**
   * Checks if a supplied token in link can reset password and is valid.
   */
  const checkSuppliedToken = async () =>  {
    set_action_loading(true);
    try {
      await jwt_decode(token);
    } catch (error) {
      set_error_message('Sorry the token is no longer valid. Please enter your email address again to reset your password.'); 
      set_enter_email_again(true);
      set_action_loading(false);
      // console.log('error occured', error.message);
      return;
    }

    // send to verify token/ account
    const response = await verifyUserAccount(token); 
    if (response.success === false) {
      set_error_message(response.message); 
      set_enter_email_again(true);
    } else {
      set_success_message(response.message);
      dispatch(loginAfterAccountVerification(token));
    }
    
    set_action_loading(false);
    return; 
  }

  /**
   * Sends another email with token to reset password
   * @param {*} e 
   */
  const resendToken = async (e) =>  {
    e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);
    const response = await forgotPassword(email); 
    if (response.success === false) {
        set_error_message(response.message);         
    } else {
        set_success_message(response.message);
    }
    set_action_loading(false);

    return; 
  }


  return <section id="about" class="about main-content mt-5 login-content">
    <div class="container-fluid" data-aos="fade-up">
      <div class="row mt-5">
        <div class="col-md-12 text-center">
          <h3 class="green-text">Verify account</h3>
          {error_message !== '' ? (
          <div class="col-12">
            <div class="alert alert-danger" role="alert">
              {error_message}
            </div>
          </div>): ('')}

          {success_message !== '' ? (
          <div class="col-12">
            <div class="alert alert-success" role="alert">
              {success_message}
            </div>
          </div>): ('')}
        </div>
           
        
    </div>

    <div class="row mt-5">
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center">
        {enter_email_again ? (
          <>
            <form onSubmit={resendToken} >
              <div class="form-group my-4">
                <input class="form-control" type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
              </div>
              <div class="form-group my-4">
                <button type="submit" class="btn btn-primary" disabled={action_loading}>Resend email</button>
              </div>
            </form>
          </>
          ): ('')}
        </div>
        <div class="col-md-4"></div>
    </div>
    </div>
  </section> 
}
export default VerifyAccountScreen;