import React, { useEffect, useState } from 'react';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { Link } from 'react-router-dom';


function GeneralSafetyAdviceScreen(props) {

  const [action_loading, set_action_loading] = useState(false);

  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');
  
  // eslint-disable-next-line
  useEffect(() => {
      if (action_loading === false) {
        set_action_loading(true);
        loadPage();
      }      
  });

  /**
   * Checks if a supplied token in link can reset password and is valid.
   */
  const loadPage = async () =>  {
    set_success_message('');
    set_error_message(''); 
  }

  return <section id="about" class="about main-content login-content">
    <div class="container-fluid" data-aos="fade-up">
      <div class="row">
        {/* <div class="col-md-4"></div> */}
        <div class="col-md-12 text-center">
          <h1 class="green-text">General Safety Advice</h1>
          {error_message !== '' ? (
          <div class="col-12">
            <div class="alert alert-danger" role="alert">
              {error_message}
            </div>
          </div>): ('')}

          {success_message !== '' ? (
          <div class="col-12">
            <div class="alert alert-success" role="alert">
              {success_message}
            </div>
          </div>): ('')}
        </div>

        
           
        
    </div>

    <div class="row">
      <div class="col-md-4 text-middle">
        <img src="https://www.thestreet.com/.image/t_share/MTY4NzczNjE5MTM1MjI3MzI4/13-online-shopping-safety-tips-for-the-holidays.png" alt="Error 404" class="img-thumbnail" />
      </div>

      <div class="col-md-8 text-middle">
        {/* <h1 class="orange-text my-3">General Safety Advice</h1> */}
        
        <div class="row mt-5">
                        <p class="">Please note that all sellers listed on Farm-Fresh Delights are independent sellers who have not been independently verified by Farm-Fresh Delights, nor are they endorsed by us. As such please use appropriate due diligence and caution when buying from sellers, as you would in any commercial transaction you would undertake. Tips that you can use can include but are not limited to those below:</p>
                      </div>

                      <div class="mb-5">
                        <ul>
                          <li>Research the seller. Find out what the others say about the seller. Read their reviews. Vet their social media accounts and their addresses if relevant</li>
                          <li>Verify the listed product</li>
                          <li>Read the complete item description</li>
                          <li>Guard your personal and banking details as you chat with them</li>
                          
                          <li>Use appropriate caution when meeting up with them to pick up the goods</li>
                          <li>Report scammers. You can <Link to="/contact">contact us</Link> to report a scammer</li>
                        </ul>
                      </div>
        
        {/* <Link to="/">
          <button class="btn btn-primary">HOME</button>
        </Link> */}
        
      </div>
    </div>
    </div>
  </section> 
}
export default GeneralSafetyAdviceScreen;