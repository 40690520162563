import React, { useState, useEffect } from 'react';
import { validate_role } from '../validations';
import { getUser } from '../actions/userActions';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';

function SellerCaseLogsScreen(props) {

  let user_profile;
  const permitted_role = 'seller';
  const check_permissions = validate_role(permitted_role);
  if (check_permissions.permitted === false) {
    let login_link = 'login';
    if (permitted_role === 'seller') login_link = 'access-forbidden'
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  const [error_message, set_error_message] = useState('');
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState('');
  const [page_loaded, set_page_loaded] = useState(false);

  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);
  
  // eslint-disable-next-line
  const [user_profile_in_db, set_user_profile_in_db] = useState('');

    

  const get_user_profile = async () => { 
    const { _id } = user_profile;    
    
    let this_user_profile;
    try {
      this_user_profile = await getUser(_id);
      if (this_user_profile.success === false) {
        set_error_message(this_user_profile.message);
        // console.log('error', error_message);
      } else {
        const user_details = this_user_profile.data[0];
        set_user_profile_in_db(user_details);
        set_page_loaded(true);
      }
      
    } catch (error) {
      set_error_message('An error occured fetching seller profile');
    }

    return;
  }

  useEffect(() => {
    if (page_loaded === false ) {
      get_user_profile();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  

  return (
    <>
    <section id="contact" class="contact" style={{backgroundColor: '#f8f8f8'}}>
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2><span class="black-text">My</span> <span class="green-text">Dashboard</span></h2>
        </div>       


        {!page_loaded  ? ( // so that only products will be loading at this time 
          <div>Loading ... </div>
        ) : (
          <>
            <div class="row">
              <div class="col-12">
                  {error_message !== '' ? (
                      <div class="alert alert-danger" role="alert">
                        <button type="button" class="close" data-dismiss="alert">&times;</button>
                        {error_message}
                      </div>
                    ): ('')}
                    {success_message !== '' ? (
                      <div class="alert alert-success" role="alert">
                        <button type="button" class="close" data-dismiss="alert">&times;</button>
                          {success_message}
                      </div>
                    ): ('')}     
                </div>
            </div>
          
          <div class="row">
                
            </div>
            </>
        ) }
        
      </div>
    </section>
    </>
  );
}
export default SellerCaseLogsScreen;
