import React, { useState, useEffect } from 'react';
import { validate_role } from '../validations';
import { getOrderItems } from '../actions/orderActions';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { Link } from 'react-router-dom';

function SellerOrderInvitationScreen(props) {

  const id = props.match.params.id ? props.match.params.id : '';

  let user_profile;
  const permitted_role = 'seller';
  const check_permissions = validate_role(permitted_role);
  if (check_permissions.permitted === false) {
    let login_link = 'login';
    if (permitted_role === 'seller') login_link = 'access-forbidden'
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  const [error_message, set_error_message] = useState('');
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState('');
  const [page_loaded, set_page_loaded] = useState(false);

  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);
  
  // eslint-disable-next-line
  const [order_item_details, set_order_item_details] = useState('');
  // eslint-disable-next-line 
  const [acceptance_details, set_acceptance_details] = useState({});
  // eslint-disable-next-line 
  const [preparation_details, set_preparation_details] = useState({});
  const [ready_details, set_ready_details] = useState({});
  // eslint-disable-next-line 
  const [collected_details, set_collected_details] = useState({});
  // eslint-disable-next-line 
  const [paid_details, set_paid_details] = useState({});

    

  const load_page_data = async () => { 

    set_action_loading(true);
    let get_seller_orders;

     try {
        get_seller_orders = await getOrderItems(id, 'order_id', user_profile._id, 'product', 'status');
        if (get_seller_orders.success) {
          const { data } = get_seller_orders;
          set_order_item_details(data[0]);
          set_acceptance_details(data[0].acceptance);
          set_preparation_details(data[0].preparation);
          set_ready_details(data[0].ready);
          set_collected_details(data[0].collected);
          set_paid_details(data[0].paid);        
        } else {
          set_error_message(get_seller_orders.message);
        }
      } catch (error) {
        set_error_message(`An error occured fetching seller orders: ${error.message} `);
      }

    // set_orders_to_display(_orders_to_display);

    set_action_loading(false);
    return;
  }

  useEffect(() => {
    if (page_loaded === false ) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  

  return (
    <>
    <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
  <h2><span class="black-text">Invitation to fulfill order</span> <br/> <span class="green-text"></span></h2>

  <p>You have been invited to fulfill the following order to supply produce
</p>
        </div>       


        {!page_loaded  ? ( // so that only products will be loading at this time 
          <div>Loading ... </div>
        ) : (
          <>
            <div class="row">
              <div class="col-12">
                  {error_message !== '' ? (
                      <div class="alert alert-danger" role="alert">
                        <button type="button" class="close" data-dismiss="alert">&times;</button>
                        {error_message}
                      </div>
                    ): ('')}
                    {success_message !== '' ? (
                      <div class="alert alert-success" role="alert">
                        <button type="button" class="close" data-dismiss="alert">&times;</button>
                          {success_message}
                      </div>
                    ): ('')}     
                </div>
            </div>
          
          <div class="row">
                
            </div>


            <div class="row">

              <div class="col-3"></div>
              <div class="col-6">
              <div class="table-responsive mb-5">
                <table class="table table-bordered">

                  <tbody>
                    <tr>
                      <th>Order Number</th>
                    <th>{order_item_details.display_order_number}</th>
                    </tr>

                    <tr>
                      <th>Scheduled date of collection</th>
                    <th>{ready_details.date_time}</th>
                    </tr>

                    <tr>
                      <th>Time to scheduled date of collection</th>
                    <th>...</th>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
              

              <h3>Produce</h3>
              <div class="table-responsive mb-5">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Item</th>
                      {/* <th>Description</th> */}
                      {/* <th>Code</th> */}
                      <th>Quantity</th>
                      <th>Unit Price</th>
                      {/* <th>Total</th> */}
                    </tr>
                    
                  </thead>

                  <tbody>


                  <tr>
                      
                    <td>{order_item_details.name}</td>
                    <td>{order_item_details.quantity}</td>
                    <td>US${order_item_details.price}</td>
                    {/* <th>{}</th> */}
                    </tr>

                   
                    
                  </tbody>
                </table>
              </div>

              <div class="text-center">
                <h3>Please accept or decline the invitation using the buttons below.</h3>
                <Link class="btn btn-primary btn-success mb-5 mr-5" to={`/seller-accept-invitation/${id}`} >
                  Accept
                </Link>
                    &nbsp;
                    <Link class="btn btn-primary btn-danger mb-5" to={`/seller-decline-invitation/${id}`}>
                      Decline
                    </Link>


                    <p><i>Note: This invitation may have been issued to several sellers in your area, and the order will be 
accepted on a first-come-first-serve basis. As such, this invitation may no longer be valid if another 
seller has already accepted the invitation.
</i></p>
              </div>

              

              </div>
              <div class="col-3"></div>
                
                </div>

                <div class="row">
                
                </div>


            </>
        ) }
        
      </div>
    </section>
    </>
  );
}
export default SellerOrderInvitationScreen;
