import React, { useEffect, useState } from 'react';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { Textbox, Textarea } from 'react-inputs-validation';
import { sendEmail } from '../actions/userActions';


function ContactScreen(props) {
  const redirect = props.location.search ? props.location.search.split("=")[1] : '';

  const order_id = props.match.params.order_id ? props.match.params.order_id : '';
  
  const [action_loading, set_action_loading] = useState(false);

  const [full_name, set_full_name] = useState('');
  const [email, set_email] = useState('');
  const [subject, set_subject] = useState('');
  const [message, set_message] = useState('');

  const [full_name_invalid, set_full_name_invalid] = useState(true);
  const [email_invalid, set_email_invalid] = useState(true);
  const [subject_invalid, set_subject_invalid] = useState(true);
  const [message_invalid, set_message_invalid] = useState(true);

  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');
  
  // eslint-disable-next-line
  useEffect(() => {
      if (action_loading === false) {
        set_action_loading(true);
        loadPage();
      }        
  });

  /**
   * Checks if a supplied token in link can reset password and is valid.
   */
  const loadPage = async () =>  {
    if (order_id !== '') {
        set_message(`Hi I have a problem with my order: ${order_id}. May you please check for me`)
    }
    // set_success_message('');
    // set_error_message(''); 
  }

  /**
   * Contact support
   * 
   */
  const contactSupport = async () =>  {
    // e.preventDefault();
    // console.log('contact support');

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    const response = await sendEmail(full_name, email, subject, message); 
    if (response.success === false) {
      // console.log('Error occured', response.message); 
      set_error_message(response.message);
    } else {
      set_full_name('')
      set_email('')
      set_subject('')
      set_message('')
      set_success_message('Thank you for contacting us. Your message has been sent, and we will respond as soon as possible.');
      if (redirect !== '') {
        props.history.push(redirect);
      }
    }

    // console.log('push action loading to false', error_message); 
    set_action_loading(false);

    return; 
  }

  return (
    <section id="about" class="about main-content mt-5 login-content">
        <div class="container-fluid" data-aos="fade-up">
          <div class="row mt-5">
            {/* <div class="col-md-4"></div> */}
            <div class="col-md-12 text-center mb-5">
              <h3 class="green-text">Contact Us</h3>

              {success_message !== '' ? (
              <div class="col-12">
                <div class="alert alert-success" role="alert">
                  {success_message}
                </div>
              </div>): ('')}

              {error_message !== '' ? (
              <div class="col-12">
                <div class="alert alert-danger" role="alert">
                  {error_message}
                </div>
              </div>): ('')}

              
            </div>

            <div class="col-md-8 row">
                <h2>Send email</h2>
                <div class="col-md-6 form-group my-3">
                <label for="">Full name</label>
                <Textbox
            attributesWrapper={{}}
            attributesInput={{
              id: "Name",
              name: "Name",
              type: "text",
              placeholder: "Name"
            }}
            // validate={validate}
            validationCallback={res =>
              set_full_name_invalid(res)
            } 
            value={full_name}
            disabled={false}
            classNameInput=""
            classNameWrapper=""
            classNameContainer=""
            customStyleInput={{}}
            customStyleWrapper={{}}
            customStyleContainer={{}}
            onChange={(full_name, e) => {
              set_full_name(e.target.value);
            }}
            onBlur={e => {
              console.log(e);
            }}
            // onFocus={(e) => {console.log(e);}}
            // onClick={(e) => {console.log(e);}}
            validationOption={{
              name: "Full name",
              check: true,
              required: true,
              type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
              // showMsg: true,
              // min: 2, 
              // max: 10,
              // length: 2,
              // compare: '3',
              // reg: /^\d{18}|\d{15}$/,
              // regMsg: 'failed in reg.test(${value})',
              // locale: 'en-US',
              // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
              // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
              // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
              //   if (res != 'milk') {
              //     return 'Name cannot be other things but milk';
              //   }
              //   return true;
              // }
            }}
          />
                </div>
                <div class="col-md-6 form-group my-3">
                <label for="">Email</label>
                <Textbox
            attributesWrapper={{}}
            attributesInput={{
              id: "email",
              name: "email",
              type: "text",
              placeholder: "Email"
            }}
            // validate={validate}
            validationCallback={res =>
              set_email_invalid(res)
            } 
            value={email}
            disabled={false}
            classNameInput=""
            classNameWrapper=""
            classNameContainer=""
            customStyleInput={{}}
            customStyleWrapper={{}}
            customStyleContainer={{}}
            onChange={(email, e) => {set_email(e.target.value);}}
            onBlur={e => { console.log(e);}}
            // onFocus={(e) => {console.log(e);}}
            // onClick={(e) => {console.log(e);}}
            validationOption={{
              name: "Email address",
              check: true,
              required: true,
              //type: 'email', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
              // showMsg: true,
              // min: 2, 
              // max: 10,
              // length: 2,
              // compare: '3',
              // reg: /^\d{18}|\d{15}$/,
              // regMsg: 'failed in reg.test(${value})',
              // locale: 'en-US',
              // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
              // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
              // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
              //   if (res != 'milk') {
              //     return 'Name cannot be other things but milk';
              //   }
              //   return true;
              // }
            }}
          />

                </div>
                <div class="col-md-12 form-group my-3">
                <label for="">Subject</label>
                <Textbox
            attributesWrapper={{}}
            attributesInput={{
              id: "Subject",
              name: "Subject",
              type: "text",
              placeholder: "Subject"
            }}
            // validate={validate}
            validationCallback={res =>
              set_subject_invalid(res)
            } 
            value={subject}
            disabled={false}
            classNameInput=""
            classNameWrapper=""
            classNameContainer=""
            customStyleInput={{}}
            customStyleWrapper={{}}
            customStyleContainer={{}}
            onChange={(subject, e) => {
              set_subject(e.target.value);
            }}
            onBlur={e => {
              console.log(e);
            }}
            // onFocus={(e) => {console.log(e);}}
            // onClick={(e) => {console.log(e);}}
            validationOption={{
              name: "Subject",
              check: true,
              required: true,
              type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
              // showMsg: true,
              // min: 2, 
              // max: 10,
              // length: 2,
              // compare: '3',
              // reg: /^\d{18}|\d{15}$/,
              // regMsg: 'failed in reg.test(${value})',
              // locale: 'en-US',
              // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
              // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
              // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
              //   if (res != 'milk') {
              //     return 'Name cannot be other things but milk';
              //   }
              //   return true;
              // }
            }}
          />
                </div>


                <div class="col-md-12 form-group my-3">
                <label for="">Message</label>
                          <Textarea
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "message",
                      name: "message",
                      type: "text",
                      placeholder: "Type your mesage here"
                    }}
                    // validate={validate}
                    validationCallback={res =>
                      set_message_invalid(res)
                    } 
                    value={message}
                    disabled={false}
                    classNameInput=""
                    classNameWrapper=""
                    classNameContainer=""
                    customStyleInput={{}}
                    customStyleWrapper={{}}
                    customStyleContainer={{}}
                    onChange={(message, e) => {
                      set_message(e.target.value);
                    }}
                    onBlur={e => { console.log(e);}}
                    // onFocus={(e) => {console.log(e);}}
                    // onClick={(e) => {console.log(e);}}
                    validationOption={{
                      name: "message",
                      // check: true,
                      required: false,
                      type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                      // showMsg: true,
                      // min: 2, 
                      // max: 10,
                      // length: 2,
                      // compare: '3',
                      // reg: /^\d{18}|\d{15}$/,
                      // regMsg: 'failed in reg.test(${value})',
                      // locale: 'en-US',
                      // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                      // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                      // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                      //   if (res != 'milk') {
                      //     return 'Name cannot be other things but milk';
                      //   }
                      //   return true;
                      // }
                    }}
                  />
                </div>
                <div class="col-md-12 form-group my-3">
                <button 
                            type="submit" 
                            class="btn btn-primary" 
                            disabled = { !action_loading || full_name_invalid || email_invalid || subject === '' || subject_invalid }
                            onClick={contactSupport}
                          >
                            {
                  action_loading ? ('Send message'):(
                    <>
                      <div class="spinner-border text-orange" role="status">
                      </div> 
                      &nbsp;Please wait
                    </>
                  )
                }
                          </button>
                </div>
            </div>

            <div class="col-md-1"></div>

            <div class="col-md-3 text-center" style={{margin: 'auto'}}>
                <h2><i class="bi bi-whatsapp green-text"></i> <br/>+{process.env.REACT_APP_CONTACT_PHONE}</h2>
                <br/>
                <h2><i class="bi bi-envelope green-text"></i> <br/>{process.env.REACT_APP_CONTACT_EMAIL}</h2>
                
            </div>

            

              
            
        </div>
        </div>
      </section>
  )}
export default ContactScreen;