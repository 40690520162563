import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { registerSeller } from '../actions/userActions';
import { Textbox, Checkbox } from 'react-inputs-validation';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';



function RegisterSellerScreen(props) {
  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');
  // const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [tradingName, setTradingName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [sellerType, setSellerType] = useState('Farm');
  const [show_password, set_show_password] = useState(false);

  const [firstNameInvalid, setFirstNameInvalid] = useState(true);
  const [lastNameInvalid, setLastNameInvalid] = useState(true);
  const [tradingNameInvalid, setTradingNameInvalid] = useState(true);
  const [phoneInvalid, setPhoneInvalid] = useState(true);
  const [emailInvalid, setEmailInvalid] = useState(true);
  const [passwordInvalid, setPasswordInvalid] = useState(true);
  const [rePasswordInvalid, setRePasswordInvalid] = useState(true);

  const [agree, set_agree] = useState(false);

  const userRegister = useSelector(state => state.userRegister);
  const { userInfo, error } = userRegister;

  const redirect = props.location.search ? props.location.search.split("=")[1] : '/';

  useEffect(() => {
    if (userInfo) {
      props.history.push(redirect);
    }
    return () => {
      //
    };
    // eslint-disable-next-line
  }, [userInfo]);

  const submitHandler = async (e) => {    
    e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    try {
      const response = await registerSeller(firstName, lastName, tradingName, phone, email, password, sellerType); 
      if (response.success === true) {
        // localStorage.setItem('token', response.token);
        set_success_message(response.message);
        // props.history.push('/seller-dashboard');
        setFirstName('');
        setLastName('');
        setTradingName('');
        setPhone('');
        setEmail('');
        setPassword('');
        setRePassword('');
        setSellerType('Farm');
      } else {
        set_error_message(response.message);  
      }
    } catch (error) {
      console.log(`${error.message}`);
      set_error_message('An error occured signing up your account');
    }
    
    set_action_loading(false);

    return; 
  }

  

  return (
  
  <section id="about" class="about main-content mt-5">
    <div class="container-fluid" data-aos="fade-up">
      <div class="row mt-5">
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center">
          <h3 class="green-text">SELLER REGISTRATION</h3>
          {error && <div class="col-12">
            <div class="alert alert-danger" role="alert">
              {error}
            </div>
          </div> }

          <div class="col-12">
                  {error_message !== '' ? (
                      <div class="alert alert-danger" role="alert">
                        {error_message}
                      </div>
                    ): ('')}
                    {success_message !== '' ? (
                      <div class="alert alert-success" role="alert">
                          {success_message}
                      </div>
                    ): ('')}     
                </div>
                            

          <form onSubmit={submitHandler} >
            <div class="form-group my-4">
            

              <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "Name",
                      name: "Name",
                      type: "text",
                      placeholder: "First name"
                    }}
                    // validate={validate}
                    validationCallback={res =>
                      setFirstNameInvalid(res)
                    } 
                    value={firstName}
                    disabled={false}
                    classNameInput=""
                    classNameWrapper=""
                    classNameContainer=""
                    customStyleInput={{}}
                    customStyleWrapper={{}}
                    customStyleContainer={{}}
                    onChange={(firstName, e) => {
                      setFirstName(e.target.value);
                    }}
                    onBlur={e => {
                      console.log(e);
                    }}
                    // onFocus={(e) => {console.log(e);}}
                    // onClick={(e) => {console.log(e);}}
                    validationOption={{
                      name: "First name",
                      check: true,
                      required: true,
                      type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                      // showMsg: true,
                      // min: 2, 
                      // max: 10,
                      // length: 2,
                      // compare: '3',
                      // reg: /^\d{18}|\d{15}$/,
                      // regMsg: 'failed in reg.test(${value})',
                      // locale: 'en-US',
                      // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                      // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                      // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                      //   if (res != 'milk') {
                      //     return 'Name cannot be other things but milk';
                      //   }
                      //   return true;
                      // }
                    }}
                  />
            </div>
            <div class="form-group my-4">
              {/* <input  class="form-control" type="name" name="last_name" id="last_name" onChange={(e) => setLastName(e.target.value)} placeholder="Surname" /> */}
              <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "Surname",
                      name: "Surname",
                      type: "text",
                      placeholder: "Surname"
                    }}
                    // validate={validate}
                    validationCallback={res =>
                      setLastNameInvalid(res)
                    } 
                    value={lastName}
                    disabled={false}
                    classNameInput=""
                    classNameWrapper=""
                    classNameContainer=""
                    customStyleInput={{}}
                    customStyleWrapper={{}}
                    customStyleContainer={{}}
                    onChange={(laststName, e) => {
                      setLastName(e.target.value);
                    }}
                    onBlur={e => {
                      console.log(e);
                    }}
                    // onFocus={(e) => {console.log(e);}}
                    // onClick={(e) => {console.log(e);}}
                    validationOption={{
                      name: "Surname",
                      check: true,
                      required: true,
                      type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                      // showMsg: true,
                      // min: 2, 
                      // max: 10,
                      // length: 2,
                      // compare: '3',
                      // reg: /^\d{18}|\d{15}$/,
                      // regMsg: 'failed in reg.test(${value})',
                      // locale: 'en-US',
                      // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                      // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                      // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                      //   if (res != 'milk') {
                      //     return 'Name cannot be other things but milk';
                      //   }
                      //   return true;
                      // }
                    }}
                  />
            </div>
            <div class="form-group my-4">
              {/* <input  class="form-control" type="text" name="phone" id="phone" onChange={(e) => setPhone(e.target.value)} placeholder="Phone Number" /> */}
              <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "Phone",
                      name: "Phone",
                      type: "text",
                      // placeholder: "Phone number. eg 263774756502"
                    }}
                    // validate={validate}
                    validationCallback={res =>
                      setPhoneInvalid(res)
                    } 
                    value={phone}
                    disabled={false}
                    classNameInput=""
                    classNameWrapper=""
                    classNameContainer=""
                    customStyleInput={{}}
                    customStyleWrapper={{}}
                    customStyleContainer={{}}
                    onChange={(phone, e) => {
                      setPhone(e.target.value);
                    }}
                    onBlur={e => {
                      console.log(e);
                    }}
                    // onFocus={(e) => {console.log(e);}}
                    // onClick={(e) => {console.log(e);}}
                    validationOption={{
                      name: "Phone number",
                      check: true,
                      required: true,
                      type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                      // showMsg: true,
                      // min: 2, 
                      // max: 10,
                      // length: 2,
                      // compare: '3',
                      reg: /^\d{7}|\d{16}$/,
                      regMsg: 'Invalid phone number',
                      // locale: 'en-US',
                      // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                      // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                      // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                      //   if (res != 'milk') {
                      //     return 'Name cannot be other things but milk';
                      //   }
                      //   return true;
                      // }
                    }}
                  />
            </div>
            <div class="form-group my-4">
              {/* <input class="form-control" type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)} placeholder="Email" /> */}
              <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "email",
                      name: "email",
                      type: "text",
                      placeholder: "Email"
                    }}
                    // validate={validate}
                    validationCallback={res =>
                      setEmailInvalid(res)
                    } 
                    value={email}
                    disabled={false}
                    classNameInput=""
                    classNameWrapper=""
                    classNameContainer=""
                    customStyleInput={{}}
                    customStyleWrapper={{}}
                    customStyleContainer={{}}
                    onChange={(email, e) => {setEmail(e.target.value);}}
                    onBlur={e => { console.log(e);}}
                    // onFocus={(e) => {console.log(e);}}
                    // onClick={(e) => {console.log(e);}}
                    validationOption={{
                      name: "Email address",
                      check: true,
                      required: true,
                      //type: 'email', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                      // showMsg: true,
                      // min: 2, 
                      // max: 10,
                      // length: 2,
                      // compare: '3',
                      // reg: /^\d{18}|\d{15}$/,
                      // regMsg: 'failed in reg.test(${value})',
                      // locale: 'en-US',
                      // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                      // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                      // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                      //   if (res != 'milk') {
                      //     return 'Name cannot be other things but milk';
                      //   }
                      //   return true;
                      // }
                    }}
                  />
                  
            </div>
            <div class="form-group my-4">
              {/* <input  class="form-control" type="text" name="trading_name" id="trading_name" onChange={(e) => setTradingName(e.target.value)} placeholder="Trading Name" /> */}
              <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "farmName",
                      name: "farmName",
                      type: "text",
                      placeholder: "Trading name"
                    }}
                    // validate={validate}
                    validationCallback={res =>
                      setTradingNameInvalid(res)
                    } 
                    value={tradingName}
                    disabled={false}
                    classNameInput=""
                    classNameWrapper=""
                    classNameContainer=""
                    customStyleInput={{}}
                    customStyleWrapper={{}}
                    customStyleContainer={{}}
                    onChange={(tradingName, e) => {
                      setTradingName(e.target.value);
                    }}
                    onBlur={e => { console.log(e);}}
                    // onFocus={(e) => {console.log(e);}}
                    // onClick={(e) => {console.log(e);}}
                    validationOption={{
                      name: "Trading name",
                      check: true,
                      required: true,
                      type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                      // showMsg: true,
                      // min: 2, 
                      // max: 10,
                      // length: 2,
                      // compare: '3',
                      // reg: /^\d{18}|\d{15}$/,
                      // regMsg: 'failed in reg.test(${value})',
                      // locale: 'en-US',
                      // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                      // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                      // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                      //   if (res != 'milk') {
                      //     return 'Name cannot be other things but milk';
                      //   }
                      //   return true;
                      // }
                    }}
                  />
            </div>
            <div class="form-group my-4">
                  {/* <input class="form-control" type="password" id="password" name="password" onChange={(e) => setPassword(e.target.value)} placeholder="Password" /> */}
                  
                  <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "password",
                      name: "password",
                      type: show_password ? "text" : "password",
                      placeholder: "Password",
                      minLength: "6",
                      maxLength: "14"
                    }}
                    // validate={validate}
                    validationCallback={res =>
                      setPasswordInvalid(res)
                    }
                    value={password}
                    disabled={false}
                    classNameInput=""
                    classNameWrapper=""
                    classNameContainer=""
                    customStyleInput={{}}
                    customStyleWrapper={{}}
                    customStyleContainer={{}}
                    onChange={(password, e) => {
                      setPassword(e.target.value);
                    }}
                    onBlur={e => { console.log(e); }}
                    // onFocus={(e) => {console.log(e);}}
                    // onClick={(e) => {console.log(e);}}
                    validationOption={{
                      name: "Account password",
                      check: true,
                      required: true,
                      type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                      // showMsg: true,
                      // min: 2, 
                      // max: 10,
                      // length: 2,
                      // compare: '3',
                      // reg: /^\d{18}|\d{15}$/,
                      // regMsg: 'failed in reg.test(${value})',
                      // locale: 'en-US',
                      // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                      // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                      // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                      //   if (res != 'milk') {
                      //     return 'Name cannot be other things but milk';
                      //   }
                      //   return true;
                      // }
                    }}
                  />
                </div>
                <div class="form-group my-4">
                  {/* <input class="form-control" type="password" id="rePassword" name="rePassword" onChange={(e) => setRePassword(e.target.value)} placeholder="Confirm Password" /> */}
                  <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "confirmPassword",
                      name: "confirmPassword",
                      type: show_password ? "text" : "password",
                      placeholder: "Confirm password",
                      minLength: "6",
                      maxLength: "14"
                    }}
                    // validate={validate}
                    validationCallback={res =>
                      setRePasswordInvalid(res)
                    }
                    value={rePassword}
                    disabled={false}
                    classNameInput=""
                    classNameWrapper=""
                    classNameContainer=""
                    customStyleInput={{}}
                    customStyleWrapper={{}}
                    customStyleContainer={{}}
                    onChange={(rePassword, e) => {
                      setRePassword(e.target.value);
                    }}
                    onBlur={e => { console.log(e); }}
                    // onFocus={(e) => {console.log(e);}}
                    // onClick={(e) => {console.log(e);}}
                    validationOption={{
                      name: "Confirm password",
                      check: true,
                      required: true,
                      type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                      // showMsg: true,
                      // min: 2, 
                      // max: 10,
                      // length: 2,
                      // compare: '3',
                      // reg: /^\d{18}|\d{15}$/,
                      // regMsg: 'failed in reg.test(${value})',
                      // locale: 'en-US',
                      // msgOnError: "Your two passwords do not match", 
                      // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                      customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                        if (res !== password) {
                          return 'Your two passwords do not match';
                        }
                        return true;
                      }
                    }}
                  />
                  
                  <button class="green-text mt-3 bi bi-eye btn-transparent" onClick={()=>{set_show_password(!show_password)}} style={{backgroundColor: 'transparent', border: '0px',}}> {show_password ? "Hide" : "Show"} password</button>
                </div>

            <div class="form-group my-4">
              <select  class="form-control" name="seller_type" id="seller_type" onChange={(e) => setSellerType(e.target.value)}>
                <option>Sell produce from</option>
                <option value="Farm">Farm</option>
                            <option value="Flea Market">Flea Market</option>
                            <option value="Home">Home</option>
                            <option value="Market Stall">Market Stall</option>
                            <option value="Shop/Tuckshop/Spaza">Shop/Tuckshop/Spaza</option>
                            <option value="Supermarket">Supermarket</option>
                            <option value="Other">Other</option>
              </select>
            </div>

            <div class="form-group my-4 text-left">
            {/* <small>Your phone number and email will be displayed online to enable buyers to contact you</small> */}
            <br/>
            <Checkbox
                        attributesInput={{
                          id: 'agree',
                          name: 'agree',
                        }}
                        onBlur={() => {}} 
                        checked={agree}
                        onChange={(e) => {
                          set_agree(e);
                        }}
                        labelHtml={
                          <div style={{ color: '#4a4a4a', marginTop: '2px' }}>
                            Your phone number and email will be displayed online to enable buyers to contact you. Agree?
                          </div>
                        } // Required.[Html].Default: none.
                        validationOption={{
                          name: 'agree', // Optional.[String].Default: "". To display in the Error message. i.e Please check the .
                          check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                          required: true, // Optional.[Bool].Default: true. To determin if it is a required field.
                        }}
                      />
            </div>
            
            <div class="form-group my-4">
              <button 
                type="submit" 
                class="btn btn-primary" 
                disabled={ action_loading || firstNameInvalid || lastNameInvalid || phoneInvalid || emailInvalid || tradingNameInvalid || passwordInvalid || rePasswordInvalid || !agree}
                //  || lastNameInvalid || phoneInvalid || emailInvalid 
              >
                {
                  !action_loading ? ('Register'):(
                    <>
                      <div class="spinner-border text-orange" role="status">
                        {/* <span class="sr-only">Loading...</span> */}
                      </div> 
                      &nbsp;Creating account
                    </>
                  )
                }
                
              </button>
              {/* &nbsp;or&nbsp; */}
              {/* <Link to={redirect === "/" ? "login-seller" : "login-seller?redirect=" + redirect} className="btn btn-primary faded-green-shade" >Login</Link> */}
            </div>

            <div class="form-group my-4 text-center">
              Already have an account? <Link to="login-seller" className="green-text" >Login</Link>
            </div>
          </form>
        </div>
        <div class="col-md-4"></div>
    </div>
    </div>
  </section> 
  )
}
export default RegisterSellerScreen;