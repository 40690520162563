import React, { useEffect, useState } from 'react';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { getOrders } from '../actions/orderActions';


function TrackScreen(props) {
  // eslint-disable-next-line
  const order_id = props.match.params.order_id ? props.match.params.order_id : '';
  const tracking_number = props.match.params.tracking_number ? props.match.params.tracking_number : '';
  
  const [action_loading, set_action_loading] = useState(false);

  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');

  const [order_details, set_order_details] = useState({});
  
  // eslint-disable-next-line
  useEffect(() => {
      if (action_loading === false) {
        set_action_loading(true);
        loadPage();
      }        
  });

  /**
   * Checks if a supplied token in link can reset password and is valid.
   */
  const loadPage = async () =>  {
    set_success_message('');
    set_error_message(''); 

    set_action_loading(true)

    let get_orders;
     try {
        get_orders = await getOrders(order_id, 'phone', 'email', 'city', 'delivery_zone', 'payment_method', 'client_id', 'status');

        if (get_orders.success) {
          const { data } = get_orders;
          set_order_details(data[0]);  
        } else {
          set_error_message(get_orders.message);
        }
      } catch (error) {
        set_error_message(`An error occured fetching seller orders: ${error.message} `);
      }

      set_action_loading(false);
  }

  return (
    <section id="about" class="about main-content mt-5 login-content">
        <div class="container-fluid" data-aos="fade-up">
          <div class="row mt-5">
            {/* <div class="col-md-4"></div> */}
            <div class="col-md-12 text-center mb-5">
              <h3 class="green-text">Track order status</h3>

              {error_message !== '' ? (
              <div class="col-12">
                <div class="alert alert-danger" role="alert">
                  {error_message}
                </div>
              </div>): ('')}

              {success_message !== '' ? (
              <div class="col-12">
                <div class="alert alert-success" role="alert">
                  {success_message}
                </div>
              </div>): ('')}
            </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="table responsive">
              <table class="table table-bordered">
                <tr>
                  <th>Order</th>
                  <td>
                    <p>Order successfully made.</p>
                    <p>Order tracking number: <b>{tracking_number}</b></p>
                  </td>
                </tr>

                <tr>
                  <th>Payment</th>
                  <td>
                    <p>Payment method: <b>{order_details.payment_method}</b></p>
                    <p>Payment status: <b>{order_details.payment_status}</b></p>
                  </td>
                </tr>

                <tr>
                  <th>Pickup from seller(s)</th>
                  <td>
                    {order_details.status === 'Completed' ? ('Done'):(
                      <>
                        Status: <b>Pending</b>
                      </>
                    ) }
                    
                  </td>
                </tr>

                <tr>
                  <th>Delivery</th>
                  <td>
                  {order_details.status === 'Completed' ? ('Delivered'):(
                      <>
                        Status: <b>Pending</b>
                      </>
                    ) }
                  </td>
                </tr>
              </table>

            </div>
          </div>
        </div>
        </div>
      </section>
  )}
export default TrackScreen;