import React, { useEffect, useState } from 'react';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';


function FaqScreen(props) {

  const [action_loading, set_action_loading] = useState(false);

  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');

  const faqs = [
    {
        number: 1,
        question: 'What is Farm-Fresh Delights?',
        answer: 'Farm-Fresh Delights is a platform where any farmer who produces farm produce can register and list their produce for sale, and where anyone looking for farm produce can search for the produce and buy',
        category: 'general',
    },
    {
        number: 2,
        question: 'Where does Farm-Fresh Delights operate?',
        answer: 'As a seller, you can list farm produce for sale from anywhere in any country that Farm-Fresh Delights operates! Buyers will only see purchase options from those locations where produce is available on the platform, but as a registered buyer, you can leave an alert for produce from any location',
        category: 'general',
    },
    {
        number: 3,
        question: 'Who can register on Farm-Fresh Delights?',
        answer: 'Anyone, buyer or seller, can register on Farm-Fresh Delights.',
        category: 'general',
    },
    {
        number: 4,
        question: 'How do I signup and register to sell on the Farm-Fresh Delights?',
        answer: 'You can signup to sell on Farm-Fresh Delights through <a href="/register-as-seller?redirect=seller-dashboard">this link</a>',
        category: 'general',
    },
    {
        number: 5,
        question: 'Why should I register as a seller?',
        answer: 'If you produce farm produce, more people anywhere in the world will be able to see what you are selling (think Diaspora buying for their relatives, for example). You can market to more people and increase your market reach. If you sell quality farm produce, your reputation as a quality producer will grow as buyers leave good ratings, which will attract even more customers. <a href="/register-as-seller?redirect=seller-dashboard">Register now</a> or <a href="/become-a-seller">click here to see more information about registering as a seller.</a>',
        category: 'general',
    },
    {
        number: 6,
        question: 'As a buyer, do I need to register in order search for and buy produce through Farm-Fresh Delights?',
        answer: 'No, you do not need to be registered to search for produce or buy through Farm-Fresh Delights. However, registering provides several benefits.',
        category: 'general',
    },
    {
        number: 7,
        question: 'Why should I register as a buyer?',
        answer: 'Lots of advantages. As a registered buyer, you can search for form produce that is currently not available, and we will inform farmers what you are looking for, then we will notify you when the produce becomes available. As a registered buyer, you also get the convenience of making purchases without having to enter your details each time, and you can create recurring orders. <a href="/register">Register now</a>',
        category: 'general',
    },
    {
        number: 8,
        question: 'Do you deliver?',
        answer: 'We do not deliver at the moment, but this is a service that we will introduce on a case-by-case basis in areas where buyers show sufficient demand for delivery services',
        category: 'general',
    },
    {
        number: 9,
        question: 'As a buyer, how can I be assured that the produce I am buying is of good quality',
        answer: 'Since you are making a direct arrangement with the seller, you need to do due diligence to ensure you are getting good quality produce. However, we do provide some indicative crowd-sourced quality indicators, such as other buyers leaving ratings for verified purchases, or number of sales made. Despite this, please do due diligence when making any purchases.',
        category: 'general',
    },
    {
        number: 10,
        question: 'What are the payment options on the platform?',
        answer: 'Currently all sales and payments are made as a direct arrangement between the buyer and the seller. We plan on introducing online payments and mobile payments in the near future.',
        category: 'general',
    },
    {
      number: 11,
      question: 'How much do I get charged for using the platform?',
      answer: 'As a promotion, we are making subscription to the platform completely free to sellers for the first year of usage! After the first year, you can renew your subscription from a selection of various packages. Buyers do not need to pay anything to use the platform, although advanced procurement services can be arranged for buyers requiring advanced services or bulk purchases. <a href="/contact">Contact us</a> for more details ',
      category: 'general',
  },
  {
    number: 12,
    question: 'How do I receive my money if a sale is made on the platform?',
    answer: 'Currently all sales and payments are made as a direct arrangement between the buyer and the seller. We plan on introducing online payments and mobile payments in the near future.',
    category: 'general',
},
{
  number: 13,
  question: 'How do I delete my account?',
  answer: 'Currently all sales and payments are made as a direct arrangement between the buyer and the seller. We plan on introducing online payments and mobile payments in the near future.',
  category: 'general',
},
{
  number: 14,
  question: 'What are the payment options on the platform?',
  answer: 'You can <a href="/contact">contact us</a> and we will delete the account on your behalf.',
  category: 'general',
},
{
  number: 15,
  question: 'How can I get a custom store designed for me?',
  answer: 'You can contact our team and we will help you with that.',
  category: 'general',
},
{
  number: 16,
  question: 'What other services do you provide?',
  answer: 'We can design custom online stores for sellers who want advanced functionality, and we can also provide  procurement services for buyers with specialty of bulk-buying needs. Please <a href="/contact">contact us</a> for more details ',
  category: 'general',
},
  ]
  
  // eslint-disable-next-line
  useEffect(() => {
      if (action_loading === false) {
        set_action_loading(true);
        loadPage();
      }        
  });

  /**
   * Checks if a supplied token in link can reset password and is valid.
   */
  const loadPage = async () =>  {
    set_success_message('');
    set_error_message(''); 
  }

  return <section id="about" class="about main-content mt-5 login-content">
    <div class="container-fluid" data-aos="fade-up">
      <div class="row mt-5">
        {/* <div class="col-md-4"></div> */}
        <div class="col-md-12 text-center">
          <h3 class="green-text">Frequently asked questions</h3>
          {error_message !== '' ? (
          <div class="col-12">
            <div class="alert alert-danger" role="alert">
              {error_message}
            </div>
          </div>): ('')}

          {success_message !== '' ? (
          <div class="col-12">
            <div class="alert alert-success" role="alert">
              {success_message}
            </div>
          </div>): ('')}
        </div>


        <div class="col-md-12">
            <div class="accordion accordion-flush" id="accordionFlushExample">

            { faqs.map((f) => (
                <div class="accordion-item">
                    <h2 class="accordion-header" id={`flush-heading${f.number}`}>
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${f.number}`} aria-expanded="false" aria-controls={`flush-collapse${f.number}`}>
                        {f.question}
                    </button>
                    </h2>
                    <div id={`flush-collapse${f.number}`} class="accordion-collapse collapse" aria-labelledby={`flush-heading${f.number}`} data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                    <div dangerouslySetInnerHTML={{ __html: f.answer }} />
                     </div>
                    </div>
                </div>
            ))}
                
                </div>
        </div>
           
        
    </div>
    </div>
  </section> 
}
export default FaqScreen;