import React, { useEffect, useState } from 'react';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { Link } from 'react-router-dom';


function Error404Screen(props) {

  const [action_loading, set_action_loading] = useState(false);

  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');
  
  // eslint-disable-next-line
  useEffect(() => {
      if (action_loading === false) {
        set_action_loading(true);
        loadPage();
      }      
  });

  /**
   * Checks if a supplied token in link can reset password and is valid.
   */
  const loadPage = async () =>  {
    set_success_message('');
    set_error_message(''); 
  }

  return <section id="about" class="about main-content login-content">
    <div class="container-fluid" data-aos="fade-up">
      <div class="row">
        {/* <div class="col-md-4"></div> */}
        <div class="col-md-12 text-center">
          {/* <h3 class="green-text">Error 404</h3> */}
          {error_message !== '' ? (
          <div class="col-12">
            <div class="alert alert-danger" role="alert">
              {error_message}
            </div>
          </div>): ('')}

          {success_message !== '' ? (
          <div class="col-12">
            <div class="alert alert-success" role="alert">
              {success_message}
            </div>
          </div>): ('')}
        </div>

        
           
        
    </div>

    <div class="row">
      <div class="col-md-4">
        <img src="assets/img/error404.jpg" alt="Error 404" class="img-thumbnail" />
      </div>

      <div class="col-md-8 text-center text-middle">
        <h1 class="orange-text my-3">Error 404</h1>
        <h2 class="my-3">Uh Oh! You're lost.</h2>
        <p>The page you are looking for does not exists. How you got here is a mystery. But you can click the button below to go back to the homepage. </p>
        <Link to="/">
          <button class="btn btn-primary">HOME</button>
        </Link>
      </div>
    </div>
    </div>
  </section> 
}
export default Error404Screen;