import React, { useState, useEffect, useRef } from 'react';
import { validate_multiple_roles } from '../validations';
import { getUser, updateUserDetails } from '../actions/userActions';
import { getCities, getCountries, getSuburbs } from '../actions/categoryActions';
import { Textbox } from 'react-inputs-validation';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import axios from 'axios';
import { APP_URL, API_URL, API_KEY, token_string } from '../config'
import { Link } from 'react-router-dom';
import { getVerifications, updateVerification } from '../actions/verificationActions';

function SellerVerificationDetailsScreen(props) {
    let user_profile;
    const permitted_roles = ['verifier', 'admin'];
    const check_permissions = validate_multiple_roles(permitted_roles);
    if (check_permissions.permitted === false) {
        const login_link = 'access-forbidden';
        props.history.push(login_link);
    } else {
        user_profile = check_permissions.user_object;

        // if (!(user_profile.roles).includes('seller')) {
        //   props.history.push('buyer-profile')
        // }
    }

    const id = props.match.params.id ? props.match.params.id : '';

    const [error_message, set_error_message] = useState('');
    const [success_message, set_success_message] = useState('');
    const [page_loaded, set_page_loaded] = useState(false);
    const [action_loading, set_action_loading] = useState(false);


    const [cities, set_cities] = useState([]);
    const [countries, set_countries] = useState([]);
    const [suburbs, set_suburbs] = useState([]);

    const [first_name, set_first_name] = useState('');
    const [username, set_username] = useState('');
    const [last_name, set_last_name] = useState('');
    const [trading_name, set_trading_name] = useState('');
    const [phone, set_phone] = useState('');
    const [email, set_email] = useState('');
    const [seller_type, set_seller_type] = useState('Farm');

    const [telephone, set_telephone] = useState('');
    const [display_name, set_display_name] = useState('');
    const [description, set_description] = useState('');
    const [address_line1, set_address_line1] = useState('');
    const [address_line2, set_address_line2] = useState('');
    const [suburb, set_suburb] = useState('');
    const [city, set_city] = useState('Select');
    const [province, set_province] = useState('');
    const [country, set_country] = useState('');
    const [country_meta, set_country_meta] = useState('');
    const [city_meta, set_city_meta] = useState('');
    // eslint-disable-next-line
    const [seller_image, set_seller_image] = useState('');

    const [provide_delivery, set_provide_delivery] = useState(false);
    const [delivery_charge_type, set_delivery_charge_type] = useState('');
    const [delivery_charge, set_delivery_charge] = useState('');
    const [delivery_purchase_minimum, set_delivery_purchase_minimum] = useState('');
    const [delivery_maximum_radius, set_delivery_maximum_radius] = useState('');
    

    const [organic_check, set_organic_check] = useState(false);
    const [organic_check_status, set_organic_check_status] = useState(false);
    const [admin_verified_status, set_admin_verified_status] = useState('');
    const [admin_verified_check, set_admin_verified_check] = useState(false);

    const [latitude, set_latitude] = useState('');
    const [longitude, set_longitude] = useState('');

    const [visit_date, set_visit_date] = useState('');
    const [farmer_name, set_farmer_name] = useState('');
    const [seller_id, set_seller_id] = useState('');
    const [quality_of_produce, set_quality_of_produce] = useState('');
    const [hygienic_handling_and_processing, set_hygienic_handling_and_processing] = useState('');
    const [water_for_washing_available, set_water_for_washing_available] = useState('');
    const [farm_size, set_farm_size] = useState('');
    const [estimated_capacity, set_estimated_capacity] = useState('');
    const [delivery_available, set_delivery_available] = useState('');
    const [organic, set_organic] = useState('');
    const [evidence_of_chemicals_on_plant, set_evidence_of_chemicals_on_plant] = useState('');
    const [have_organic_certificate, set_have_organic_certificate] = useState('');
    const [verified_crops, set_verified_crops] = useState('');
    const [recommendation, set_recommendation] = useState('');
    const [notes, set_notes] = useState('');

    const [reference_number, set_reference_number] = useState('');
    const [proof_of_payment, set_proof_of_payment] = useState('');
    const [status, set_status] = useState('');

    const [photo_of_field_with_crops, set_photo_of_field_with_crops] = useState('');
    const [photo_of_produce, set_photo_of_produce] = useState('');
    const [photo_of_organic_certificate, set_photo_of_organic_certificate] = useState('');

    const [admin_verification, set_admin_verification] = useState({
        status: 'Not Verified',
        comments: '',
    });


    // eslint-disable-next-line
    const [first_name_invalid, set_first_name_invalid] = useState(true);
    // eslint-disable-next-line
    const [last_name_invalid, set_last_name_invalid] = useState(true);
    // eslint-disable-next-line
    const [trading_name_invalid, set_trading_name_invalid] = useState(true);
    // eslint-disable-next-line
    const [phone_invalid, set_phone_invalid] = useState(true);
    // eslint-disable-next-line
    const [email_invalid, set_email_invalid] = useState(true);
    // eslint-disable-next-line
    const [seller_type_invalid, set_seller_type_invalid] = useState(true);
    // eslint-disable-next-line
    const [telephone_invalid, set_telephone_invalid] = useState(true);
    // eslint-disable-next-line
    const [display_name_invalid, set_display_name_invalid] = useState(true);
    // eslint-disable-next-line
    const [description_invalid, set_description_invalid] = useState(true);
    // eslint-disable-next-line
    const [address_line1_invalid, set_address_line1_invalid] = useState(true);
    // eslint-disable-next-line
    const [address_line2_invalid, set_address_line2_invalid] = useState(true);
    // eslint-disable-next-line
    const [suburb_invalid, set_suburb_invalid] = useState(true);
    // eslint-disable-next-line
    const [city_invalid, set_city_invalid] = useState(true);
    // eslint-disable-next-line
    const [province_invalid, set_province_invalid] = useState(true);
    // eslint-disable-next-line
    const [country_invalid, set_country_invalid] = useState(false);
    // eslint-disable-next-line
    const [onsite_invalid, set_onsite_invalid] = useState(true);

    // eslint-disable-next-line
    const [user_profile_in_db, set_user_profile_in_db] = useState('');

    const [file, setFile] = useState(''); // storing the uploaded file
    // eslint-disable-next-line
    const [progress, setProgess] = useState(0); // progess bar
    const el = useRef(); // accesing input element   

    const new_photo_of_organic_certificate = (e) => {
        setProgess(0)
        let file = e.target.files[0]
        set_photo_of_organic_certificate(file)
    }
    const new_photo_of_produce = (e) => { set_photo_of_produce(e.target.files[0]) }
    const new_photo_of_field_with_crops = (e) => { set_photo_of_field_with_crops(e.target.files[0]) }

    /**
     * 
     * @returns updates a client profile picture
     */
    // eslint-disable-next-line 
    const update_verification_details = async (e) => {
        e.preventDefault();
        set_success_message('');
        set_error_message('');

        set_action_loading(true);
        try {
            if (token_string === null) { token_string = localStorage.getItem('token') }

            const formData = new FormData();
            formData.append('api_key', API_KEY);
            formData.append('visit_date', visit_date)
            formData.append('quality_of_produce', quality_of_produce)
            formData.append('hygienic_handling_and_processing', hygienic_handling_and_processing)
            formData.append('water_for_washing_available', water_for_washing_available)
            formData.append('farm_size', farm_size)
            formData.append('estimated_capacity', estimated_capacity)
            formData.append('delivery_available', delivery_available)
            formData.append('organic', organic)
            formData.append('evidence_of_chemicals_on_plant', evidence_of_chemicals_on_plant)
            formData.append('have_organic_certificate', have_organic_certificate)
            formData.append('photo_of_organic_certificate', photo_of_organic_certificate)
            formData.append('latitude', latitude)
            formData.append('longitude', longitude)
            formData.append('photo_of_produce', photo_of_produce)
            formData.append('photo_of_field_with_crops', photo_of_field_with_crops)
            formData.append('recommendation', recommendation)
            formData.append('verified_crops', verified_crops)
                
            formData.append('update_key', 'basic_detail');
            formData.append('id', id);
            // console.log('about to axio', token_string)
            const { data } = await axios.patch(`${API_URL}/verifications`,
                formData, {
                headers: {
                    Authorization: 'Bearer ' + token_string
                },
            })

            console.log('data', data)
            if (data.success === true) {
                set_success_message('Update successful');
                props.history.push(`/seller-verification-details/${id}`)
            } else if (data.success === false) {
                set_error_message(data.message);
            } 
        } catch (error) {
            console.log('error', error.message)
            set_error_message('An error occured updating verification details')
        }

//         , {
//             onUploadProgress: (ProgressEvent) => {
//                 let progress = Math.round(
//                     ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
//                 setProgess(progress);
//             }
// }).then(res => {
//     const { data } = res;
//     if (data.success === true) {
//         set_success_message('Update successful');
//         props.history.push(`seller-verification-details/${id}`)
//     } else if (data.success === false) {
//         set_error_message(data.message);
//     } else {
//         set_error_message(`An error occured updating profile picture`);
//     }
//     console.log(data, 'then')
// }).catch(
//     console.log('exc')
//     // set_error_message('An error occured updating profile picture')
// );

await load_page_data();

set_action_loading(false);
return;
    }




const load_page_data = async () => {

    let get_orders;
    try {
        get_orders = await getVerifications(id, user_profile._id, 'seller_id', 'Pending')

        // console.log(get_orders)

        if (get_orders.success) {
            let { data } = get_orders;

            set_farmer_name((data[0].seller_details.seller_details[0]).trading_name)
            set_visit_date(data[0].visit_date)
            set_quality_of_produce(data[0].quality_of_produce)
            set_hygienic_handling_and_processing(data[0].hygienic_handling_and_processing)
            set_water_for_washing_available(data[0].water_for_washing_available)
            set_farm_size(data[0].farm_size)
            set_delivery_available(data[0].delivery_available)
            set_organic(data[0].organic)
            set_evidence_of_chemicals_on_plant(data[0].evidence_of_chemicals_on_plant)
            set_photo_of_organic_certificate(data[0].photo_of_organic_certificate)
            set_verified_crops(data[0].verified_crops)
            set_photo_of_produce(data[0].photo_of_produce)
            set_photo_of_field_with_crops(data[0].photo_of_field_with_crops)
            set_have_organic_certificate(data[0].have_organic_certificate)
            set_estimated_capacity(data[0].estimated_capacity)
            set_seller_id(data[0].seller_id)

            set_proof_of_payment(data[0].proof_of_payment)
            set_reference_number(data[0].reference_number)
            set_status(data[0].status)


        } else {
            set_error_message('No pending verification requests found.');
        }
    } catch (error) {
        set_error_message(`No pending verification requests found`);
    }

    // const geolocationAPI = navigator.geolocation;
    // console.log('geo location api', geolocationAPI)
    // if (!geolocationAPI) {
    //     console.error('Geolocation not found in browser')
    // } else {
    //     await geolocationAPI.getCurrentPosition((position) => {
    //         console.log('position', position)
    //         const { coords } = position;
    //         set_latitude(coords.latitude)
    //         set_longitude(coords.longitude)
    //     }, (error) => {
    //         console.log('Error occured getting location.', error)
    //         // setError('Something went wrong getting your position!')
    //     })
    // }

    return;
}

/**
 * Updates user profile
 * @param {*} e 
 */
const updateProfile = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    const update_data = {
        first_name,
        last_name,
        phone,
        email,
        telephone,
        display_name,
        organic: {
            check: organic_check,
        },
        delivery: {
            provides: provide_delivery,
            charge: delivery_charge,
            charge_type: delivery_charge_type,
            purchase_minimum: delivery_purchase_minimum,
            maximum_radius: delivery_maximum_radius
        },
        seller_details: [
            {
                seller_type,
                trading_name,
                description,
                address_line1,
                address_line2,
                suburb,
                city,
                province,
                country,
                latitude,
                longitude,
            }
        ],
    };

    const response = await updateUserDetails(update_data, 'basic_detail');
    if (response.success === false) {
        set_error_message(response.message);

    } else {
        set_success_message('Account details successfully updated.');
    }
    set_action_loading(false);

    return;
}


const requestVerification = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    const update_data = {};

    const response = await updateUserDetails(update_data, 'request_verification');
    if (response.success === false) {
        set_error_message(response.message);

    } else {
        await load_page_data()
        set_success_message('Verification request successfully sent.')

    }
    set_action_loading(false);

    return;
}

useEffect(() => {
    if (page_loaded === false) {
        load_page_data();
        set_page_loaded(true);
    }
    // eslint-disable-next-line
}, []);

const country_changed = async (new_country) => {
    set_country(new_country)
    set_city('')
    set_suburb('')

    for (let i = 0; i < countries.length; i++) {
        if (countries[i].country === new_country) {
            set_country_meta(countries[i].meta)
            return;
        }
    }
}

const city_changed = async (new_city) => {
    set_city(new_city)
    set_suburb('')

    for (let i = 0; i < cities.length; i++) {
        if (cities[i].city === new_city) {
            // console.log(cities[i].meta)
            set_city_meta(cities[i].meta)
            return;
        }
    }
}

const organic_check_changed = async (new_organic_check) => {
    let _organic_check = new_organic_check
    if (new_organic_check === 'true') {
        _organic_check = true
    } else if (new_organic_check === 'false') {
        _organic_check = false
    }

    set_organic_check(_organic_check)
}

const provide_delivery_changed = async (new_provide_delivery) => {
    let _provide_delivery = new_provide_delivery
    if (new_provide_delivery === 'true') {
        _provide_delivery = true
    } else if (new_provide_delivery === 'false') {
        _provide_delivery = false
    }

    set_provide_delivery(_provide_delivery)
}



return (
    <>
        <section id="contact" class="contact">
            <div class="container" data-aos="fade-up">
                <div class="section-title">
                    <h2><span class="green-text">Farmer Verification </span> <span class="orange-text">Details</span></h2>
                </div>


                {!page_loaded ? ( // so that only products will be loading at this time 
                    <div>Loading ... </div>
                ) : (
                    <>
                        <div class="row">
                            <div class="col-12">
                                {error_message !== '' ? (
                                    <div class="alert alert-danger" role="alert">
                                        {error_message}
                                    </div>
                                ) : ('')}
                                {success_message !== '' ? (
                                    <div class="alert alert-success" role="alert">
                                        {success_message}
                                    </div>
                                ) : ('')}
                            </div>
                        </div>

                        <div class="row">

                            <div class="col-md-12">
                                <hr />
                                <div class="table-responsive">
                                    <table class="table table-bordered table-striped">
                                        <tr>
                                            <th>Farm name</th>
                                            <td>
                                                <Link to={`/seller/${seller_id}`}>{farmer_name}</Link>
                                                
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>Visit date</th>
                                            <td>{visit_date.slice(0,10)}</td>
                                        </tr>

                                        <tr>
                                            <th>Quality of produce</th>
                                            <td>{quality_of_produce}</td>
                                        </tr>

                                        <tr>
                                            <th>Hygienic handling and processing</th>
                                            <td>{hygienic_handling_and_processing}</td>
                                        </tr>

                                        <tr>
                                            <th>Water for washing available</th>
                                            <td>{water_for_washing_available}</td>
                                        </tr>

                                        <tr>
                                            <th>Farm/field size</th>
                                            <td>{farm_size}</td>
                                        </tr>

                                        <tr>
                                            <th>Estimated capacity</th>
                                            <td>{estimated_capacity}</td>
                                        </tr>

                                        <tr>
                                            <th>Delivery available</th>
                                            <td>{delivery_available}</td>
                                        </tr>

                                        <tr>
                                            <th>Organic</th>
                                            <td>{organic}</td>
                                        </tr>

                                        <tr>
                                            <th>Evidence of chemicals/fertilisers</th>
                                            <td>{evidence_of_chemicals_on_plant}</td>
                                        </tr>

                                        <tr>
                                            <th>Have organic certificate</th>
                                            <td>{have_organic_certificate}</td>
                                        </tr>

                                        <tr>
                                            <th>Photo of organic certificate</th>
                                            <td>
                                                {photo_of_organic_certificate !== undefined ?(<a href={`${API_URL}/${photo_of_organic_certificate}`} target="_blank">View</a>):('')}
                                                
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>Verified crops</th>
                                            <td>{verified_crops}</td>
                                        </tr>

                                        <tr>
                                            <th>Photo of the produce</th>
                                            <td>
                                                <a href={`${API_URL}/${photo_of_produce}`} target="_blank">View</a>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>Photo of field with crops</th>
                                            <td>
                                                <a href={`${API_URL}/${photo_of_field_with_crops}`} target="_blank">View</a>
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                                { status == 'Paid' ? (
                                    <>
                                    <h3 class="mt-5">Payment details</h3>
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <tr>
                                                <th>Reference number</th>
                                                <td>{reference_number}</td>
                                            </tr>

                                            <tr>
                                                <th>Proof of payment</th>
                                                <td><a href={`${API_URL}/${proof_of_payment}`} target="_blank">View</a></td>
                                            </tr>
                                        </table>
                                        </div>
                                        </>
                                ):(<></>)}
                                {/* <form onSubmit={update_verification_details}>
                                        <div class="form-group col-12 my-3">
                                        <br />
                                        <button
                                            class="btn btn-lg btn-success"
                                            type="submit"
                                            disabled={action_loading}
                                        >
                                            <i class="glyphicon glyphicon-ok-sign"></i> Save
                                        </button>
                                        </div>
                                </form> */}

                                {/* <hr /> */}
                            </div>
                        </div>
                    </>
                )}

            </div>
        </section>
    </>
);
}
export default SellerVerificationDetailsScreen;
