// export const API_URL = 'https://peleka-market-dev-api.herokuapp.com';
export const API_URL = process.env.REACT_APP_API_URL
export const API_KEY = process.env.REACT_APP_API_KEY
export const APP_URL = process.env.REACT_APP_PUBLIC_URL
export const token_string = localStorage.getItem('token');
export const buyer_city = localStorage.getItem('buyer_city');

export const get_date = (firestore_timestamp) => new Date(firestore_timestamp.seconds * 1000);

export const get_month = (month_number) => {
    switch (month_number) {
    case 1:
      return 'January';
    case 2:
      return 'February';
    case 3:
      return 'March';
    case 4:
      return 'April';
    case 5:
      return 'May';
    case 6:
      return 'June';
    case 7:
      return 'July';
    case 8:
      return 'August';
    case 9:
      return 'September';
    case 10:
      return 'October';
    case 11:
      return 'November';
    case 12:
      return 'December';
    default:
      break;
    }
  };

export const get_date_string = (firestore_timestamp) => {
    // console.log('in');
    let created_date;
    created_date = firestore_timestamp;
    // console.log('in', created_date);
  
    if (firestore_timestamp.seconds) {
      created_date = get_date(firestore_timestamp);
    }
  
    const day = created_date.getDate() < 10 ? `0${created_date.getDate()}` : created_date.getDate();
    // const seconds = created_date.getSeconds() < 10 ? `0${created_date.getSeconds()}` : created_date.getSeconds();
    const minutes = created_date.getMinutes() < 10 ? `0${created_date.getMinutes()}` : created_date.getMinutes();
    const hours = created_date.getHours() < 10 ? `0${created_date.getHours()}` : created_date.getHours();
  
    const created_date_string = `${day} ${get_month(created_date.getMonth() + 1)} ${created_date.getFullYear()} at ${hours}:${minutes}`;
  
    return created_date_string;
  };

  export const get_display_date = (mongo_date_string) => {
    
    // let firestore_timestamp = ;
    let firestore_timestamp = new Date(new Date(mongo_date_string));

    

    const created_date = firestore_timestamp;

    const now = new Date();
  
    const created_date_utc = Date.UTC(
      created_date.getFullYear(),
      created_date.getMonth(),
      created_date.getDate(),
      created_date.getHours(),
      created_date.getMinutes(),
      created_date.getSeconds(),
      created_date.getMinutes(),
    );
    const now_utc = Date.UTC(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      now.getMinutes(),
      now.getSeconds(),
      now.getMilliseconds(),
    );
  
    const seconds_ago = Math.floor((now_utc - created_date_utc) / (1000));
  
    if (seconds_ago < 60) {
      return `${seconds_ago} ${seconds_ago === 1 ? 'second' : 'seconds'} ago`;
    }
  
    const minutes_ago = Math.floor((now_utc - created_date_utc) / (1000 * 60));
  
    if (minutes_ago < 60) {
      return `${minutes_ago} ${minutes_ago === 1 ? 'min' : 'mins'} ago`;
    }
  
    const hours_ago = Math.floor((now_utc - created_date_utc) / (1000 * 60 * 60));
  
    if (hours_ago < 24) {
      return `${hours_ago} ${hours_ago === 1 ? 'hour' : 'hours'} ago`;
    }
  
    // return 'nothing';
    return get_date_string(firestore_timestamp);
  };

  export const PELEKA_DELIVERY_CITIES = [
    'Gweru', 'Gwelo', 'gweru', 'gwelo', 'GWERU', 'GWELO'
  ];

  export const FFD_STAFF_ROLES = [
    { role: 'admin',  display_name: 'System administrator' },
    { role: 'technical-support',  display_name: 'Technical support' },
    { role: 'sales-support',  display_name: 'Sales support' },
    { role: 'field-support',  display_name: 'Field support' },
    { role: 'delivery-support',  display_name: 'Delivery support' },
    { role: 'verifier',  display_name: 'Verifier' },
  ]

  export const FFD_CUSTOMER_ROLES = [
    { role: 'seller',  display_name: 'Seller' },
    { role: 'farmer',  display_name: 'Farmer' },
    { role: 'aggregator',  display_name: 'Aggregator' },
    { role: 'verifier',  display_name: 'Verifier' },
    { role: 'observer',  display_name: 'Observer' },
  ]

  export const FFD_DEFAULT_COUNTRY = 'zimbabwe'
  export const FFD_COUNTRIES_METAS = ['zimbabwe', 'botswana']
  export const FFD_COUNTRIES = [
    {
      country: 'Botswana',
      meta: 'botswana',
    },
    {
      country: 'Zimbabwe',
      meta: 'zimbabwe',
    }, 
  ]

