import React, { useEffect, useState } from 'react';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { Link } from 'react-router-dom';


function AboutScreen(props) {

  const [action_loading, set_action_loading] = useState(false);

  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');
  
  // eslint-disable-next-line
  useEffect(() => {
      if (action_loading === false) {
        set_action_loading(true);
        loadPage();
      }        
  });

  /**
   * Checks if a supplied token in link can reset password and is valid.
   */
  const loadPage = async () =>  {
    set_success_message('');
    set_error_message(''); 
  }

  return <section id="about" class="about main-content login-content">
    <div class="container-fluid" data-aos="fade-up">
      <div class="row mt-3">
        {/* <div class="col-md-4"></div> */}
        <div class="col-md-12 text-center">
          <h3 class="green-text">About Farm-Fresh Delights</h3>
          {error_message !== '' ? (
          <div class="col-12">
            <div class="alert alert-danger" role="alert">
              {error_message}
            </div>
          </div>): ('')}

          {success_message !== '' ? (
          <div class="col-12">
            <div class="alert alert-success" role="alert">
              {success_message}
            </div>
          </div>): ('')}
        </div>


        <div class="col-md-12 mt-5 how-to-steps">
            <div class="container px-4 py-5" id="featured-3">
              <p class="lead about-text text-justify">
              Farm-Fresh Delights is an online marketplace for farm produce that brings together producers of quality farm produce with buyers who are looking for that produce.
              </p>

              <p class="lead about-text text-justify">
              Buyers interested in good quality farm produce can visit farmfreshdelights.com to find the produce they are interested in. You can search the site for produce in a locality you specify, or nationwide if that is your preference. Search results will show sellers who have what in stock what you are looking for in stock. Buyers can then contacts the seller directly and arrange a sale.
              </p>

              <p class="lead about-text">
              Farmers and other traders can register online at farmfreshdelights.com to open an "online farmgate" shop, and they stock it with the produce they are currently selling, and their location. 
              </p>

              <p class="lead about-text">
              We list all different types of farm produce, including specialty goods such as traditional foods. 
              </p>

              <p class="lead about-text">
              Our mission is to help farmers expand their market reach, avoid food loss and make their quality produce available to more people, while at the same time helping buyers find the quality produce they seek with convenience. 
              </p>

              <p class="lead about-text">
              We are continually innovating, listening and adapting to customer demands in order to provide better services to our clients, partners and friends. Please reach out and leave a note on our contact page if you have any suggestions for us. 
              </p>

              <p class="lead about-text">
              We are working closely with our sister company AgVal Networks, whose mission is to bring value to the entire Agricultural Value Network, from improved production systems to improved market access, and everything in between.
              </p>

              <p class="lead about-text">
              We are currently serving Zimbabwe, but our goal is to serve the entire African market, and we will be continually expanding our footprint to reach those. If you are outside our current coverage, stay tuned, farmfreshdelights.com may soon be providing services to a farmer or a buyer near you!
              </p>
                
            </div>
        </div>
    </div>
    </div>
  </section> 
}
export default AboutScreen;