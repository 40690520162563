import React, { useEffect, useState } from 'react';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { getOrders } from '../actions/orderActions';
import { Link } from 'react-router-dom';


function OrderScreen(props) {
  const order_id = props.match.params.order_id ? props.match.params.order_id : '';
  const status = props.match.params.status ? props.match.params.status : '';
  let payment_failed_reason = props.match.params.payment_failed_reason ? props.match.params.payment_failed_reason : '';

  if (payment_failed_reason === 'insufficient-funds') payment_failed_reason = 'Insufficient funds.';
  else if (payment_failed_reason === 'failed') payment_failed_reason = 'Transaction failed.';
  else if (payment_failed_reason === 'cancelled') payment_failed_reason = 'Transaction cancelled by customer';
  
  // Other PayPal states 
  // created. The trasaction was successfully created but is pending approval
  // completed. 
  // pending. Transaction still pending approval 
  else payment_failed_reason = ''

  const [action_loading, set_action_loading] = useState(false);

  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');

  const [tracking_number, set_tracking_number] = useState('');
  
  // eslint-disable-next-line
  useEffect(() => {
      if (action_loading === false) {
        set_action_loading(true);
        loadPage();
      }        
  });

  /**
   * Checks if a supplied token in link can reset password and is valid.
   */
  const loadPage = async () =>  {
    set_success_message('');
    set_error_message(''); 
    set_action_loading(true);

    let get_all_orders;

    try {
       get_all_orders = await getOrders(order_id, 'role');
       if (get_all_orders.success === false) {
         set_error_message(get_all_orders.message);
       }
       else {
         const { data } = get_all_orders;

         set_tracking_number(data[0].tracking_number);
        //  set_order_data(data[0]);
       }
     } catch (error) {
       set_error_message(`An error occured fetching order details: ${error.message} `);
     }

     set_action_loading(false);
  }

  return (
    status === 'success' ? (
      <section id="about" class="about main-content mt-5 login-content">
        <div class="container-fluid" data-aos="fade-up">
          <div class="row mt-5">
            {/* <div class="col-md-4"></div> */}
            <div class="col-md-12 text-center mb-5">
              <h3>Purchase successfully completed</h3>

              {error_message !== '' ? (
              <div class="col-12">
                <div class="alert alert-danger" role="alert">
                  {error_message}
                </div>
              </div>): ('')}

              {success_message !== '' ? (
              <div class="col-12">
                <div class="alert alert-success" role="alert">
                  {success_message}
                </div>
              </div>): ('')}


              <img src="/assets/img/payment-success.png" alt="success" />
              
            </div>

            <div class="col-12 mt-5">
                <p class="my-5">Thank you for purchasing from Peleka Marketplace</p>

                <p  class="my-5">We are going to pickup your order now, and we will deliver it to your specified delivery address based on your selected preferences.</p>

              <p  class="my-5">Your order tracking number is <span class="orange-text">{tracking_number}</span></p>

                <p  class="my-5">Please follow <Link to={`/track/${tracking_number}`}>this link</Link> to track the status of your order.</p>

                <Link to="/shop">
                <button class="btn btn-primary">Return to shopping</button>
              </Link>
                </div>

              
            
        </div>
        </div>
      </section>
    ):(
      <section id="about" class="about main-content mt-5 login-content">
      <div class="container-fluid" data-aos="fade-up">
        <div class="row mt-5">
          {/* <div class="col-md-4"></div> */}
          <div class="col-md-12 text-center mb-5">
            <h3>Order payment unsuccessful</h3>

            {error_message !== '' ? (
            <div class="col-12">
              <div class="alert alert-danger" role="alert">
                {error_message}
              </div>
            </div>): ('')}

            {success_message !== '' ? (
            <div class="col-12">
              <div class="alert alert-success" role="alert">
                {success_message}
              </div>
            </div>): ('')}


            <img src="/assets/img/payment-failed.png" alt="failure" />
            
          </div>

          <div class="col-12 mt-5">
              <p class="my-5">Unfortunately the payment for your order was unsuccessful. 
              
              { payment_failed_reason !== '' ? (<>Your financing institution issued the following reason:<br/>
            {payment_failed_reason}</>):('') } </p>

              <p  class="my-5">If you made an error and entered incorrect payment details, please <Link to={`/pay/${order_id}`}>retry payment</Link> using the correct details.
              <br/><br/>
              <Link to={`/pay/${order_id}`}>
                <button class="btn btn-primary">Retry payment</button>
              </Link>
              </p>

            <p  class="my-5">Alternatively, you can try payment using a different payment method. Please <Link to="/contact/payment-options">contact us</Link> for additional payment options.</p>

              <p  class="my-5">If you think this was an error please <Link to={`/contact/order/${order_id}`}>contact our sales support team</Link></p>
              </div>

            
          
      </div>
      </div>
    </section>
    )  
  )}
export default OrderScreen;