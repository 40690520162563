import React, { useState, useEffect } from 'react';
import { validate_role } from '../validations';
import { getUser } from '../actions/userActions';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';

function SellerPerfomanceSummaryScreen(props) {


  const status = props.match.params.status ? props.match.params.status : 'past-30-days';

  let user_profile;
  const permitted_role = 'seller';
  const check_permissions = validate_role(permitted_role);
  if (check_permissions.permitted === false) {
    let login_link = 'login';
    if (permitted_role === 'seller') login_link = 'access-forbidden'
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  const [error_message, set_error_message] = useState('');
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState('');
  const [page_loaded, set_page_loaded] = useState(false);

  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);
  
  // eslint-disable-next-line
  const [user_profile_in_db, set_user_profile_in_db] = useState('');

  const [overall_perfomance_rating_percentage, set_overall_perfomance_rating_percentage] = useState('');
  const [overall_perfomance_rating_stars, set_overall_perfomance_rating_stars] = useState('');

  // eslint-disable-next-line 
  const [all_orders_count, set_all_orders_count] = useState('');
  // eslint-disable-next-line 
  const [all_orders_percentage, set_all_orders_percentage] = useState('');
  // eslint-disable-next-line 
  const [all_orders_rating, set_all_orders_rating] = useState('');

  const [accepted_orders_count, set_accepted_orders_count] = useState('');
  const [accepted_orders_percentage, set_accepted_orders_percentage] = useState('');
  const [accepted_orders_rating, set_accepted_orders_rating] = useState('');

  const [orders_completed_on_time_count, set_orders_completed_on_time_count] = useState('');
  const [orders_completed_on_time_percentage, set_orders_completed_on_time_percentage] = useState('');
  const [orders_completed_on_time_rating, set_orders_completed_on_time_rating] = useState('');

  const [accepted_orders_fulfilled_count, set_accepted_orders_fulfilled_count] = useState('');
  const [accepted_orders_fulfilled_percentage, set_accepted_orders_fulfilled_percentage] = useState('');
  const [accepted_orders_fulfilled_rating, set_accepted_orders_fulfilled_rating] = useState(''); 
  
  const [recommendations, set_recommendations] = useState(''); 

  const [past30_days_displayed, set_past30_days_displayed] = useState(false);
  const [past3_months_displayed, set_past3_months_displayed] = useState(false);
  const [past12_months_displayed, set_past12_months_displayed] = useState(false);

  const display_stars = (stars) => {
    switch (parseFloat(stars)) {
    case 0:
      return <>
        <img src="/assets/img/star-empty.png" alt="" class="img-fluid" />
        <img src="/assets/img/star-empty.png" alt="" class="img-fluid" />
        <img src="/assets/img/star-empty.png" alt="" class="img-fluid" />
        <img src="/assets/img/star-empty.png" alt="" class="img-fluid" />
        <img src="/assets/img/star-empty.png" alt="" class="img-fluid" />
        </>    
    case 0.5:
      return <>
      <img src="/assets/img/star-half.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid" /></>
    case 1:
      return <>
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid" /></>
    case 1.5:
      return <>
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-half.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid" /></>
    case 2:
      return <>
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid" /></>
    case 2.5:
      return <>
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-half.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid" /></>
    case 3:
      return <>
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid" /></>
    case 3.5:
      return <>
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-half.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid" /></>
    case 4:
      return <>
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid" /></>
    case 4.5:
      return <>
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-half.png" alt="" class="img-fluid" /></>
    case 5:
      return <>
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid" /></>
    default:
      break;
    }
  };

  const display_overall_stars = (stars) => {
    switch (parseFloat(stars)) {
    case 0:
      return <>
        <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/>
        <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/>
        <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/>
        <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/>
        <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/>
        </>    
    case 0.5:
      return <>
      <img src="/assets/img/star-half.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/>
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/>
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/>
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/>
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/></>
    case 1:
      return <>
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/>
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/>
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/>
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/>
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/></>
    case 1.5:
      return <>
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/>
      <img src="/assets/img/star-half.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/>
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/>
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/>
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/></>
    case 2:
      return <>
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/>
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/>
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/>
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/>
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars"  style = {{width: '40px', height: '40px'}}/></>
    case 2.5:
      return <>
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} />
      <img src="/assets/img/star-half.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} /></>
    case 3:
      return <>
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} /></>
    case 3.5:
      return <>
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} />
      <img src="/assets/img/star-half.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} /></>
    case 4:
      return <>
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} />
      <img src="/assets/img/star-empty.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} /></>
    case 4.5:
      return <>
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} />
      <img src="/assets/img/star-half.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} /></>
    case 5:
      return <>
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} />
      <img src="/assets/img/star-full.png" alt="" class="img-fluid bigger-stars" style = {{width: '40px', height: '40px'}} /></>
    default:
      break;
    }
  };
    

  const load_page_data = async () => { 
    if (status === 'past-30-days') {
      set_past30_days_displayed(true);
      set_past3_months_displayed(false);
      set_past12_months_displayed(false);
    } else if (status === 'past-3-months') {
      set_past30_days_displayed(false);
      set_past3_months_displayed(true);
      set_past12_months_displayed(false);
    } else if (status === 'past-12-months') {
      set_past30_days_displayed(false);
      set_past3_months_displayed(false);
      set_past12_months_displayed(true);
    } 

    let this_user_profile;
    try {
      this_user_profile = await getUser(user_profile._id);
      if (this_user_profile.success === false) {
        set_error_message(this_user_profile.message);
        // console.log('error', error_message);
      } else {
        const { rating } = this_user_profile.data[0];
        // console.log(rating);
        const { all_orders, accepted_orders, orders_completed_on_time, accepted_orders_fulfilled } = rating;

        const _accepted_orders_percentage =  Math.round(((accepted_orders/all_orders) * 100));
        const _accepted_orders_rating = Math.round((_accepted_orders_percentage/100) * 5);

        

        set_all_orders_count(all_orders);
        set_accepted_orders_count(accepted_orders);
        set_orders_completed_on_time_count(orders_completed_on_time);
        set_accepted_orders_fulfilled_count(accepted_orders_fulfilled);

        set_accepted_orders_rating(_accepted_orders_rating);
        set_accepted_orders_percentage(_accepted_orders_percentage);

        const _orders_completed_on_time_percentage = Math.round(((orders_completed_on_time/accepted_orders) * 100));
        const _orders_completed_on_time_rating = Math.round((_orders_completed_on_time_percentage/100) * 5);
        set_orders_completed_on_time_rating(_orders_completed_on_time_rating);
        set_orders_completed_on_time_percentage(_orders_completed_on_time_percentage);

        const _accepted_orders_fulfilled_percentage = Math.round(((accepted_orders_fulfilled/accepted_orders) * 100));
        const _accepted_orders_fulfilled_rating = Math.round((_accepted_orders_fulfilled_percentage/100) * 5);
        set_accepted_orders_fulfilled_rating(_accepted_orders_fulfilled_rating);
        set_accepted_orders_fulfilled_percentage(_accepted_orders_fulfilled_percentage);

        let _recomendations = [];
        if (accepted_orders_percentage < 70 ) {
          _recomendations.push(<li>Stay online or reachable by phone to gain better quick access to Order invitations</li>);
        }

        if (_accepted_orders_fulfilled_percentage < 70 ) {
          _recomendations.push(<li>Try to fulfill all orders. If you don't have order items, don't accept the order.</li>);
        }

        if (_orders_completed_on_time_percentage < 70 ) {
          _recomendations.push(<li>Try to complete orders in time</li>);
        }
        
      
      set_recommendations(_recomendations);

      let _overall_perfomance_rating_stars = Math.round((_accepted_orders_rating + _orders_completed_on_time_rating + _accepted_orders_fulfilled_rating) / 3);
      let _overall_perfomance_rating_percentage  = (_overall_perfomance_rating_stars / 5 ) * 100;

      set_overall_perfomance_rating_percentage(`${_overall_perfomance_rating_percentage}%`);
      set_overall_perfomance_rating_stars(_overall_perfomance_rating_stars);

        set_page_loaded(true);
      }

      
    } catch (error) {
      // set_error_message('An error occured fetching seller profile');
    }
    // load data
    return;
  }


  const change_shown_status = (new_status) => { 
    props.history.push(`/seller-perfomance-summary/${new_status}`);
    if (new_status === 'past-30-days') {
      set_past30_days_displayed(true);
      set_past3_months_displayed(false);
      set_past12_months_displayed(false);
    } else if (new_status === 'past-3-months') {
      set_past30_days_displayed(false);
      set_past3_months_displayed(true);
      set_past12_months_displayed(false);
    } else if (new_status === 'past-12-months') {
      set_past30_days_displayed(false);
      set_past3_months_displayed(false);
      set_past12_months_displayed(true);
    } 
    // return;
  }

  useEffect(() => {
    if (page_loaded === false ) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  

  return (
    <>
    <section id="contact" class="contact" style={{backgroundColor: '#f8f8f8'}}>
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2><span class="black-text">Perfomance</span> <span class="green-text"></span></h2>
        </div>       


        {!page_loaded  ? ( // so that only products will be loading at this time 
          <div>Loading ... </div>
        ) : (
          <>
            <div class="row">
              <div class="col-12">
                  {error_message !== '' ? (
                      <div class="alert alert-danger" role="alert">
                        {error_message}
                      </div>
                    ): ('')}
                    {success_message !== '' ? (
                      <div class="alert alert-success" role="alert">
                          {success_message}
                      </div>
                    ): ('')}     
                </div>
            </div>

            { all_orders_count > 1 ? (
              <div class="row">
              <div class="col-md-8">
              <div class="section-title">
                <h2>
                  <span class="black-text">Overall Perfomance Rating</span> 
                  <span class="green-text mx-5">{overall_perfomance_rating_percentage}</span> 
                  <span class="yellow-text mx-5">{display_overall_stars(overall_perfomance_rating_stars)}</span>
                </h2>
              </div>
                <div class="table-responsive mt-5">
                  <table class="table">
                  <thead>
                      <tr>
                        <th>Order Processing Perfomance</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>Order invitations accepted</td>
                  <td><b>{accepted_orders_percentage}%</b> ({accepted_orders_count})</td>
                        <td>
                          { display_stars(accepted_orders_rating) }
                        </td>
                      </tr>

                      <tr>
                        <td>Timeliness of order completions</td>
                  <td><b>{orders_completed_on_time_percentage}%</b> ({orders_completed_on_time_count})</td>
                        <td>
                          { display_stars(orders_completed_on_time_rating) }
                        </td>
                      </tr>

                      <tr>
                        <td>Accepted orders fulfilled</td>
                  <td><b>{accepted_orders_fulfilled_percentage}%</b> ({accepted_orders_fulfilled_count})</td>
                        <td>{ display_stars(accepted_orders_fulfilled_rating) }</td>
                      </tr>
                    </tbody>  
                  </table>
                </div>

                {/* <div class="table-responsive mt-5">
                  <table class="table">
                  <thead>
                      <tr>
                        <th>Customer ratings on produce quality</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>Freshness</td>
                        <td><b>80%</b> (16)</td>
                        <td>
                          { display_stars(1.5) }
                        </td>
                      </tr>

                      <tr>
                        <td>Taste</td>
                        <td><b>80%</b> (16)</td>
                        <td>
                          { display_stars(2) }
                        </td>
                      </tr>

                      <tr>
                        <td>Damaged</td>
                        <td><b>80%</b> (16)</td>
                        <td>{ display_stars(2.5) }</td>
                      </tr>

                      <tr>
                        <td>Unclean</td>
                        <td><b>80%</b> (16)</td>
                        <td>{ display_stars(3) }</td>
                      </tr>
                    </tbody>  
                  </table>
                </div> */}

                {/* <div class="table-responsive mt-5">
                  <table class="table">
                  <thead>
                      <tr>
                        <th>Customer actions</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>Number of complaints on produce quality</td>
                        <td><b>3</b></td>
                      </tr>

                      <tr>
                        <td>Number of returns</td>
                        <td><b>0</b></td>
                      </tr>

                      <tr>
                        <td>Number of buy-again requests</td>
                        <td><b>2</b></td>
                      </tr>
                    </tbody>  
                  </table>
                </div> */}

                { recommendations !== '' ? (
                  <>
                  <h2 class="mt-5">Recommendations</h2>
                <p>Based on your performance, the following recommendations have been automatically generated:
                  <ol>
                    {recommendations}
                  </ol>
                </p>
                  </>
                ):('')}
                
              </div>
              <div class="col-md-4">
                <div class="text-center">
                  <button class="btn btn-primary mb-5" disabled={past30_days_displayed} onClick={() => change_shown_status('past-30-days')}>
                    Past 30 days
                  </button>
                  <br/>
                  <button class="btn btn-primary mb-5" disabled={past3_months_displayed} onClick={() => change_shown_status('past-3-months')}>
                    Past 3 months
                  </button>
                  <br/>
                  <button class="btn btn-primary mb-5"  disabled={past12_months_displayed} onClick={() => change_shown_status('past-12-months')}>
                    Past 12 months
                  </button>
                      
                </div>
              </div>
          </div>
            ):(
              <div class="row">
                <div class="col-md-8">
                  <p>You do not have any ratings made on your account yet</p>
                </div>
              </div>
            )}
          
          
            </>
        ) }
        
      </div>
    </section>
    </>
  );
}
export default SellerPerfomanceSummaryScreen;
