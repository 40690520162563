import React, { useState, useEffect } from 'react';
import { getOrdersV2 } from '../actions/orderActions';
import { getSellers, getBuyers } from '../actions/userActions';

import { validate_multiple_roles } from '../validations';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { Link } from 'react-router-dom';


function AdminDashboardScreen(props) {

  // eslint-disable-next-line
  let user_profile;
  const permitted_roles = ['admin', 'sales-support'];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = 'access-forbidden';
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  // eslint-disable-next-line
  const status = props.match.params.status ? props.match.params.status : 'Pending';

  const [error_message, set_error_message] = useState('');
  // eslint-disable-next-line 
  const [success_message, set_success_message] = useState('');

  const [page_loaded, set_page_loaded] = useState(false);
  // eslint-disable-next-line 
  const [action_loading, set_action_loading] = useState(false);


  const [pending_orders, set_pending_orders] = useState([]);

  const [paid_orders, set_paid_orders] = useState([]);

  const [buyers_count, set_buyers_count] = useState(0);
  const [sellers_count, set_sellers_count] = useState(0);
  const [orders_count, set_orders_count] = useState(0);

  // eslint-disable-next-line 
  const [id, set_id] = useState('id');
  // eslint-disable-next-line 
  const [phone, set_phone] = useState('phone');
  // eslint-disable-next-line 
  const [email, set_email] = useState('email');
  // eslint-disable-next-line 
  const [city, set_city] = useState('city');
  // eslint-disable-next-line 
  const [delivery_zone, set_delivery_zone] = useState('delivery_zone');
  // eslint-disable-next-line 
  const [payment_method, set_payment_method] = useState('payment_method');
  // eslint-disable-next-line 
  const [client_id, set_client_id] = useState('client_id');
  // eslint-disable-next-line 
  // const [status, set_status] = useState('status');


  // eslint-disable-next-line
  const [add_city_invalid, set_add_city_invalid] = useState(true);

  const link_cell_formatter = (cell, row) => {
    return (
      <div>
        <Link to={`${cell}/${row.age}`}>{cell}</Link>
      </div>);
  }

  const load_page_data = async () => {
    let get_retrieved_sellers;
    try {
      get_retrieved_sellers = await getSellers('id', 'seller', 'status');
      // console.log('ret prod', get_retrieved_sellers)
      if (get_retrieved_sellers.success === false) {
        // set_error_message(get_retrieved_sellers.message);
      }
      else {
        const { data } = get_retrieved_sellers;
        // console.log('retrieved sellers', data);
        let _sellers = 0

        for (let i = 0; i < data.length; i++) {
          _sellers++
        }

        set_sellers_count(_sellers)
      }
    } catch (error) {
      console.log('error', error.message)
      set_error_message(`An error occured fetching sellers: ${error.message} `);
    }

    let get_retrieved_buyers;
    try {
    //   console.log('in try')
      get_retrieved_buyers = await getBuyers('id', 'buyer', 'status');
      // console.log('ret prod', get_retrieved_buyers)
      if (get_retrieved_buyers.success === false) {
        set_error_message(get_retrieved_buyers.message);
      }
      else {
        const { data } = get_retrieved_buyers;
        let buyers_ = 0

        for (let i = 0; i < data.length; i++) {
          buyers_++
        }

        set_buyers_count(buyers_)
      }
    } catch (error) {
      console.log('error', error.message)
      set_error_message(`An error occured fetching buyers: ${error.message} `);
    }


    let get_orders;
    try {
      get_orders = await getOrdersV2('id', 'buyer', 'seller', 'status');
      console.log(get_orders)
      // console.log('get orders', get_orders);
      if (get_orders.success) {
        const { data } = get_orders;

        let orders_ = 0

        for (let i = 0; i < data.length; i++) {
          orders_++
        }

        set_orders_count(orders_)

      } else {
        set_error_message('No orders found');
      }
    } catch (error) {
      set_error_message(`An error occured fetching orders: ${error.message} `);
    }

    return;
  }

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);



  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">



          {!page_loaded ? ( // so that only products will be loading at this time 
            <div>Loading ... </div>
          ) : (
            <>
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-12">
                      {error_message !== '' ? (
                        <div class="alert alert-danger" role="alert">
                          {error_message}
                        </div>
                      ) : ('')}
                      {success_message !== '' ? (
                        <div class="alert alert-success" role="alert">
                          {success_message}
                        </div>
                      ) : ('')}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div class="row">
            <div class="col-md-3">
              <div class="info-box product-box dashboard-box">
                <Link to="seller-orders/active">
                  <div class="text-right mb-3 mr-3">
                    <span class="grey-text dashboard-box-text">
                      &nbsp;
                    </span>
                    <br />
                  </div>
                  <span class="grey-text dashboard-box-heading">Buyers</span>
                  <br />
                  <span class="black-text dashboard-box-count">{buyers_count}</span>
                  <br />
                  <span class="grey-text dashboard-box-text">&nbsp;</span>
                </Link>
              </div>
            </div>
            <div class="col-md-1"></div>

            <div class="col-md-3">
              <div class="info-box product-box dashboard-box">
                <Link to="seller-orders/active">
                  <div class="text-right mb-3 mr-3">
                    <span class="grey-text dashboard-box-text">
                      &nbsp;
                    </span>
                    <br />
                  </div>
                  <span class="grey-text dashboard-box-heading">Sellers</span>
                  <br />
                  <span class="black-text dashboard-box-count">{sellers_count}</span>
                  <br />
                  <span class="grey-text dashboard-box-text">&nbsp;</span>
                </Link>
              </div>
            </div>
            <div class="col-md-1"></div>

            <div class="col-md-3">
              <div class="info-box product-box dashboard-box">
                <Link to="seller-orders/active">
                  <div class="text-right mb-3 mr-3">
                    <span class="grey-text dashboard-box-text">
                      &nbsp;
                    </span>
                    <br />
                  </div>
                  <span class="grey-text dashboard-box-heading">Orders</span>
                  <br />
                  <span class="black-text dashboard-box-count">{orders_count}</span>
                  <br />
                  <span class="grey-text dashboard-box-text">&nbsp;</span>
                </Link>
              </div>
            </div>
            <div class="col-md-1"></div>


          </div>{/* row */}


          {/* <div class="row">
            <div class="col-12">
            <div class="section-title">
  <h2><span class="green-text">Pending</span> <span class="orange-text"> Orders</span></h2>
        </div>  

                <BootstrapTable
                                data={ pending_orders }
                                search
                                pagination>
                                    <TableHeaderColumn dataField='order_date'>Order date</TableHeaderColumn>
                                    <TableHeaderColumn dataField='tracking_number'>Tracking number</TableHeaderColumn>
                                    <TableHeaderColumn dataField='order_items' >Items count</TableHeaderColumn>
                                    <TableHeaderColumn dataField='order_value'>Order value</TableHeaderColumn>
                                    <TableHeaderColumn dataField='customer'>Customer</TableHeaderColumn>
                                    <TableHeaderColumn dataField='email' >Email</TableHeaderColumn>
                                    <TableHeaderColumn dataField='phone'>Phone</TableHeaderColumn>
                                    <TableHeaderColumn dataField='address'>Address</TableHeaderColumn>
                                    <TableHeaderColumn dataField='payment_method' >Payment method</TableHeaderColumn>
                                    <TableHeaderColumn dataField='action' dataFormat={link_cell_formatter} isKey={ true }></TableHeaderColumn>
                                </BootstrapTable>
            </div>

            <div class="col-12">
            <div class="section-title">
  <h2><span class="green-text">Paid</span> <span class="orange-text"> Orders</span></h2>
        </div>  

                <BootstrapTable
                                data={ paid_orders }
                                search
                                pagination>
                                    <TableHeaderColumn dataField='order_date'>Order date</TableHeaderColumn>
                                    <TableHeaderColumn dataField='tracking_number'>Tracking number</TableHeaderColumn>
                                    <TableHeaderColumn dataField='order_items' >Items count</TableHeaderColumn>
                                    <TableHeaderColumn dataField='order_value'>Order value</TableHeaderColumn>
                                    <TableHeaderColumn dataField='customer'>Customer</TableHeaderColumn>
                                    <TableHeaderColumn dataField='email' >Email</TableHeaderColumn>
                                    <TableHeaderColumn dataField='phone'>Phone</TableHeaderColumn>
                                    <TableHeaderColumn dataField='address'>Address</TableHeaderColumn>
                                    <TableHeaderColumn dataField='payment_method' >Payment method</TableHeaderColumn>
                                    <TableHeaderColumn dataField='action' dataFormat={link_cell_formatter} isKey={ true }></TableHeaderColumn>
                                </BootstrapTable>
            </div>
        </div> */}


        </div>

      </section>



    </>
  );
}
export default AdminDashboardScreen;
