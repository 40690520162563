import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { signin } from '../actions/userActions';
import queryString from 'query-string'



function LoginSellerScreen(props) {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const userSignin = useSelector(state => state.userSignin);
  const { loading, userInfo, error } = userSignin;
  const dispatch = useDispatch();
  // let redirect = props.location.search ? props.location.search.split("=")[1] : 'seller-dashboard';
  let redirect = props.location.search ? props.location.search.split("=")[1] : 'seller-dashboard';


  // console.log('redirect', redirect)

  useEffect(() => {
    if (userInfo) {
      props.history.push(redirect);
    }
    return () => {
      //
    };
    // eslint-disable-next-line
  }, [userInfo]);

  // console.log('props', props);

  const submitHandler = async (e) => {
    e.preventDefault();
    // console.log(userInfo);
    let notify_admin = false
    let notify_details = {}
    // check if has query strings 
    const query = await queryString.parse(props.location.search)

    // console.log(query)
    if (query.lf !== undefined) {
      notify_details.product = query.lf
      notify_admin = true
      // redirect = '/shop?st=7'
      redirect = '/my-requests'
    }
      
    if (query.q !== undefined) 
      notify_details.quantity = query.q
    if (query.u !== undefined) 
      notify_details.units = query.u

    // console.log(query.lf, query.q, query.u )
    // if (query.lf !== undefined && query.q !== undefined && query.u !== undefined) {
    //   redirect = `/?st=7&lf=${query.lf}&q=${query.q}&u=${query.u}`
    // }

    // console.log(redirect)
    
      // return;

   await dispatch(signin(email, password, notify_admin, notify_details, props, redirect));
    // console.log('after dispatch', userInfo);
  }
  return <section id="about" class="about main-content mt-5 login-content">
    <div class="container-fluid" data-aos="fade-up">
      <div class="row mt-5">
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center">
          <h3 class="green-text">SIGN IN</h3>
          {error && <div class="col-12">
            <div class="alert alert-danger" role="alert">
              {error}
            </div>
          </div> }
                            

          <form onSubmit={submitHandler} >
            <div class="form-group my-4">
              <input class="form-control" type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
            </div>
            <div class="form-group my-4">
              <input class="form-control" type="password" id="password" name="password" onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
            </div>
            <div class="form-group my-4 text-end">
               <Link to="forgot-password" className="green-text" >Forgot password ?</Link>
            </div>
            <div class="form-group my-4">
              <button type="submit" class="btn btn-primary form-control login-button" disabled={loading}>Sign in</button>              
            </div>
            <div class="form-group">
              <Link to={redirect === "/" ? "" : "register?search=" + redirect} className="green-text" >Not a member? Register</Link>
            </div>
          </form>
        </div>
        <div class="col-md-4"></div>
    </div>
    </div>
  </section> 
}
export default LoginSellerScreen;