import React, { useEffect, useState } from 'react';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';


function RefundPolicyScreen(props) {

  const [action_loading, set_action_loading] = useState(false);

  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');
  
  // eslint-disable-next-line
  useEffect(() => {
      if (action_loading === false) {
        set_action_loading(true);
        loadPage();
      }      
  });

  /**
   * Checks if a supplied token in link can reset password and is valid.
   */
  const loadPage = async () =>  {
    set_success_message('');
    set_error_message(''); 
  }

  return <section id="about" class="about main-content mt-5 login-content">
    <div class="container-fluid" data-aos="fade-up">
      <div class="row mt-5">
        {/* <div class="col-md-4"></div> */}
        <div class="col-md-12 text-center">
          <h3 class="green-text">Refund Policy</h3>
          {error_message !== '' ? (
          <div class="col-12">
            <div class="alert alert-danger" role="alert">
              {error_message}
            </div>
          </div>): ('')}

          {success_message !== '' ? (
          <div class="col-12">
            <div class="alert alert-success" role="alert">
              {success_message}
            </div>
          </div>): ('')}
        </div>
        <div class="col-md-12">
            <p>When buying from Peleka Marketplace there are two options that you can use. You can buy through Peleka Marketplace or you buy directly from the seller. 
                <br/>
                
                <br/>
            </p>

            <h3>Buying through Peleka Marketplace </h3>
            <p>Your payment shall be held by Peleka until you have received your goods, and you will receive your refund in the following manner: 

                <ul>
                    <li>If your item arrives damaged, doesn't match the listing description, or is the wrong item, you can return it for a refund</li>
                    <li>If you've changed your mind and want to return it, you can ask the seller if they'll accept a return. If they do accept a return you will be expected to pay fro both delivery pickup and delivery jobs that will occur.</li>
                </ul>
            </p>

            <h3>Buying directly from the seller </h3>
            <p>If you buy directy from the seller you should directly contact the seller for any refund that should occur and Peleka Marketplace shall not be involved in the matter.
                
            </p>
            

        </div>
           
        
    </div>
    </div>
  </section> 
}
export default RefundPolicyScreen;