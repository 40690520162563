import React, { useState, useEffect, useRef } from 'react';
import { validate_multiple_roles } from '../validations';
import { getUser, updateUserDetails } from '../actions/userActions';
import { getCities, getCountries, getSuburbs } from '../actions/categoryActions';
import { Textbox } from 'react-inputs-validation';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import axios from 'axios';
import { APP_URL, API_URL, API_KEY, token_string, get_display_date } from '../config'
import { Link } from 'react-router-dom';
import { getVerifications, updateVerification } from '../actions/verificationActions';

function PaySellerVerifierScreen(props) {
    let user_profile;
    const permitted_roles = ['technical-support', 'admin'];
    const check_permissions = validate_multiple_roles(permitted_roles);
    if (check_permissions.permitted === false) {
        const login_link = 'access-forbidden';
        props.history.push(login_link);
    } else {
        user_profile = check_permissions.user_object;

        // if (!(user_profile.roles).includes('seller')) {
        //   props.history.push('buyer-profile')
        // }
    }

    const id = props.match.params.id ? props.match.params.id : '';

    const [error_message, set_error_message] = useState('');
    const [success_message, set_success_message] = useState('');
    const [page_loaded, set_page_loaded] = useState(false);
    const [action_loading, set_action_loading] = useState(false);


    const [cities, set_cities] = useState([]);
    const [countries, set_countries] = useState([]);
    const [suburbs, set_suburbs] = useState([]);

    const [first_name, set_first_name] = useState('');
    const [username, set_username] = useState('');
    const [last_name, set_last_name] = useState('');
    const [trading_name, set_trading_name] = useState('');
    const [phone, set_phone] = useState('');
    const [email, set_email] = useState('');
    const [seller_type, set_seller_type] = useState('Farm');

    const [telephone, set_telephone] = useState('');
    const [display_name, set_display_name] = useState('');
    const [description, set_description] = useState('');
    const [address_line1, set_address_line1] = useState('');
    const [address_line2, set_address_line2] = useState('');
    const [suburb, set_suburb] = useState('');
    const [city, set_city] = useState('Select');
    const [province, set_province] = useState('');
    const [country, set_country] = useState('');
    const [country_meta, set_country_meta] = useState('');
    const [city_meta, set_city_meta] = useState('');
    // eslint-disable-next-line
    const [seller_image, set_seller_image] = useState('');

    const [provide_delivery, set_provide_delivery] = useState(false);
    const [delivery_charge_type, set_delivery_charge_type] = useState('');
    const [delivery_charge, set_delivery_charge] = useState('');
    const [delivery_purchase_minimum, set_delivery_purchase_minimum] = useState('');
    const [delivery_maximum_radius, set_delivery_maximum_radius] = useState('');

    const [organic_check, set_organic_check] = useState(false);
    const [organic_check_status, set_organic_check_status] = useState(false);
    const [admin_verified_status, set_admin_verified_status] = useState('');
    const [admin_verified_check, set_admin_verified_check] = useState(false);

    const [latitude, set_latitude] = useState('');
    const [longitude, set_longitude] = useState('');

    const [visit_date, set_visit_date] = useState('');
    const [farmer_name, set_farmer_name] = useState('');
    const [reference_number, set_reference_number] = useState('');
    

    const [proof_of_payment, set_proof_of_payment] = useState('');

    const [admin_verification, set_admin_verification] = useState({
        status: 'Not Verified',
        comments: '',
    });


    // eslint-disable-next-line
    const [first_name_invalid, set_first_name_invalid] = useState(true);
    // eslint-disable-next-line
    const [last_name_invalid, set_last_name_invalid] = useState(true);
    // eslint-disable-next-line
    const [trading_name_invalid, set_trading_name_invalid] = useState(true);
    // eslint-disable-next-line
    const [phone_invalid, set_phone_invalid] = useState(true);
    // eslint-disable-next-line
    const [email_invalid, set_email_invalid] = useState(true);
    // eslint-disable-next-line
    const [seller_type_invalid, set_seller_type_invalid] = useState(true);
    // eslint-disable-next-line
    const [telephone_invalid, set_telephone_invalid] = useState(true);
    // eslint-disable-next-line
    const [display_name_invalid, set_display_name_invalid] = useState(true);
    // eslint-disable-next-line
    const [description_invalid, set_description_invalid] = useState(true);
    // eslint-disable-next-line
    const [address_line1_invalid, set_address_line1_invalid] = useState(true);
    // eslint-disable-next-line
    const [address_line2_invalid, set_address_line2_invalid] = useState(true);
    // eslint-disable-next-line
    const [suburb_invalid, set_suburb_invalid] = useState(true);
    // eslint-disable-next-line
    const [city_invalid, set_city_invalid] = useState(true);
    // eslint-disable-next-line
    const [province_invalid, set_province_invalid] = useState(true);
    // eslint-disable-next-line
    const [country_invalid, set_country_invalid] = useState(false);
    // eslint-disable-next-line
    const [onsite_invalid, set_onsite_invalid] = useState(true);

    // eslint-disable-next-line
    const [user_profile_in_db, set_user_profile_in_db] = useState('');

    const [file, setFile] = useState(''); // storing the uploaded file
    // eslint-disable-next-line
    const [progress, setProgess] = useState(0); // progess bar
    const el = useRef(); // accesing input element   

    
    const new_proof_of_payment = (e) => { set_proof_of_payment(e.target.files[0]) }

    /**
     * 
     * @returns updates a client profile picture
     */
    // eslint-disable-next-line 
    const update_verification_details = async (e) => {
        e.preventDefault();
        set_success_message('');
        set_error_message('');

        set_action_loading(true);
        try {
            if (token_string === null) { token_string = localStorage.getItem('token') }

            const formData = new FormData();
            formData.append('api_key', API_KEY);
            formData.append('proof_of_payment', proof_of_payment)
            formData.append('reference_number', reference_number)
                
            formData.append('update_key', 'proof_of_payment');
            formData.append('id', id);
            // console.log('about to axio', token_string)
            const { data } = await axios.patch(`${API_URL}/verifications`,
                formData, {
                headers: {
                    Authorization: 'Bearer ' + token_string
                },
            })

            console.log('data', data)
            if (data.success === true) {
                set_success_message('Update successful');
                props.history.push(`/seller-verification-details/${id}`)
            } else if (data.success === false) {
                set_error_message(data.message);
            } 
        } catch (error) {
            console.log('error', error.message)
            set_error_message('An error occured updating verification details')
        }

//         , {
//             onUploadProgress: (ProgressEvent) => {
//                 let progress = Math.round(
//                     ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
//                 setProgess(progress);
//             }
// }).then(res => {
//     const { data } = res;
//     if (data.success === true) {
//         set_success_message('Update successful');
//         props.history.push(`seller-verification-details/${id}`)
//     } else if (data.success === false) {
//         set_error_message(data.message);
//     } else {
//         set_error_message(`An error occured updating profile picture`);
//     }
//     console.log(data, 'then')
// }).catch(
//     console.log('exc')
//     // set_error_message('An error occured updating profile picture')
// );

await load_page_data();

set_action_loading(false);
return;
    }




const load_page_data = async () => {

    let get_orders;
    try {
        get_orders = await getVerifications(id, user_profile._id, 'seller_id', 'Pending')

        // console.log(get_orders)

        if (get_orders.success) {
            let { data } = get_orders;

            set_farmer_name((data[0].seller_details.seller_details[0]).trading_name)
            set_visit_date(data[0].visit_date)



        } else {
            set_error_message('No pending verification requests found.');
        }
    } catch (error) {
        set_error_message(`No pending verification requests found`);
    }

    const geolocationAPI = navigator.geolocation;
    console.log('geo location api', geolocationAPI)
    if (!geolocationAPI) {
        console.error('Geolocation not found in browser')
    } else {
        await geolocationAPI.getCurrentPosition((position) => {
            console.log('position', position)
            const { coords } = position;
            set_latitude(coords.latitude)
            set_longitude(coords.longitude)
        }, (error) => {
            console.log('Error occured getting location.', error)
            // setError('Something went wrong getting your position!')
        })
    }

    return;
}

/**
 * Updates user profile
 * @param {*} e 
 */
const updateProfile = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    const update_data = {
        first_name,
        last_name,
        phone,
        email,
        telephone,
        display_name,
        organic: {
            check: organic_check,
        },
        delivery: {
            provides: provide_delivery,
            charge: delivery_charge,
            charge_type: delivery_charge_type,
            purchase_minimum: delivery_purchase_minimum,
            maximum_radius: delivery_maximum_radius
        },
        seller_details: [
            {
                seller_type,
                trading_name,
                description,
                address_line1,
                address_line2,
                suburb,
                city,
                province,
                country,
                latitude,
                longitude,
            }
        ],
    };

    const response = await updateUserDetails(update_data, 'basic_detail');
    if (response.success === false) {
        set_error_message(response.message);

    } else {
        set_success_message('Account details successfully updated.');
    }
    set_action_loading(false);

    return;
}


const requestVerification = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    const update_data = {};

    const response = await updateUserDetails(update_data, 'request_verification');
    if (response.success === false) {
        set_error_message(response.message);

    } else {
        await load_page_data()
        set_success_message('Verification request successfully sent.')

    }
    set_action_loading(false);

    return;
}

useEffect(() => {
    if (page_loaded === false) {
        load_page_data();
        set_page_loaded(true);
    }
    // eslint-disable-next-line
}, []);

const country_changed = async (new_country) => {
    set_country(new_country)
    set_city('')
    set_suburb('')

    for (let i = 0; i < countries.length; i++) {
        if (countries[i].country === new_country) {
            set_country_meta(countries[i].meta)
            return;
        }
    }
}

const city_changed = async (new_city) => {
    set_city(new_city)
    set_suburb('')

    for (let i = 0; i < cities.length; i++) {
        if (cities[i].city === new_city) {
            // console.log(cities[i].meta)
            set_city_meta(cities[i].meta)
            return;
        }
    }
}

const organic_check_changed = async (new_organic_check) => {
    let _organic_check = new_organic_check
    if (new_organic_check === 'true') {
        _organic_check = true
    } else if (new_organic_check === 'false') {
        _organic_check = false
    }

    set_organic_check(_organic_check)
}

const provide_delivery_changed = async (new_provide_delivery) => {
    let _provide_delivery = new_provide_delivery
    if (new_provide_delivery === 'true') {
        _provide_delivery = true
    } else if (new_provide_delivery === 'false') {
        _provide_delivery = false
    }

    set_provide_delivery(_provide_delivery)
}



return (
    <>
        <section id="contact" class="contact">
            <div class="container" data-aos="fade-up">
                <div class="section-title">
                    <h2><span class="green-text">Pay</span> <span class="orange-text">Seller Verifier</span></h2>

                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <tr>
                                <th>Farm name</th>
                                <th>{farmer_name}</th>
                            </tr>
                            <tr>
                                <th>Visit date</th>
                                <th>{get_display_date(visit_date)}</th>
                            </tr>
                        </table>
                    </div>
                </div>


                {!page_loaded ? ( // so that only products will be loading at this time 
                    <div>Loading ... </div>
                ) : (
                    <>
                        <div class="row">
                            <div class="col-12">
                                {error_message !== '' ? (
                                    <div class="alert alert-danger" role="alert">
                                        {error_message}
                                    </div>
                                ) : ('')}
                                {success_message !== '' ? (
                                    <div class="alert alert-success" role="alert">
                                        {success_message}
                                    </div>
                                ) : ('')}
                            </div>
                        </div>

                        <div class="row">

                            <div class="col-md-12">
                                <hr />

                                <form onSubmit={update_verification_details}>
                                    <div class="row">
                                        <div class="form-group col-md-12 my-3">
                                            <label for="first_name">Reference number</label>
                                            <input class="form-control" value={reference_number} onChange={(e) => set_reference_number(e.target.value)} />
                                        </div>

                                        <div class="form-group col-md-12 my-3">
                                            <label for="first_name">Proof of payment</label>
                                            <input class="text-center center-block file-upload form-control" type="file" ref={el} onChange={new_proof_of_payment} />
                                        </div>
                                   

                                    
                                        

                                    </div>

                                    <div class="form-group col-12 my-3">
                                        <br />
                                        <button
                                            class="btn btn-lg btn-success"
                                            type="submit"
                                            disabled={action_loading}
                                        // check invalids 
                                        >
                                            <i class="glyphicon glyphicon-ok-sign"></i> Save payment details
                                        </button>
                                        {/* <button class="btn btn-lg" type="reset"><i class="glyphicon glyphicon-repeat"></i> Reset</button> */}
                                    </div>
                                </form>

                                {/* <hr /> */}
                            </div>
                        </div>
                    </>
                )}

            </div>
        </section>
    </>
);
}
export default PaySellerVerifierScreen;
