import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { validate_multiple_roles } from '../validations';
import { getCategoriesV2 } from '../actions/categoryActions';

function CategoriesScreen(props) {
  // eslint-disable-next-line
  let user_profile;
  const permitted_roles = ['admin', 'technical-support'];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = 'access-forbidden';
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  // eslint-disable-next-line
  const [error_message, set_error_message] = useState('');
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState('');
  const [page_loaded, set_page_loaded] = useState(false);
  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);

  const [all_categories, set_all_categories] = useState([]);


  const productList = useSelector((state) => state.productList);
  const { loading, error } = productList;

  const load_page_data = async () => { 
    let get_all_categories;

     try {
       get_all_categories = await getCategoriesV2('id', 'meta', 'status', 'featured', 'limit', 'priority');
       if (get_all_categories.success === false) {
         set_error_message(get_all_categories.message);
       }
       else {
         const { data } = get_all_categories;
         set_all_categories(data);
       }
     } catch (error) {
       set_error_message(`An error occured fetching categories: ${error.message} `);
     }

   return;
 }

 // eslint-disable-next-line
  const dispatch = useDispatch();
  useEffect(() => {
    if (page_loaded === false ) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
    <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2><span class="green-text">Categories</span> <span class="orange-text"> </span></h2>
        </div>

        <div class="row">
          <div class="col-12 mb-5">
            <Link to="/add-category" class="btn btn-primary"><i class="bi bi-plus"> </i>Add category</Link>
          </div>
        </div>

        


        {loading ? ( // so that only products will be loading at this time 
          <div>Loading ... </div>
        ) : error ? (
        <div class="row">
          <div class="col-12">
            <div class="alert alert-danger" role="alert">
                No categories found. Please add one using the "Add category" button. 
            </div>
          </div>
        </div>
        ) : (
          <div class="row">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Priority</th>
                    <th>Category</th>
                    <th>Code</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                
                
                {all_categories.map((category) => (              
                    <tr key={category._id}>
                        <td>{category.priority}</td>
                        <td>{category.category}</td>
                        <td>{category.code}</td>
                        <td>{category.status}</td>
                        <td><Link to={`/category/${category._id}`}>View more</Link></td>
                    </tr>
                ))}
            </tbody>
            </table>
          </div>
        </div>
        )}
        
      </div>
    </section>
    </>
  );
}
export default CategoriesScreen;
