import jwt_decode from "jwt-decode";

export const validate_role = (expected_role) => {
	let permitted = false;
    const token_string = localStorage.getItem('token');
    try {
		var user_object = jwt_decode(token_string);
		const { roles } = user_object;
		for (let i = 0; i < roles.length; i++) {
			if (roles[i] === expected_role) {
				permitted = true;
				return {
					permitted,
					user_object,
				}
			}
		}
		return {
			permitted,
		};      
    } catch (error) {
      return {
		  permitted,
	  };
	}
}

export const validate_multiple_roles = (expected_roles) => {
	let permitted = false;
    const token_string = localStorage.getItem('token');
    try {
		var user_object = jwt_decode(token_string);
		const { roles } = user_object;
		for (let i = 0; i < roles.length; i++) {
			if (expected_roles.includes(roles[i])) {
				permitted = true;
				return {
					permitted,
					user_object,
				}
			}
		}
		return {
			permitted,
		};      
    } catch (error) {
      return {
		  permitted,
	  };
	}
}

export const validate_name = (name) => {
	return true;
};

