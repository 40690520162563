import Axios from "axios";
import Cookie from "js-cookie";
import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_SAVE_SHIPPING, CART_SAVE_PAYMENT } from "../constants/cartConstants";

import { API_URL, API_KEY } from "../config";

let token_string = localStorage.getItem('token');

const addToCart = (productId, quantity) => async (dispatch, getState) => {
  try {
    const { data } = await Axios.get(`${API_URL}/products?id=${productId}`);
    const product = data.data[0];
    dispatch({
      type: CART_ADD_ITEM, payload: {
        product: product._id,
        name: product.product_name,
        image: product.pictures[0],
        price: product.price,
        units: product.price_per,
        product_meta: product.product_meta,
        quantity_in_stock: product.quantity,
        quantity,
      }
    });
    const { cart: { cartItems } } = getState();
    Cookie.set("cartItems", JSON.stringify(cartItems));

    // storing for /shop
    // console.log('storing cart items', cartItems);
    localStorage.setItem('shopping_cart_items', JSON.stringify(cartItems));

  } catch (error) {
    // console.log(error.message);
  }
}

const updateQuantity = (productId, action) => async (dispatch, getState) => {
  const { cart: { cartItems } } = getState();
  let _new_cart_items = [];
  try {
    if (action === 'increment') {
      // console.log('have listing first', cartItems);
      for (let i = 0; i < cartItems.length; i++) {        
        if (cartItems[i].product === productId) {
          if (cartItems[i].quantity < cartItems[i].quantity_in_stock) {
            cartItems[i].quantity = cartItems[i].quantity + 1;
          }          
        }
        _new_cart_items.push(cartItems[i]);
      }      
    } else if (action === 'decrement') {
      for (let i = 0; i < cartItems.length; i++) {        
        if (cartItems[i].product === productId) {
          if (cartItems[i].quantity === 1) {  
            dispatch({ type: CART_REMOVE_ITEM, payload: productId });
            cartItems.splice(i, 1); 
          } else {
            cartItems[i].quantity = cartItems[i].quantity - 1;
            _new_cart_items.push(cartItems[i]);
          }     
        }
        
      }
    }
    Cookie.set("cartItems", JSON.stringify(_new_cart_items));

    // storing for /shop
    // console.log('storing cart items', cartItems);
    localStorage.setItem('shopping_cart_items', JSON.stringify(cartItems));
  } catch(error) {
    // amount at last
  }
}

const addToWishList = (productId, quantity) => async (dispatch, getState) => {
  // localStorage.removeItem('wish_list_items');
  // return;
  let _wish_list_items = [];
  try {
    if (!(localStorage.getItem('wish_list_items') === null || localStorage.getItem('wish_list_items') === '')) {
      console.log('if satisified', localStorage.getItem('wish_list_items'));
      _wish_list_items = JSON.parse(localStorage.getItem('wish_list_items'));
    }

    if (!(_wish_list_items.includes(productId))) {
      _wish_list_items.push(productId);
    }

    localStorage.setItem('wish_list_items', JSON.stringify(_wish_list_items));
  } catch (error) {
    console.log(error.message);
  }
}


const removeFromCart = (productId) => (dispatch, getState) => {
  dispatch({ type: CART_REMOVE_ITEM, payload: productId });

  const { cart: { cartItems } } = getState();
  Cookie.set("cartItems", JSON.stringify(cartItems));
}
const saveShipping = (data) => (dispatch) => {
  dispatch({ type: CART_SAVE_SHIPPING, payload: data });
}
const savePayment = (data) => (dispatch) => {
  dispatch({ type: CART_SAVE_PAYMENT, payload: data });
}

const getRates = async (base, foreign) => {
  try {
    const { data } = await Axios.get(
      `${API_URL}/rates?base=${base}&foreign=${foreign}`,
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Failed to retrieve rates. Please reload page',
    }
  }
}

/**
 * Updates forex rate for a currency
 * 
 * @param {*} base 
 * @param {*} foreign 
 * @param {*} buy 
 * @param {*} sell 
 */
const updateRate = async (base, foreign, buy, sell) => {
  

  for (let a in localStorage) {
    console.log(a, ' = ', localStorage[a]);
  }
  if (token_string === null) { token_string = localStorage.getItem('token') }

  try {
    const { data } = await Axios.post(
      `${API_URL}/rates`, 
      { 
        api_key: API_KEY, 
        base,
        foreign,
        buy,
        sell,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: `Sorry we could not update new rates. ${error.message}`,
    }
  }
}

export { addToCart, addToWishList, removeFromCart, saveShipping, savePayment, getRates, updateRate, updateQuantity }
