import React, { useState, useEffect } from 'react';
import { validate_role } from '../validations';
import { getOrdersV2, updateOrderItem, updateOrder} from '../actions/orderActions';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link } from 'react-router-dom'

function SellerOrderScreen(props) {

  const id = props.match.params.id ? props.match.params.id : '';

  let user_profile;
  const permitted_role = 'seller';
  const check_permissions = validate_role(permitted_role);
  if (check_permissions.permitted === false) {
    let login_link = 'login';
    if (permitted_role === 'seller') login_link = 'access-forbidden'
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  const [error_message, set_error_message] = useState('');
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState('');
  const [page_loaded, set_page_loaded] = useState(false);

  const [new_order_status, set_new_order_status] = useState('');

  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);

  // eslint-disable-next-line
  const [order_details, set_order_details] = useState({});
  const [product_details, set_product_details] = useState({});
  const [buyer_details, set_buyer_details] = useState({});

  const [acceptance_details, set_acceptance_details] = useState({});
  const [preparation_details, set_preparation_details] = useState({});
  const [ready_details, set_ready_details] = useState({});
  const [collected_details, set_collected_details] = useState({});
  const [paid_details, set_paid_details] = useState({});

  const [mark_order_ready, set_mark_order_ready] = React.useState(false);
  const mark_order_ready_open = () => {
    set_mark_order_ready(true);
  };

  const mark_order_ready_close = () => {
    set_mark_order_ready(false);
  };

  const [start_preparing_dialog, set_start_preparing_dialog] = React.useState(false);

  const start_preparing_dialog_open = () => {
    set_start_preparing_dialog(true);
  };

  const start_preparing_dialog_close = () => {
    set_start_preparing_dialog(false);
  };



  const load_page_data = async () => {



    set_action_loading(true);
    let get_orders;
    try {
      get_orders = await getOrdersV2(id, 'buyer', 'mine', 'status');
      console.log(get_orders.data[0])
      if (get_orders.success) {
        set_order_details(get_orders.data[0]);
        set_product_details(get_orders.data[0].product_details);
        set_buyer_details(get_orders.data[0].buyer_details);
      } else {
        set_error_message('Order details not found');
      }
    } catch (error) {
      set_error_message(`An error occured fetching order details: ${error.message} `);
    }

    // set_orders_to_display(_orders_to_display);

    set_action_loading(false);
    return;
  }

  /**
* Makes an action
* 
*/
  const markOrderReady = async () => {
    // e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    const update_data = {
      status: 'completed',
    }

    const response = await updateOrderItem(update_data, id, 'completed');
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      set_success_message('Order successfully marked as complete');
      load_page_data();
    }

    mark_order_ready_close();
    set_action_loading(false);

    return;
  }

  /**
  * Makes an action
  * 
  */
  const startPreparingOrder = async () => {
    // e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    const update_data = {
      status: 'preparing',
    }

    const response = await updateOrderItem(update_data, id, 'preparing');
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      set_success_message('Order in preparing stage now');
      load_page_data();
    }

    start_preparing_dialog_close();
    set_action_loading(false);

    return;
  }

  const update_order_status = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');

    let update_data = {
      status: new_order_status
    }
    let update_key = 'seller_status_change'

    if (new_order_status === '') {
      set_error_message('New order status cannot be null')
      return
    }

    set_action_loading(true)

    const response = await updateOrder(update_data, id, update_key)
    if (response.success === false) {
      set_error_message(response.message)
    } else {
      set_success_message('Order status successfully updated.')
      await load_page_data()
    }

    set_action_loading(false)

    return;
  }

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);



  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2><span class="black-text">Viewing Order</span> <br /> <span class="green-text">{order_details.quantity} {product_details.quantity_units} of {product_details.product_name}</span></h2>
          </div>


          {!page_loaded ? ( // so that only products will be loading at this time 
            <div>Loading ... </div>
          ) : (
            <>
              <div class="row">
                <div class="col-12">
                  {error_message !== '' ? (
                    <div class="alert alert-danger" role="alert">
                      {error_message}
                    </div>
                  ) : ('')}
                  {success_message !== '' ? (
                    <div class="alert alert-success" role="alert">
                      {success_message}
                    </div>
                  ) : ('')}
                </div>
              </div>

              <div class="row">

              </div>


              <div class="row">

                <div class="col-3"></div>
                <div class="col-6">
                  <div class="table-responsive mb-5">
                    <table class="table table-bordered">
                      <thead>

                      </thead>

                      <tbody>
                        <tr>
                          <th>Order Number</th>
                          <th>{order_details._id}</th>
                        </tr>


                        <tr>
                          <th>Product</th>
                          <th>{product_details.product_name}</th>
                        </tr>

                        <tr>
                          <th>Quantity Required</th>
                          <th>{order_details.quantity} {product_details.quantity_units}</th>
                        </tr>

                        <tr>
                          <th>Order Status</th>
                          <th>

                            <p>{order_details.status}</p>

                            <p>
                              <div class="form-group">
                                <select class="form-control" onChange={(e)=>set_new_order_status(e.target.value)}>
                                  <option value="">Select to update order status</option>
                                  {/* Pending, Confirmed, Declined, Processing, Awaiting collection, In transit, Collected/Delivered, Cancelled */}
                                  <option value="Processing">We are processing the order</option>
                                  <option value="Awaiting collection">Awaiting collection by customer</option>
                                  <option value="In transit">Order is in transit to buyer</option>
                                  <option value="Delivered">We have delivered order to buyer</option>
                                  <option value="Collected">Buyer has collected order</option>
                                  <option value="Declined">We have cancelled the order</option>
                                  <option value="Cancelled">Buyer has cancelled order</option>
                                </select>
                                

                              </div>

                              <div class="form-group mt-3">
                                <form onSubmit={update_order_status}>
                                  <button to={`/messages/${order_details._id}`} class="btn btn-primary btn-sm">
                                    Update order status
                                  </button>
                                </form>
                              </div>
                            </p>



                          </th>
                        </tr>


                        {/* <tr>
                          <th>Buyer Details</th>
                          <th>{buyer_details.display_name} <br /> {buyer_details.phone} <br /> {buyer_details.email}</th>
                        </tr> */}

                        {/* <tr>
                          <th>Next Activity</th>
                          <th>
                            {order_details.status === 'invited' ? ('Accept') : ('')}
                            {order_details.status === 'accepted' ? ('Prepare') : ('')}
                            {order_details.status === 'preparing' ? ('Complete') : ('')}
                            {order_details.status === 'completed' ? ('Delivery Agent Collects') : ('')}
                            {order_details.status === 'collected' ? ('Get Payout') : ('')}

                            {order_details.status === 'accepted' ? (
                              <>
                                <br />
                                <button onClick={() => start_preparing_dialog_open()} class="btn btn-primary" >Start preparing order</button>
                              </>
                            ) : ('')}

                            {order_details.status === 'preparing' ? (
                              <>
                                <br />
                                <button onClick={() => mark_order_ready_open()} class="btn btn-primary" >Mark as ready</button>
                              </>
                            ) : ('')}
                          </th>
                        </tr> */}

                        {/* <tr>
                          <th>Scheduled Date of Next Activity</th>
                          <th>
                            {order_details.status === 'invited' ? (ready_details.acceptance) : ('')}
                            {order_details.status === 'accepted' ? (ready_details.preparation) : ('')}
                            {order_details.status === 'preparing' ? (ready_details.ready) : ('')}
                            {order_details.status === 'completed' ? (ready_details.collected) : ('')}
                            {order_details.status === 'collected' ? (ready_details.paid) : ('')}
                          </th>
                        </tr> */}

                        {/* <tr>
                          <th>Scheduled Date of Collection</th>
                          <th>{ready_details.date_time}</th>
                        </tr> */}

                      </tbody>
                    </table>
                  </div>


                  {/* <h3>Timing of Activities for Order {order_details.display_order_number}</h3>
                  <div class="table-responsive mb-5">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Activity</th>
                          <th>Status</th>
                          <th>Date and Time</th>
                          <th>Time Type</th>
                        </tr>

                      </thead>

                      <tbody>
                        <tr>
                          <th>1</th>
                          <th>Acceptance</th>
                          <td>{acceptance_details.status}</td>
                          <td>{acceptance_details.date_time}</td>
                          <td>{acceptance_details.time_type}</td>
                        </tr>

                        <tr>
                          <th>2</th>
                          <th>Preparation</th>
                          <td>{preparation_details.status}</td>
                          <td>{preparation_details.date_time}</td>
                          <td>{preparation_details.time_type}</td>
                        </tr>

                        <tr>
                          <th>3</th>
                          <th>Ready for collection</th>
                          <td>{ready_details.status}</td>
                          <td>{ready_details.date_time}</td>
                          <td>{ready_details.time_type}</td>
                        </tr>

                        <tr>
                          <th>4</th>
                          <th>Collected</th>
                          <td>{collected_details.status}</td>
                          <td>{collected_details.date_time}</td>
                          <td>{collected_details.time_type}</td>
                        </tr>

                        <tr>
                          <th>5</th>
                          <th>Paid</th>
                          <td>{paid_details.status}</td>
                          <td>{paid_details.date_time}</td>
                          <td>{paid_details.time_type}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div> */}

                  <div class="mt-5">
                    <Link to={`/messages/${order_details._id}`} class="btn btn-primary">
                      Send new message to buyer
                    </Link>
                    &nbsp;
                    <Link to={`/messages/${order_details._id}`} class="btn btn-primary">
                      View order messages
                    </Link>
                  </div>
                </div>
                <div class="col-3"></div>

              </div>

              <div class="row">

              </div>


            </>
          )}





        </div>
      </section>


      <Dialog open={mark_order_ready} onClose={mark_order_ready_close} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Is the product ready for collection?</DialogTitle>
        <DialogContent>
          {/* <DialogContentText> */}
          <form onSubmit={markOrderReady}>
            After making it as ready our delivery agents will come collect the order.
          </form>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <button
            class="btn btn-lg btn-success"
            type="submit"
            onClick={mark_order_ready_close} color="primary">
            Cancel
          </button>

          <button
            class="btn btn-lg btn-success"
            type="submit"
            onClick={markOrderReady} color="primary"
            disabled={action_loading} >
            Yes
          </button>
        </DialogActions>
      </Dialog>


      <Dialog open={start_preparing_dialog} onClose={start_preparing_dialog_close} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Start preparing order</DialogTitle>
        <DialogContent>
          {/* <DialogContentText> */}
          <form onSubmit={startPreparingOrder}>
            Please be sure to finish before the stated time, so that we can come pick up with no challenges.
          </form>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <button
            class="btn btn-lg btn-success"
            type="submit"
            onClick={start_preparing_dialog_close} color="primary">
            Cancel
          </button>

          <button
            class="btn btn-lg btn-success"
            type="submit"
            onClick={startPreparingOrder} color="primary"
            disabled={action_loading} >
            Start
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default SellerOrderScreen;
