import React, { useState, useEffect } from 'react';
import { addCategory } from '../actions/categoryActions';
import { validate_multiple_roles } from '../validations';
import { Textbox } from 'react-inputs-validation';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';


function AddCategoryScreen(props) {
 // eslint-disable-next-line
 let user_profile;
 const permitted_roles = ['admin', 'technical-support'];
 const check_permissions = validate_multiple_roles(permitted_roles);
 if (check_permissions.permitted === false) {
   const login_link = 'access-forbidden';
   props.history.push(login_link);
 } else {
   user_profile = check_permissions.user_object;
 }

  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');
  const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);

  const [add_category, set_add_category] = useState('');
  const [add_code, set_add_code] = useState('');
  const [add_priority, set_add_priority] = useState('');
  
  // eslint-disable-next-line
  const [add_category_invalid, set_add_category_invalid] = useState(true);
  // eslint-disable-next-line
  const [add_priority_invalid, set_add_priority_invalid] = useState(true);
  // eslint-disable-next-line
  const [add_code_invalid, set_add_code_invalid] = useState(true);
  // eslint-disable-next-line


  const load_page_data = async () => { 
    return;
  }

  /**
   * Updates user profile
   * @param {*} e 
   */
  const addNewCategory = async (e) =>  {
    e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    const add_data = {
      category: add_category,
      code: add_code,
      priority: add_priority
    };

    const response = await addCategory(add_data); 
    if (response.success === false) {
        set_error_message(response.message); 
        
    } else {
      set_add_priority('');
      set_add_code('');
      set_add_category('');
      set_success_message('New category successfully added');
    }
    set_action_loading(false);

    return; 
  }

  useEffect(() => {
    if (page_loaded === false ) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  

  return (
    <>
    <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2><span class="green-text">Add</span> <span class="orange-text">Category</span></h2>
        </div>       


        {!page_loaded  ? ( // so that only products will be loading at this time 
          <div>Loading ... </div>
        ) : (
          <>
            <div class="row">
              <div class="col-12">
                  {error_message !== '' ? (
                      <div class="alert alert-danger" role="alert">
                        {error_message}
                      </div>
                    ): ('')}
                    {success_message !== '' ? (
                      <div class="alert alert-success" role="alert">
                          {success_message}
                      </div>
                    ): ('')}     
                </div>
            </div>
          
          <div class="row">
            <div class="col-md-4"></div>

            <div class="col-md-4">
                  <form onSubmit={addNewCategory}>
                      <div class="row">
                        <div class="form-group col-md-12 my-3">
                          <label for="add_category">Category name</label>
                          <Textbox
                            attributesWrapper={{}}
                            attributesInput={{
                              id: "categoryName",
                              name: "Category",
                              type: "text",
                              placeholder: ""
                            }}
                            // validate={validate}
                            validationCallback={res =>
                              set_add_category_invalid(res)
                            } 
                            value={add_category}
                            disabled={false}
                            classNameInput=""
                            classNameWrapper=""
                            classNameContainer=""
                            customStyleInput={{}}
                            customStyleWrapper={{}}
                            customStyleContainer={{}}
                            onChange={(add_category, e) => {
                              set_add_category(e.target.value);
                            }}
                            onBlur={e => {
                              console.log(e);
                            }}
                            // onFocus={(e) => {console.log(e);}}
                            // onClick={(e) => {console.log(e);}}
                            validationOption={{
                              name: "Category",
                              check: true,
                              required: true,
                              type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                              // showMsg: true,
                            //   min: 0.01, 
                              // max: 10,
                              // length: 2,
                              // compare: '3',
                              // reg: /^\d{12}|\d{14}$/,
                              // regMsg: 'Invalid phone number',
                              // locale: 'en-US',
                              // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                              // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                              // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                              //   if (res != 'milk') {
                              //     return 'Name cannot be other things but milk';
                              //   }
                              //   return true;
                              // }
                            }}
                          />
                        </div>


                        <div class="form-group col-md-12 my-3">
                          <label for="add_category">Category code</label>
                          <Textbox
                            attributesWrapper={{}}
                            attributesInput={{
                              id: "categoryCode",
                              name: "Code",
                              type: "text",
                              placeholder: ""
                            }}
                            // validate={validate}
                            validationCallback={res =>
                              set_add_code_invalid(res)
                            } 
                            value={add_code}
                            disabled={false}
                            classNameInput=""
                            classNameWrapper=""
                            classNameContainer=""
                            customStyleInput={{}}
                            customStyleWrapper={{}}
                            customStyleContainer={{}}
                            onChange={(add_code, e) => {
                              set_add_code(e.target.value);
                            }}
                            onBlur={e => {
                              console.log(e);
                            }}
                            validationOption={{
                              name: "Code",
                              check: true,
                              required: true,
                              type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                              // showMsg: true,
                            //   min: 0.01, 
                              // max: 10,
                              // length: 2,
                              // compare: '3',
                              // reg: /^\d{12}|\d{14}$/,
                              // regMsg: 'Invalid phone number',
                              // locale: 'en-US',
                              // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                              // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                              // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                              //   if (res != 'milk') {
                              //     return 'Name cannot be other things but milk';
                              //   }
                              //   return true;
                              // }
                            }}
                          />
                        </div>

                        <div class="form-group col-md-12 my-3">
                            <label for="add_priority">Category priority</label>
                          <Textbox
                            attributesWrapper={{}}
                            attributesInput={{
                              id: "Priority",
                              name: "Priority",
                              type: "number",
                              placeholder: ""
                            }}
                            // validate={validate}
                            validationCallback={res =>
                              set_add_priority_invalid(res)
                            } 
                            value={add_priority}
                            disabled={false}
                            classNameInput=""
                            classNameWrapper=""
                            classNameContainer=""
                            customStyleInput={{}}
                            customStyleWrapper={{}}
                            customStyleContainer={{}}
                            onChange={(add_priority, e) => {
                              set_add_priority(e.target.value);
                            }}
                            onBlur={e => {console.log(e);}}
                            // onFocus={(e) => {console.log(e);}}
                            // onClick={(e) => {console.log(e);}}
                            validationOption={{
                              name: "Priority",
                              check: true,
                              required: true,
                              type: 'number', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                              // showMsg: true,
                              min: 1, 
                              // max: 10,
                              // length: 2,
                              // compare: '3',
                              // reg: /^\d{12}|\d{14}$/,
                              // regMsg: 'Invalid phone number',
                              // locale: 'en-US',
                              // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                              // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                              // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                              //   if (res != 'milk') {
                              //     return 'Name cannot be other things but milk';
                              //   }
                              //   return true;
                              // }
                            }}
                          />
                        </div>                      

                        
                      </div>

                      <div class="form-group col-12 my-3 text-center">
                        <button 
                          class="btn btn-lg btn-success" 
                          type="submit"
                          disabled={action_loading}
                          // check invalids 
                        >
                          <i class="glyphicon glyphicon-plus"></i> Add category
                        </button>
                      </div>
                </form>
          </div>
          
          <div class="col-md-4"></div>

            </div>
            </>
        ) }
        
      </div>
    </section>
    </>
  );
}
export default AddCategoryScreen;
