import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { validate_role } from '../validations';
import { get_display_date } from '../config';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { getOrdersV2 } from '../actions/orderActions';
import { Link } from "react-router-dom"

function SellerSalesScreen(props) {
  let user_profile;
  const permitted_role = 'seller';
  const check_permissions = validate_role(permitted_role);
  if (check_permissions.permitted === false) {
    let login_link = 'login';
    if (permitted_role === 'seller') login_link = 'access-forbidden'
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  const productList = useSelector((state) => state.productList);
  const { loading, error } = productList;
  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);
  const [page_loaded, set_page_loaded] = useState(false);
  // eslint-disable-next-line
  const [error_message, set_error_message] = useState('');
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState('');
  const [all_sales, set_all_sales] = useState([]);
  // eslint-disable-next-line
  const [total_amount, set_total_amount] = useState('');
  // eslint-disable-next-line
  const [sales_count, set_sales_count] = useState(0);

  useEffect(() => {
    if (page_loaded === false) {
      // console.log('load page')
      set_page_loaded(true);
      load_page();
    }
    // eslint-disable-next-line     
  }, []);

  const link_cell_formatter = (cell, row) => {
    return (
      row.status === 'Pending' || row.status === 'Rejected' ? (<div>
      </div>) : (<div>
        <Link class="btn btn-success btn-lg" to={`seller-order/${row._id}`}>View/update</Link>
      </div>)
    );
  }

  /*
   * Loads a page date 
   */
  const load_page = async () => {
    set_success_message('');
    set_error_message('');

    let _data = []
    let get_orders;
    try {
      get_orders = await getOrdersV2('id', 'buyer', 'mine', 'status');
      if (get_orders.success) {
        let { data } = get_orders;
        for (let i = 0; i < data.length; i++) {


          let buyer_name = ''
          let buyer_phone = ''
          let buyer_email = ''
          let action = ''
          if (data[i].status !== 'Pending') {
            buyer_name = `${data[i].buyer_details.display_name}`
            buyer_phone = data[i].buyer_details.phone
            buyer_email = data[i].buyer_details.email
            action = <Link class="btn btn-success btn-lg" to={`order/${data[0]._id}`}>View/update</Link>
          }




          data[i].order_number = data[i]._id
          data[i].order_value = data[i].quantity * data[i].product_details.price
          data[i].order_date = get_display_date(data[i].createdAt) //(data[i].createdAt).format('MMMM d, YYYY')
          data[i].customer = ''
          data[i].address = ''
          data[i].buyer = data[i].buyer_details.display_name
          data[i].buyer_phone = buyer_phone
          data[i].buyer_email = buyer_email
          data[i].status = data[i].status
          data[i].order_items = data[i].quantity + ' ' + data[i].product_details.price_per + ' ' + data[i].product_details.product_name
          data[i].action = action
          if (data[i].status === 'Confirmed' || data[i].status === 'Processing' || data[i].status === 'Awaiting collection' || data[i].status === 'In transit' || data[i].status === 'Collected' || data[i].status === 'Delivered') {
            _data.push(data[i])
          }  
          // Confirmed, Processing, Awaiting collection, In transit, Collected/Delivered
        }
        set_all_sales(_data);
      } else {
        set_error_message('Your purchases history is empty.');
      }
    } catch (error) {
      set_error_message(`An error occured fetching purchases history: ${error.message} `);
    }

    return;
  }


  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2><span class="green-text">My</span> <span class="orange-text">Orders</span></h2>
          </div>


          {loading ? ( // so that only products will be loading at this time 
            <div>Loading ... </div>
          ) : error ? (
            <div class="row">
              <div class="col-12">
                <div class="alert alert-danger" role="alert">
                  You do not have any sales yet.
                </div>
              </div>
            </div>
          ) : (
            <div class="row">
              <BootstrapTable
                data={all_sales}
                search
                pagination>
                {/* Order #, Description, Order Status, Order Date/Time, Ordered by, View, Update.  */}
                <TableHeaderColumn dataField='order_number'>Order #</TableHeaderColumn>
                <TableHeaderColumn dataField='order_items' >Description</TableHeaderColumn>
                <TableHeaderColumn dataField='status' >Order Status</TableHeaderColumn>
                <TableHeaderColumn dataField='order_date'>Order Date/Time</TableHeaderColumn>
                <TableHeaderColumn dataField='buyer'>Ordered by</TableHeaderColumn>
                {/* <TableHeaderColumn dataField='tracking_number'>Tracking number</TableHeaderColumn> */}

                {/* <TableHeaderColumn dataField='order_value'>Order value</TableHeaderColumn>
                                    
                                    <TableHeaderColumn dataField='buyer_phone'>Buyer Phone</TableHeaderColumn>
                                    <TableHeaderColumn dataField='buyer_email'>Buyer Email</TableHeaderColumn> */}

                {/* <TableHeaderColumn dataField='status'>Status</TableHeaderColumn> */}
                <TableHeaderColumn dataField='action' dataFormat={link_cell_formatter} isKey={true}></TableHeaderColumn>
              </BootstrapTable>
            </div>
          )}

        </div>
      </section>
    </>
  );
}
export default SellerSalesScreen;
