import {
  ORDER_DETAILS_REQUEST, ORDER_DETAILS_SUCCESS, ORDER_DETAILS_FAIL, MY_ORDER_LIST_REQUEST, MY_ORDER_LIST_SUCCESS, MY_ORDER_LIST_FAIL, ORDER_DELETE_REQUEST, ORDER_DELETE_SUCCESS, ORDER_DELETE_FAIL, ORDER_LIST_REQUEST, ORDER_LIST_SUCCESS, ORDER_LIST_FAIL
} from "../constants/orderConstants";

import axios from 'axios';
import Axios from 'axios';

import { API_URL, API_KEY } from "../config";

let token_string = localStorage.getItem('token');

const createOrder = async (first_name, last_name, phone, email, address1, address2, suburb, city, province, country, delivery_instructions, payment_method, client_id, products, prices) => {
  try {
    const { data } = await Axios.post(`${API_URL}/orders`, { api_key: API_KEY, first_name, last_name, phone, email, address1, address2, suburb, city, province, country, delivery_instructions, payment_method, client_id, products, prices});
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Sorry we could not create order. Please try again',
    }
  }
}

/**
 * Creates order in the new manner
 * @param {} token 
 */
const createOrderV2 = async (product, quantity) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }

  try {
    const { data } = await Axios.post(
      `${API_URL}/orders/v2`,
      { api_key: API_KEY, product, quantity },
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Sorry we could not create order. Please try again',
    }
  }
}


/**
 * Creates a recurring order
 * @param {} token 
 */
const createRecurringOrder = async (product, quantity, repeat_period) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }

  try {
    const { data } = await Axios.post(
      `${API_URL}/orders/recurring`,
      { api_key: API_KEY, product, quantity, repeat_period },
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Sorry we could not create recurring order. Please try again',
    }
  }
}

const listMyOrders = () => async (dispatch, getState) => {
  try {
    dispatch({ type: MY_ORDER_LIST_REQUEST });
    const { userSignin: { userInfo } } = getState();
    const { data } = await Axios.get("/api/orders/mine", {
      headers:
        { Authorization: 'Bearer ' + userInfo.token }
    });
    dispatch({ type: MY_ORDER_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({ type: MY_ORDER_LIST_FAIL, payload: error.message });
  }
}

const listOrders = () => async (dispatch, getState) => {

  try {
    dispatch({ type: ORDER_LIST_REQUEST });
    const { userSignin: { userInfo } } = getState();
    const { data } = await Axios.get("/api/orders", {
      headers:
        { Authorization: 'Bearer ' + userInfo.token }
    });
    dispatch({ type: ORDER_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({ type: ORDER_LIST_FAIL, payload: error.message });
  }
}

const detailsOrder = (orderId) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_DETAILS_REQUEST, payload: orderId });
    const { userSignin: { userInfo } } = getState();
    const { data } = await Axios.get("/api/orders/" + orderId, {
      headers:
        { Authorization: 'Bearer ' + userInfo.token }
    });
    dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    dispatch({ type: ORDER_DETAILS_FAIL, payload: error.message });
  }
}

const payOrder = async (first_name, last_name, phone, email, address1, address2, suburb, city, province, country, delivery_instructions, payment_method, client_id) => {
  try {
    const { data } = await Axios.post(`${API_URL}/orders`, { api_key: API_KEY, first_name, last_name, phone, email, address1, address2, suburb, city, province, country, delivery_instructions, payment_method, client_id });
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Sorry we could not pay for order order. Please try again',
    }
  }
}

const deleteOrder = (orderId) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_DELETE_REQUEST, payload: orderId });
    const { userSignin: { userInfo } } = getState();
    const { data } = await Axios.delete("/api/orders/" + orderId, {
      headers:
        { Authorization: 'Bearer ' + userInfo.token }
    });
    dispatch({ type: ORDER_DELETE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({ type: ORDER_DELETE_FAIL, payload: error.message });
  }
}

const getOrders = async (id, phone, email, city, delivery_zone, payment_method, client_id, status) => {
  try {
    const { data } = await Axios.get(
      `${API_URL}/orders?id=${id}&phone=${phone}&email=${email}&city=${city}&delivery_zone=${delivery_zone}&payment_method=${payment_method}&client_id=${client_id}&status=${status}`,
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Failed to retrieve orders. Please reload page',
    }
  }
}

const getOrdersV2 = async (id, buyer, seller, status) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }

  try {
    const { data } = await Axios.get(
      `${API_URL}/orders?id=${id}&buyer=${buyer}&seller=${seller}&status=${status}`, {
        headers:
          { Authorization: 'Bearer ' + token_string }
      });
    return data;
  } catch (error) {
    console.log(error)
    return {
      success: false,
      message: 'Failed to retrieve orders. Please reload page',
    }
  }
}

const getOrdersV3 = async (id, buyer, seller, status, messages) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }

  try {
    const { data } = await Axios.get(
      `${API_URL}/orders?id=${id}&buyer=${buyer}&seller=${seller}&status=${status}&messages=${messages}`, {
        headers:
          { Authorization: 'Bearer ' + token_string }
      });
    return data;
  } catch (error) {
    console.log(error)
    return {
      success: false,
      message: 'Failed to retrieve orders. Please reload page',
    }
  }
}

const getRecurringOrders = async (id, buyer, status) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }

  try {
    const { data } = await Axios.get(
      `${API_URL}/orders/recurring?id=${id}&buyer=${buyer}&status=${status}`, {
        headers:
          { Authorization: 'Bearer ' + token_string }
      });
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Failed to retrieve orders. Please reload page',
    }
  }
}

const payForOrder = async (payment_method, order_id) => {
  try {
    const { data } = await Axios.post(`${API_URL}/orders/payment`, { api_key: API_KEY, payment_method, order_id});
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Sorry we could not retry order payment. Please try again',
    }
  }
}

const getOrderItems = async (id, order_id, seller_id, product, status) => {
  try {
    const { data } = await axios.get(
      `${API_URL}/orders/items?id=${id}&order_id=${order_id}&seller_id=${seller_id}&product=${product}&status=${status}`,
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message)
    return {
      success: false,
      message: 'Failed to retrieve order items',
    }
  }
}

const getOrderItemsV2 = async (id, order_id, seller_id, product, status, period) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await axios.get(
      `${API_URL}/orders/items?id=${id}&order_id=${order_id}&seller_id=${seller_id}&product=${product}&status=${status}&period=${period}`,
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message)
    return {
      success: false,
      message: 'Failed to retrieve order items',
    }
  }
}

/**
 * Updates an order item
 * @param {Object} update_data 
 * @param {String} id
 * @param {String} update_key
 */
const updateOrderItem = async (update_data, id, update_key) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await Axios.patch(
      `${API_URL}/orders/items`, 
      { 
        api_key: API_KEY, 
        update_data,
        id,
        update_key,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: `Sorry we could not update order item. ${error.message}`,
    }
  }
}

/**
 * Updates an order item
 * @param {Object} update_data 
 * @param {String} id
 * @param {String} update_key
 */
 const updateOrder = async (update_data, id, update_key) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await Axios.patch(
      `${API_URL}/orders`, 
      { 
        api_key: API_KEY, 
        update_data,
        id,
        update_key,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: `Sorry we could not update order item. ${error.message}`,
    }
  }
}

const updateRecurringOrder = async (update_data, id, update_key) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await Axios.patch(
      `${API_URL}/orders/recurring`, 
      { 
        api_key: API_KEY, 
        update_data,
        id,
        update_key,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: `Sorry we could not update order item. ${error.message}`,
    }
  }
}

export { createOrder, createOrderV2, createRecurringOrder, detailsOrder, payOrder, listMyOrders, listOrders, deleteOrder, getOrders, payForOrder, getOrderItems, getOrderItemsV2, updateOrderItem, updateOrder, getOrdersV2, getRecurringOrders, updateRecurringOrder, getOrdersV3 };