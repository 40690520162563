import Axios from "axios";
import {
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAIL, 
  USER_REGISTER_SUCCESS, USER_REGISTER_FAIL, USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_FAIL
} from "../constants/userConstants";

import { API_URL, API_KEY } from "../config";

import jwt_decode from "jwt-decode";

let token_string = localStorage.getItem('token');

const update = ({ userId, name, email, password }) => async (dispatch, getState) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  // eslint-disable-next-line
  const { userSignin: { userInfo } } = getState();
  dispatch({ type: USER_UPDATE_REQUEST, payload: { userId, name, email, password } });
  try {
    const { data } = await Axios.put("/api/users/" + userId,
      { name, email, password }, {
      headers: {
        Authorization: 'Bearer ' + token_string
      }
    });
    dispatch({ type: USER_UPDATE_SUCCESS, payload: data });    
  } catch (error) {
    dispatch({ type: USER_UPDATE_FAIL, payload: error.message });
  }
}

/**
 * Sign in a seller 
 * @param {String} email 
 * @param {String} password 
 * @param {*} props 
 * @param {String} redirect_link 
 */
const signin = (email, password, notify_admin, notify_details, props, redirect_link) => async (dispatch) => {
  // console.log(redirect_link)
  try {
    const { data } = await Axios.post(`${API_URL}/sellers/login`, 
    { api_key: API_KEY, email, password, notify_admin, notify_details });
    
    if (data.success) {
      localStorage.setItem('token', data.token);
      await dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
      // console.log(redirect_link)
      props.history.push(redirect_link);
    } else {
      dispatch({ type: USER_SIGNIN_FAIL, payload: data.message });
    }
    
  } catch (error) {
    dispatch({ type: USER_SIGNIN_FAIL, payload: 'An error occured loggin you in. Please try again' });
  }
}

/**
 * Sign in a buyer
 * @param {String} email 
 * @param {String} password 
 * @param {*} props 
 * @param {String} redirect_link 
 */
const signinBuyer = (email, password, notify_admin, notify_details, props, redirect_link) => async (dispatch) => {
  try {
    const { data } = await Axios.post(`${API_URL}/buyers/login`, { api_key: API_KEY, email, password, notify_admin, notify_details });
    
    if (data.success) {
      localStorage.setItem('token', data.token);
      dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
      props.history.push(redirect_link);
    } else {
      dispatch({ type: USER_SIGNIN_FAIL, payload: data.message });
    }
    
  } catch (error) {
    dispatch({ type: USER_SIGNIN_FAIL, payload: 'An error occured logging you in. Please try again' });
  }
}

/**
 * Sign in an admin
 * @param {String} email 
 * @param {String} password 
 * @param {*} props 
 * @param {String} redirect_link 
 */
const signinAdmin = (email, password, props, redirect_link) => async (dispatch) => {
  try {
    const { data } = await Axios.post(`${API_URL}/admins/login`, { api_key: API_KEY, email, password });
    
    if (data.success) {
      localStorage.setItem('token', data.token);
      dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
      props.history.push(redirect_link);
    } else {
      dispatch({ type: USER_SIGNIN_FAIL, payload: data.message });
    }
    
  } catch (error) {
    dispatch({ type: USER_SIGNIN_FAIL, payload: 'An error occured logging you in. Please try again' });
  }
}

const loginAfterAccountVerification = (token) => (dispatch) => {
  localStorage.setItem('token', token);
  dispatch({ type: USER_SIGNIN_SUCCESS });
}



const register = (first_name, last_name, trading_name, phone, email, password, seller_type, props, redirect_link) => async (dispatch) => {
  try {
    const { data } = await Axios.post(`${API_URL}/sellers`, { api_key: API_KEY, first_name, last_name, trading_name, phone, email, password, seller_type });
    
    if (data.success) {
      localStorage.setItem('token', data.token);
      dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
      props.history.push(redirect_link);
    } else {
      dispatch({ type: USER_REGISTER_FAIL, payload: data.message });
    }
    
  } catch (error) {
    console.log('error signing up', error.message)
    dispatch({ type: USER_REGISTER_FAIL, payload: 'Sorry we could not create your user account. Please try again' });
  }
}

const registerSeller = async (first_name, last_name, trading_name, phone, email, password, seller_type) => {
  try {
    const { data } = await Axios.post(`${API_URL}/sellers`, { api_key: API_KEY, first_name, last_name, trading_name, phone, email, password, seller_type });
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Sorry we could not create account. Please try again',
    }
  }
}

const registerBuyer = async (first_name, last_name, phone, email, password, address1, address2, suburb, city, province, country, delivery_instructions, notify_admin, notify_details, number_on_whatsapp, contact_me_by) => {
  try {
    const { data } = await Axios.post(`${API_URL}/buyers`, { api_key: API_KEY, first_name, last_name, phone, email, password, address1, address2, suburb, city, province, country, delivery_instructions, notify_admin, notify_details, number_on_whatsapp, contact_me_by });
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Sorry we could not create account. Please try again',
    }
  }
}

const verifyBuyer = async (email, otp, notify_admin, notify_details) => {
  try {
    const { data } = await Axios.post(`${API_URL}/buyers/verify`, { api_key: API_KEY, email, otp, notify_admin, notify_details });
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Sorry we could not verify your account. Please try again',
    }
  }
}

// const registerBuyer = (first_name, last_name, trading_name, phone, email, password, seller_type) => async (dispatch) => {
//   dispatch({ type: USER_REGISTER_REQUEST, payload: { first_name, last_name, trading_name, phone, email, password, seller_type } });
//   try {
//     const { data } = await Axios.post(`${API_URL}/buyers`, { api_key: API_KEY, first_name, last_name, trading_name, phone, email, password, seller_type });
//     dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
//     if (data.success) {
//       localStorage.setItem('token', data.token);
//     } else {
//       dispatch({ type: USER_REGISTER_FAIL, payload: data.message });
//     }
    
//   } catch (error) {
//     dispatch({ type: USER_REGISTER_FAIL, payload: 'Sorry we could not create your user account. Please try again' });
//   }
// }

const forgotPassword = async (email) => {
  try {
    const { data } = await Axios.post(`${API_URL}/sellers/reset-password`, { api_key: API_KEY, email });
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Sorry we could not send a password reset email to your provided email',
    }
  }
}

/**
 * Deprecated. Resets a user password using only the token string.
 * 
 * @param {} token 
 */
const resetPassword = async (token) => {
  try {
    const { data } = await Axios.get(`${API_URL}/sellers/reset-password?token=${token}`);
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Sorry we could not could the password reset process. Please try again',
    }
  }
}

/**
 * Updates a user password with user desired password
 * @param {} token 
 */
const updatePassword = async (password, token) => {
  // console.log('action', password, token)
  try {
    const { data } = await Axios.post(
      `${API_URL}/sellers/update-password`,
      { api_key: API_KEY, password },
      {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Sorry we could not could the password reset process. Please try again',
    }
  }
}

/**
 * Verifies a user account
 * @param {} token 
 */
const verifyUserAccount = async (token) => {
  try {
    const { data } = await Axios.post(
      `${API_URL}/sellers/verify`, 
      { api_key: API_KEY },
      {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Sorry we could not complete the password reset process. Please try again',
    }
  }
}

/**
 * Updates user details
 * @param {Object} update_data 
 * @param {String} update_key
 */
const updateUserDetails = async (update_data, update_type) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  // console.log(update_data, update_type)
  try {
    const { data } = await Axios.patch(
      `${API_URL}/sellers`, 
      { 
        api_key: API_KEY, 
        update_data,
        update_type,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Sorry we could not update your account details. Please try again',
    }
  }
}

/**
 * Updates user details
 * @param {Object} update_data 
 * @param {String} update_key
 */
const updateAdminDetails = async (update_data, id, update_type) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await Axios.patch(
      `${API_URL}/admins`, 
      { 
        api_key: API_KEY, 
        update_data,
        id,
        update_type,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Sorry we could not update account details. Please try again',
    }
  }
}

const logout = () => (dispatch) => {
  localStorage.removeItem('token');
  // dispatch({ type: USER_LOGOUT });
  window.location.replace('/');
}

const getUser = async (seller_id) => { 
  if (token_string === null) { token_string = localStorage.getItem('token') }

  try {
    const { data } = await Axios.get(`${API_URL}/sellers?id=${seller_id}`, {
      headers: {
        Authorization: 'Bearer ' + token_string,
      },
    });
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'An error occured getting user profile',
    };
  }
}


const getAdmin = async (admin_id) => { 
  if (token_string === null) { token_string = localStorage.getItem('token') }

  try {
    const { data } = await Axios.get(`${API_URL}/admins?id=${admin_id}`, {
      headers: {
        Authorization: 'Bearer ' + token_string,
      },
    });
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'An error occured getting user profile',
    };
  }
}

const getBuyer = async (buyer_id) => { 
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await Axios.get(`${API_URL}/buyers?id=${buyer_id}`, {
      headers: {
        Authorization: 'Bearer ' + token_string,
      },
    });
    // console.log('data', data)
    return data;
  } catch (error) {
    console.log(error.message)
    return {
      success: false,
      message: 'An error occured getting user profile',
    };
  }
}

const checkLoggedIn = () => {
  let token_string = localStorage.getItem('token');
  if (token_string === null) { token_string = localStorage.getItem('token') }

  // console.log('token string', token_string)
  
  // let's decode this token
  try {
    var userObject = jwt_decode(token_string);
    return userObject;
  } catch (error) {
    return '';
  }
}

const getAdmins = async (id, role) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await Axios.get(
      `${API_URL}/admins?id=${id}&role=${role}`, {
        headers: {
          Authorization: 'Bearer ' + token_string,
        },
      });
    return data;
  } catch (error) {
    console.log(error.message);
    return {
      success: false,
      message: 'Failed to retrieve system administrators. Please reload page',
    }
  }
}

const getAdminsV2 = async (id, role, status) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  // console.log('get admins v2', id, role, status)
  try {
    const { data } = await Axios.get(
      `${API_URL}/admins?id=${id}&role=${role}&status=${status}`, {
        headers: {
          Authorization: 'Bearer ' + token_string,
        },
      });
    return data;
  } catch (error) {
    console.log(error.message);
    return {
      success: false,
      message: 'Failed to retrieve system administrators. Please reload page',
    }
  }
}

const getSellers = async (id, role, status) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await Axios.get(
      `${API_URL}/sellers?id=${id}&role=${role}&status=${status}`, {
        headers: {
          Authorization: 'Bearer ' + token_string,
        },
      });
    return data;
  } catch (error) {
    console.log(error.message);
    return {
      success: false,
      message: 'Failed to retrieve sellers. Please reload page',
    }
  }
}

const getBuyers = async (id, role, status) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await Axios.get(
      `${API_URL}/buyers?id=${id}&role=${role}&status=${status}`, {
        headers: {
          Authorization: 'Bearer ' + token_string,
        },
      });
    return data;
  } catch (error) {
    console.log(error.message);
    return {
      success: false,
      message: 'Failed to retrieve sellers. Please reload page',
    }
  }
}

/**
 * Adds an administrator account 
 * @param {*} first_name 
 * @param {*} last_name 
 * @param {*} phone 
 * @param {*} email 
 * @param {*} password 
 * @param {Array} roles 
 */
const addAdmin = async (first_name, last_name, phone, email, password, roles, suburb, city) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await Axios.post(
      `${API_URL}/admins`, 
      { api_key: API_KEY, first_name, last_name, phone, email, password, roles, suburb, city }, {
      headers: {
        Authorization: 'Bearer ' + token_string,
      },
    });
    return data;
  } catch (error) {
    console.log(error.message);
    return {
      success: false,
      message: 'Sorry we could not create account. Please try again',
    }
  }
}

const sendEmail = async (full_name, email, subject, message) => {
  try {
    const { data } = await Axios.post(
      `${API_URL}/contact/email`, 
      { api_key: API_KEY, full_name, email, subject, message }
    );
    return data;
  } catch (error) {
    console.log(error.message);
    return {
      success: false,
      message: 'Sorry we could not send email. Please try again',
    }
  }
}

const deleteSeller = async (id) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await Axios.post(
      `${API_URL}/sellers/delete`, 
      { 
        api_key: API_KEY, 
        id,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message)
    return {
      success: false,
      message: 'Sorry we could not delete account. Please try again',
    }
  }
}

const verifySeller = async (id) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await Axios.post(
      `${API_URL}/sellers/verify-by-admin`, 
      { 
        api_key: API_KEY, 
        id,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message)
    return {
      success: false,
      message: 'Sorry we could not verify account details. Please try again',
    }
  }
}

const verifyOrganicSeller = async (id) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await Axios.post(
      `${API_URL}/sellers/verify-organic-seller`, 
      { 
        api_key: API_KEY, 
        id,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message)
    return {
      success: false,
      message: 'Sorry we could not verify account details. Please try again',
    }
  }
}

const addNewReview = async (new_data) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  new_data.api_key = API_KEY
  try {
    const { data } = await Axios.post(
      `${API_URL}/sellers/rating`, 
      new_data,
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
    
  } catch (error) {
    return {
      success: false,
      message: 'Sorry we could not add rating. Please try again',
    }
  }
}


export { addNewReview, deleteSeller, verifySeller, getBuyers, getSellers, addAdmin, checkLoggedIn, forgotPassword, logout, register, resetPassword, signin, signinAdmin, signinBuyer, update, getAdmins, getAdminsV2, getBuyer, getUser, sendEmail, updateAdminDetails, updatePassword, verifyUserAccount, updateUserDetails, registerBuyer, registerSeller, loginAfterAccountVerification, verifyOrganicSeller, verifyBuyer, getAdmin };