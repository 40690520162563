import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts, getProductsV3 } from '../actions/productActions';
import { getCategories } from '../actions/categoryActions';
import { validate_role } from '../validations';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { addToCart, addToWishList, updateQuantity } from '../actions/cartActions';
import { Link } from 'react-router-dom';

import { API_URL, buyer_city, get_display_date } from '../config';

function ProductScreen(props) {
  const dispatch = useDispatch();

  const cart = useSelector(state => state.cart);
  const { cartItems } = cart;

  // eslint-disable-next-line
  let user_profile;
  const permitted_role = 'seller';
  const check_permissions = validate_role(permitted_role);
  if (check_permissions.permitted === false) {
    // do nothing
  } else {
    user_profile = check_permissions.user_object;
  }

  const id = props.match.params.id ? props.match.params.id : '';
  const meta = props.match.params.meta ? props.match.params.meta : '';

  const [error_message, set_error_message] = useState('');
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState('');

  const [page_loaded, set_page_loaded] = useState(false);
  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);

  const [product_details, set_product_details] = useState([]);
  // eslint-disable-next-line
  const [product_sales, set_product_sales] = useState([]);
  const [product_pictures, set_product_pictures] = useState([]);
  // eslint-disable-next-line
  const [all_categories, set_all_categories] = useState([]);
  // eslint-disable-next-line
  const [added_to_cart_message, set_added_to_cart_message] = useState('');
  // eslint-disable-next-line
  const [added_to_wishlist_message, set_added_to_wishlist_message] = useState('');
  // eslint-disable-next-line
  const [item_added_to_cart_or_wishlist, set_item_added_to_cart_or_wishlist] = useState('');
  const [in_cart_products, set_in_cart_products] = useState([]);
  // eslint-disable-next-line 
  const [in_wish_list_products, set_in_wish_list_products] = useState([]);
  // eslint-disable-next-line
  const [cart_items, set_cart_items] = useState([]);

  const [related_products, set_related_products] = useState([]);

  // eslint-disable-next-line
  const [display_sub_categories, set_display_sub_categories] = useState([]);
  const [sub_category_name, set_sub_category_name] = useState('');

  const [seller_details, set_seller_details] = useState({});
  const [organic_details, set_organic_details] = useState({});
  const [approved_details, set_approved_details] = useState({ check: false });

  const [expanded_image, set_expanded_image] = useState('');

  const [seller_city, set_seller_city] = useState('');
  const [seller_country, set_seller_country] = useState('');
  const [seller_delivery, set_seller_delivery] = useState('');

  const add_to_cart = async (product_id, product_name) => {
    set_item_added_to_cart_or_wishlist('');

    set_added_to_cart_message('');
    set_added_to_wishlist_message('');

    await dispatch(addToCart(product_id, 1));

    const new_cart_items = JSON.parse(localStorage.getItem('shopping_cart_items'));

    let _in_cart_products = [];
    if (new_cart_items.length > 0) {
      set_cart_items(new_cart_items);
      for (let i = 0; i < new_cart_items.length; i++) {
        _in_cart_products.push(new_cart_items[i].name);
      }
    }
    set_in_cart_products(_in_cart_products);

    set_item_added_to_cart_or_wishlist(product_id);
    set_added_to_cart_message(`${product_name} added to cart`);
  }

  // eslint-disable-next-line 
  const add_to_wishlist = (product_id, product_name) => {
    set_item_added_to_cart_or_wishlist('');

    set_added_to_wishlist_message('');
    set_added_to_cart_message('');

    dispatch(addToWishList(product_id, 1));

    set_item_added_to_cart_or_wishlist(product_id);
    set_in_wish_list_products(JSON.parse(localStorage.getItem('wish_list_items')));
    set_added_to_wishlist_message(`${product_name} added to wishlist`);
  }

  const update_quantity = async (product_id, action) => {
    set_item_added_to_cart_or_wishlist('');

    set_added_to_cart_message('');
    set_added_to_wishlist_message('');

    await dispatch(updateQuantity(product_id, action));

    const new_cart_items = JSON.parse(localStorage.getItem('shopping_cart_items'));

    let _in_cart_products = [];
    if (new_cart_items.length > 0) {
      set_cart_items(new_cart_items);
      for (let i = 0; i < new_cart_items.length; i++) {
        _in_cart_products.push(new_cart_items[i].name);
      }
    }
    set_in_cart_products(_in_cart_products);


    set_item_added_to_cart_or_wishlist(product_id);
    set_added_to_cart_message(`Quantity updated`);
  }

  const get_quantity_in_cart = (_product_name) => {
    let _product_quantity = '';
    if (cartItems.length > 0) {
      for (let i = 0; i < cartItems.length; i++) {
        if (cartItems[i].name === _product_name) {
          _product_quantity = cartItems[i].quantity;
          break;
        }
      }
    }
    return _product_quantity;
  }

  const search_related_products = async () => {
    set_error_message('');
    // moved to in page load data
    return;
  }

  const load_page_data = async () => {
    let get_all_categories;
    let get_product_details;
    // eslint-disable-next-line
    let _product_details;

    try {
      get_product_details = await getProducts(id, 'seller', 'farm', 'product_meta', 'sub_category_meta', 'category_meta', 'product_name', 'quantity', 'quantity_units', 'min_price', 'max_price', 'delivery_zone', 'featured', 'on_sale', 'sort_order');
      if (get_product_details.success === false) {
        set_error_message(get_product_details.message);
      }
      else {

        const { data } = get_product_details;
        _product_details = data[0];
        set_product_details(data[0]);
        set_product_sales(data[0].sales);
        set_product_pictures(data[0].pictures);

        set_expanded_image(data[0].pictures[0]);




        // set_seller_details(data[0].seller_details)
        // console.log(data[0].seller_details)
        // console.log(data[0].seller_details.approved)

        set_seller_details(data[0].seller_details)

        if (data[0].seller_details.organic !== undefined) {
          set_organic_details(data[0].seller_details.organic)
        }



        if (data[0].seller_details.approved !== undefined) {
          set_approved_details(data[0].seller_details.approved)
        }

        set_seller_city(data[0].seller_details.seller_details[0].city)
        set_seller_country(data[0].seller_details.seller_details[0].country)

        if (data[0].seller_details.delivery !== undefined) {
          if (data[0].seller_details.delivery.provides === true) {
            set_seller_delivery('Provides delivery')
          }
        }


        set_item_added_to_cart_or_wishlist(id)
        set_sub_category_name((data[0].sub_category.replace(/-/g, ' ')))
      }
    } catch (error) {
      set_error_message(`An error occured fetching categories: ${error.message} `);
    }

    try {
      get_all_categories = await getCategories('id', 'meta', 'active');
      if (get_all_categories.success === false) {
        set_error_message(get_all_categories.message);
      }
      else {
        const { data } = get_all_categories;
        set_all_categories(data);
      }
    } catch (error) {
      set_error_message(`An error occured fetching categories: ${error.message} `);
    }

    let get_retrieved_products;
    let _search_term = 'search_term';
    let _product_meta = 'product_meta';
    let _sub_category_meta = 'sub_category_meta';
    // eslint-disable-next-line 
    let _category_meta = 'category_meta';
    let _product_name = 'product_name';
    let _quantity = 0;
    let _quantity_units = 'quantity_units';
    let _min_price = 'min_price';
    let _max_price = 'max_price';
    let city = 'city';

    if (!(buyer_city === null || buyer_city === '')) {
      city = buyer_city;
    }


    try {
      get_retrieved_products = await getProductsV3('id', 'seller', 'farm', _product_meta, _sub_category_meta, _product_details.category_meta, _product_name, _quantity, _quantity_units, _min_price, _max_price, 'delivery_zone', 'featured', 'on_sale', 'sort_order', 'sort_by', _search_term, 'latitude', 'longitude', city);
      if (get_retrieved_products.success === false) {
        // set_error_message(get_retrieved_products.message);
      }
      else {
        const { data } = get_retrieved_products;
        let _data = []
        for (let i = 0; i < 4; i++) {
          if (_data.length < 3 && data[i]._id !== id) {
            _data.push(data[i])
          }
        }
        set_related_products(_data);
      }
    } catch (error) {
      // set_error_message(`An error occured fetching product types: ${error.message} `);
    }

    return;
  }

  useEffect(() => {
    if (page_loaded === false) {
      search_related_products()
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);



  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2><span class="green-text">{product_details.product_name}</span> <span class="orange-text"></span></h2>
          </div>


          {action_loading ? ( // so that only products will be loading at this time 
            <div>
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only"></span>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div class="row">
                <div class="col-12">
                  {error_message !== '' ? (
                    <div class="alert alert-danger" role="alert">
                      {error_message}
                    </div>
                  ) : ('')}
                  {success_message !== '' ? (
                    <div class="alert alert-success" role="alert">
                      {success_message}
                    </div>
                  ) : ('')}
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <span class="breadcrumb">
                    <Link to="/shop">
                      Shop
                    </Link>
                  &nbsp;>&nbsp;
                    <Link to={`/shop/${product_details.category_meta}`}>
                      {product_details.category}
                    </Link>
                  &nbsp;>&nbsp;
                    <Link to={`/shop/${product_details.category_meta}/${product_details.sub_category}`}>
                      {sub_category_name}
                    </Link>
                  &nbsp;>&nbsp;
                    {product_details.product_name}
                  </span>
                </div>




                <div class="col-md-4 seller-details-in-product-view d-none d-md-block d-lg-block d-xl-block">
                  {product_details.selling_method !== 'direct' ? (
                    <div class="mb-5">
                      <h2 class="">Cart</h2>
                      <p>${product_details.price}</p>
                      {
                        in_cart_products.includes(product_details.product_name) ? (
                          <>
                            <button
                              style={{ color: "#fff", fontSize: "12px" }}
                              class="btn btn-primary btn-sm mb-2"
                              onClick={() => update_quantity(product_details._id, 'decrement')}
                            >
                              -
                            </button>
                            &nbsp;&nbsp;&nbsp;
                            <span style={{ fontWeight: "bold", fontSize: "24px" }}>{get_quantity_in_cart(product_details.product_name)}</span>
                            &nbsp;&nbsp;&nbsp;

                            <button
                              style={{ color: "#fff", fontSize: "12px" }}
                              class="btn btn-primary btn-sm mb-2"
                              onClick={() => update_quantity(product_details._id, 'increment')}
                            >
                              +
                            </button>
                          </>
                        ) : (
                          <button
                            style={{ width: "100%", color: "#fff", fontSize: "12px" }}
                            class="btn btn-primary btn-sm mb-2"
                            onClick={() => add_to_cart(product_details._id, product_details.product_name)}
                          >
                            Add to cart
                          </button>
                        )
                      }
                    </div>
                  ) : ('')}

                  {product_details.selling_method === 'direct' || product_details.selling_method === 'hybrid' ? (
                    <>


                      <div class="row mt-5">
                        <div class="col-md-12">
                          <h2 class="seller-name-heading"><Link to={`/store/${product_details.username}`}>{product_details.seller_name}</Link></h2>
                        </div>

                        <div class="col-md-12">
                          <img src={`${API_URL}/${product_details.seller_image}`} alt={product_details.seller_name} class="img-thumbnail seller-image-in-product" />
                        </div>
                        <div class="col-md-12 mt-3">

                          {approved_details.check ? (<h3 class="green-text"><i class="bi bi-check"></i> {approved_details.status} </h3>) : ('')}

                          {(organic_details.check === true && organic_details.status === 'Verified Organic Farmer') ? (
                            <h3 class="green-text">&#x2705; {organic_details.status}</h3>
                          ) : ('')}

                          <h3 class="">Based in {seller_city}, {seller_country}</h3>
                          <h3 class="">{seller_delivery}</h3>
                        </div>
                      </div>

                      <div class="row mt-5">
                        <p>
                          This is an independent seller. Please use due diligence for all online purchases. <Link to="/general-safety-advice" class=""> See more details here.</Link>
                        </p>
                      </div>
                    </>

                  ) : ('')}
                </div>
                {/* col-md-4 to contact seller  */}







                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-12">
                      <Link to={`/order-product/${meta}/${id}`} class="btn btn-primary my-4">Order now</Link>
                    </div>
                    <div class="col-md-12">

                      <img src={`${API_URL}/${expanded_image}`} alt={product_details.product_name} class="main-product-image" />
                      <br />Photos last updated: {get_display_date(product_details.pictures_last_update)}
                    </div>
                    {product_pictures.map(pp => (
                      pp !== expanded_image ? (
                        <div class="col-md-3" onClick={() => set_expanded_image(pp)}>
                          <img src={`${API_URL}/${pp}`} alt={product_details.product_name} class="supporting-product-image" />
                        </div>
                      ) : ('')
                    ))}
                  </div>

                  <div class="row my-4">
                    <div class="col-md-12 my-5">
                      <h2>
                        <span class="">
                          US${product_details.price} {product_details.quantity_units === 'each' ? '' : 'per'} {product_details.quantity_units}
                        </span>
                        <span class="orange-text"></span>
                      </h2>
                      <p>
                        <span class="green-text">Product last updated: {get_display_date(product_details.updatedAt)}</span>
                      </p>
                      <Link to={`/order-product/${meta}/${id}`} class="btn btn-primary mt-4">Order now</Link>
                    </div>

                    <div class="col-md-12">
                      <div dangerouslySetInnerHTML={{ __html: product_details.description }} />
                    </div>
                  </div>

                  <div class="row my-4">
                    {related_products.length > 0 ?
                      (related_products.map((p) => (
                        p !== undefined ? (
                          <div key={p._id} class="col-md-4 d-flex text-center" data-aos="fade-up">
                            <div class="info-box product-box">
                              <Link to={`/product/${p.product_meta}/${p._id}`}>
                                <img src={`${API_URL}/${p.pictures[0]}`} alt={p.product_name} />
                                <h3 class="green-text">{p.product_name}</h3>
                              </Link>

                              <p class="green-text">${p.price}</p>

                              {p.selling_method === 'direct' ? (
                                <Link to={`/product/${p.product_meta}/${p._id}`} class="mt-2 contact-seller"><button class="btn btn-primary btn-sm mb-2">Contact seller</button></Link>
                              ) : (
                                <div class="row mt-2">
                                  <div class="col-md-2"></div>
                                  <div class="col-md-2 text-right">
                                    <i class="bi bi-basket"></i>
                                  </div>
                                  <div class="col-md-6">
                                    {
                                      in_cart_products.includes(p.product_name) ? (
                                        <>
                                          <button
                                            style={{ color: "#fff", fontSize: "12px" }}
                                            class="btn btn-primary btn-sm mb-2"
                                            onClick={() => update_quantity(p._id, 'decrement')}
                                          >
                                            -
                                          </button>
                                          &nbsp;&nbsp;&nbsp;
                                          <span style={{ fontWeight: "bold", fontSize: "24px" }}>{get_quantity_in_cart(p.product_name)}</span>
                                          &nbsp;&nbsp;&nbsp;

                                          <button
                                            style={{ color: "#fff", fontSize: "12px" }}
                                            class="btn btn-primary btn-sm mb-2"
                                            onClick={() => update_quantity(p._id, 'increment')}
                                          >
                                            +
                                          </button>
                                        </>
                                      ) : (
                                        <button
                                          style={{ width: "100%", color: "#fff", fontSize: "12px" }}
                                          class="btn btn-primary btn-sm mb-2"
                                          onClick={() => add_to_cart(p._id, p.product_name)}
                                        >
                                          Add to cart
                                        </button>
                                      )
                                    }

                                  </div>
                                </div>
                              )}


                              <div class="row my-5">
                                {added_to_cart_message !== '' && item_added_to_cart_or_wishlist === p._id ? (
                                  <>
                                    <div class="col-md-2"></div>
                                    <div class="col-md-8">
                                      <div class="alert alert-success" role="alert">
                                        {added_to_cart_message}. <Link to="/cart">Open cart</Link>
                                      </div>
                                    </div>
                                    <div class="col-md-2"></div>
                                  </>
                                ) : ('')
                                }
                                {added_to_wishlist_message !== '' && item_added_to_cart_or_wishlist === p._id ? (
                                  <>
                                    <div class="col-md-2"></div>
                                    <div class="col-md-8">
                                      <div class="alert alert-success" role="alert">
                                        {added_to_wishlist_message}. <Link to="/wishlist">Open wishlist</Link>
                                      </div>
                                    </div>
                                    <div class="col-md-2"></div>
                                  </>
                                ) : ('')
                                }
                              </div>
                            </div>
                          </div>
                        ) : ('')

                      ))
                      ) : (
                        <div class="col-12 d-flex text-center" data-aos="fade-up">
                          <div class="alert alert-danger" role="alert">
                            No related products found
                          </div>
                        </div>
                      )}
                  </div>
                </div>


                <div class="col-12 d-block d-md-none">
                  {product_details.selling_method !== 'direct' ? (
                    <div class="mb-5">
                      <h2 class="">Cart</h2>
                      <p>${product_details.price}</p>
                      {
                        in_cart_products.includes(product_details.product_name) ? (
                          <>
                            <button
                              style={{ color: "#fff", fontSize: "12px" }}
                              class="btn btn-primary btn-sm mb-2"
                              onClick={() => update_quantity(product_details._id, 'decrement')}
                            >
                              -
                            </button>
                            &nbsp;&nbsp;&nbsp;
                            <span style={{ fontWeight: "bold", fontSize: "24px" }}>{get_quantity_in_cart(product_details.product_name)}</span>
                            &nbsp;&nbsp;&nbsp;

                            <button
                              style={{ color: "#fff", fontSize: "12px" }}
                              class="btn btn-primary btn-sm mb-2"
                              onClick={() => update_quantity(product_details._id, 'increment')}
                            >
                              +
                            </button>
                          </>
                        ) : (
                          <button
                            style={{ width: "100%", color: "#fff", fontSize: "12px" }}
                            class="btn btn-primary btn-sm mb-2"
                            onClick={() => add_to_cart(product_details._id, product_details.product_name)}
                          >
                            Add to cart
                          </button>
                        )
                      }
                    </div>
                  ) : ('')}

                  {product_details.selling_method === 'direct' || product_details.selling_method === 'hybrid' ? (
                    <>


                      <div class="row mt-5">
                        <div class="col-md-12">
                          <h2 class="seller-name-heading"><Link to={`/store/${product_details.username}`}>{product_details.seller_name}</Link></h2>
                        </div>
                        <div class="col-md-12">
                          <img src={`${API_URL}/${product_details.seller_image}`} alt={product_details.seller_name} class="img-thumbnail seller-image-in-product" />
                        </div>
                        <div class="col-md-12 mt-3">

                          {approved_details.check ? (<h3 class="green-text"><i class="bi bi-check"></i> {approved_details.status}</h3>) : ('')}


                          {(organic_details.check === true && organic_details.status === 'Verified Organic Farmer') ? (
                            <h3 class="green-text">&#x2705; {organic_details.status}</h3>
                          ) : ('')}

                        </div>
                      </div>

                      <div class="row mt-5">
                        <p>
                          This is an independent seller. Please use due diligence for all online purchases. <Link to="/general-safety-advice" class=""> See more details here.</Link>
                        </p>
                      </div>
                    </>

                  ) : ('')}
                </div>




              </div>


            </>
          )}

        </div>
      </section>
    </>
  );
}
export default ProductScreen;
