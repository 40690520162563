import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactPlayer from "react-player"
import { Link } from 'react-router-dom';

function SellerLandingScreen(props) {
  const userSignin = useSelector(state => state.userSignin);
  const { userInfo } = userSignin;
  const redirect = props.location.search ? props.location.search.split("=")[1] : '/';

  // localStorage.setItem('potential_user_type', 'seller');

  useEffect(() => {
    if (userInfo) {
      props.history.push(redirect);
    }
    return () => {
      //
    };
    // eslint-disable-next-line
  }, [userInfo]);

  // console.log('props', props);

  // const submitHandler = (e) => {
  //   e.preventDefault();
  //   // console.log(userInfo);
  //   dispatch(signin(email, password, props, redirect));
  //   // console.log('after dispatch', userInfo);
  // }
  return (
      <>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous"/>

<section id="heroSeller">
    <div class="heroSeller-container">
      <div id="heroSellerCarousel" class="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="5000">

        <ol class="carousel-indicators" id="heroSeller-carousel-indicators"></ol>

        <div class="carousel-inner" role="listbox">
          <div class="carousel-item active" style={{ backgroundImage: 'url("assets/img/sellerHomeWallpaper.jpg")' }}>
            <div class="carousel-container">
              <div class="carousel-content container">
                <h2 class="animate__animated animate__fadeInDown"><span class="green-text">Farm-Fresh </span><span class="orange-text">Delights</span></h2>

<h1 class="header-text" style = {{fontSize: "3vw", color: "white"}}><strong>Helping farmers, traders and vendors reach more customers and sell more quality farm produce </strong></h1>            

                <Link to="/register-as-seller" class="btn-get-started animate__animated animate__fadeInUp scrollto">Register now</Link>
                







    <div class="container">
        <div class="row">
            <div class="col-md-4">

                    <div class="card-bottom-page">
                        <h4 class="card-bottom-page-text">Access new markets </h4>
                    </div>

            </div>
            <div class="col-md-4">

                <div class="card-bottom-page">
                    <h4 class="card-bottom-page-text">Reduce food loss </h4>
                </div>

            </div>
            <div class="col-md-4">

                <div class="card-bottom-page">
                    <h4 class="card-bottom-page-text">Increase sales </h4>
                </div>

            </div>
        </div>
    </div>            








              </div>
            </div>
          </div>

        </div>



      </div>
    </div>
  </section>

  

    <div class="container secndary-wrapper">
        <div class="row">
            <div class="col-md-6">
                <h2 class="secondary-header-text">Farmers, extend your market reach by making your farm produce available online 
                </h2>

                <h4 class="secondary-decorative-text">Selling on Farm-Fresh takes just 3 easy steps:</h4>

                <ul class="secondary-list">
                    <li class="secondary-list--item">Register as a seller on Farm-Fresh Delights </li>
                    <li class="secondary-list--item"> Work with our support team who will help you get set up on the Farm-Fresh platform</li>
                    <li class="secondary-list--item">You update your Farm-Fresh profile daily with the produce you are selling</li>
                </ul>
                <Link to="/register-as-seller" class="btn-get-started animate__animated animate__fadeInUp scrollto">Register now</Link>
            </div>
            <div class="col-md-6">
                <div class="side-image--wrapper">
                    <img class="side-image--wrapper-image" src="assets/img/personBuying.jpg" alt=""/>

                    <h4 class="decp-text">Once these 3 steps are complete, customers can <b>find and buy your produce</b> online when they search on
                        Farm-Fresh Delights</h4>
                </div>
            </div>
        </div>
    </div>



    <div class="reason-section">
        <div class="container">
            <div class="row">
                <h2 class="reason-main-text">5 reasons to register as a seller on Farm-Fresh Delights</h2>
                <ul class="reason-main-text-list">
                    <li class="reason-main-text-list-item">benefit from a vast network of buyers and extend your customer reach</li>
                    <li class="reason-main-text-list-item">get great prices for your produce </li>
                    <li class="reason-main-text-list-item">manage and track your inventory</li>
                    <li class="reason-main-text-list-item">join a growing network of farmers for sharing farming tips </li>
                    <li class="reason-main-text-list-item">get free advisory </li>
                </ul>

               

            </div>
            <Link to="/register-as-seller" class="btn-get-started animate__animated animate__fadeInUp scrollto">Register now</Link>
        </div>
    </div>


    <section class="container">
           
        <h2 class="video-section">Farmers who have registered on Farm-Fresh Delights have this to say: </h2>

        <div class="video-section-bottom">
            <ReactPlayer
            url="https://www.youtube.com/watch?v=-lAZIV5z8L0"
            />
        </div>

    </section>

      </>
  )
}
export default SellerLandingScreen;
