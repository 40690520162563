import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { forgotPassword } from '../actions/userActions';



function ForgotPasswordScreen(props) {
  const [email, setEmail] = useState('');

  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');

  const [action_loading, set_action_loading] = useState('');

  const userSignin = useSelector(state => state.userSignin);
  const { userInfo } = userSignin;
  const redirect = props.location.search ? props.location.search.split("=")[1] : '/';

  useEffect(() => {
    if (userInfo) {
      props.history.push(redirect);
    }
    return () => {
      //
    };
    // eslint-disable-next-line
  }, [userInfo]);


  const submitHandler = async (e) =>  {
    e.preventDefault();

    set_action_loading(true);
    set_error_message('');
    set_success_message('');
    const response = await forgotPassword(email); 
    if (response.success === false) {
        set_error_message(response.message); 
    } else {
        set_success_message(response.message);
    }
    set_action_loading(false);

    return; 
  }

  return <section id="about" class="about main-content mt-5 login-content">
    <div class="container-fluid" data-aos="fade-up">
      <div class="row mt-5">
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center">
          <h3 class="green-text">RESET PASSWORD</h3>
          {error_message !== '' ? (
          <div class="col-12">
            <div class="alert alert-danger" role="alert">
              {error_message}
            </div>
          </div>): ('')}

          {success_message !== '' ? (
          <div class="col-12">
            <div class="alert alert-success" role="alert">
              {success_message}
            </div>
          </div>): ('')}
                            

          <form onSubmit={submitHandler} >
            <div class="form-group my-4">
              <input class="form-control" type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
            </div>
            <div class="form-group my-4">
              <button type="submit" class="btn btn-primary" disabled={action_loading}>
              {
                  !action_loading ? ('Reset Password'):(
                    <>
                      <div class="spinner-border text-orange" role="status">
                      </div> 
                      &nbsp;Please wait
                    </>
                  )
                }
              </button>
              &nbsp;or&nbsp;
              <Link to={redirect === "/" ? "login-seller" : "login?redirect=" + redirect} className="btn btn-primary faded-green-shade" >Login</Link>
            </div>
          </form>
        </div>
        <div class="col-md-4"></div>
    </div>
    </div>
  </section> 
}
export default ForgotPasswordScreen;