import React, { useState, useEffect } from 'react';
import { retrieveSellerRequestedProducts, updateBuyerRequestedProduct} from '../actions/productActions';
import { validate_multiple_roles } from '../validations';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import moment from 'moment';
import { Link } from 'react-router-dom';

export default function SellerRequestsScreen(props) { 
  // eslint-disable-next-line
  let user_profile;
  const permitted_roles = ['seller', 'buyer'];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = 'access-forbidden';
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  // eslint-disable-next-line
  const status = props.match.params.status ? props.match.params.status : 'Pending';

  const [error_message, set_error_message] = useState('');
  // eslint-disable-next-line 
  const [success_message, set_success_message] = useState('');

  const [page_loaded, set_page_loaded] = useState(false);
  // eslint-disable-next-line 
  const [action_loading, set_action_loading] = useState(false);


  const [orders, set_orders] = useState([]);  

  // eslint-disable-next-line 
  const [id, set_id] = useState('id');
  // eslint-disable-next-line 
  const [phone, set_phone] = useState('phone');
  // eslint-disable-next-line 
  const [email, set_email] = useState(user_profile.email);
  // eslint-disable-next-line 
  const [city, set_city] = useState('city');
  // eslint-disable-next-line 
  const [delivery_zone, set_delivery_zone] = useState('delivery_zone');
  // eslint-disable-next-line 
  const [payment_method, set_payment_method] = useState('payment_method');
  // eslint-disable-next-line 
  const [client_id, set_client_id] = useState('client_id');
  // eslint-disable-next-line 
  // const [status, set_status] = useState('status');

  
  // eslint-disable-next-line
  const [add_city_invalid, set_add_city_invalid] = useState(true);

  const link_cell_formatter= (cell, row) => {
    return (
    <div>
      <button class="btn btn-danger btn-lg" onClick={()=>cancelRequest(row._id)}>Delete request</button>
    </div>);
  }

  const load_page_data = async () => { 
     let get_orders;
     try {
        get_orders = await retrieveSellerRequestedProducts('id', 'buyer', 'status');
        // console.log(get_orders)
        // console.log('get orders', get_orders);
        if (get_orders.success) {
          let { data } = get_orders; 

          
                      
          for (let i=0; i < data.length; i++) {
              console.log(data[i]);
            data[i].product = data[i].product
            data[i].category = data[i].category
            data[i].other_names = data[i].other_names
            data[i].status = data[i].status
            // data[i].action = <Link class="" to={`/track/${data[i].tracking_number}/${data[i]._id}`}><i class="bi bi-truck"></i> Track order</Link>
            data[i].action = <button class="btn btn-danger btn-lg" onClick={()=>cancelRequest(data[i]._id)}>Delete request</button>
          }
          set_orders(data);
        } else {
          set_error_message('Your requests history is empty.');
        }
      } catch (error) {
        set_error_message('Your requests history is empty.');
      }

    return;
  } 
  
  
  
  useEffect(() => {
    if (page_loaded === false ) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  const cancelRequest = async (requested_id) =>  {
    // e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    const update_data = {
    }

    const response = await updateBuyerRequestedProduct(update_data, requested_id, 'delete'); 
    if (response.success === false) {
        set_error_message(response.message); 
    } else {    
      set_success_message('Request successfully deleted.');
      load_page_data();
    }

    set_action_loading(false);

    return; 
  }


  

  return (
    <>
    <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
  <h2><span class="green-text">My Products</span> <span class="orange-text">Requests History</span></h2>
        </div>       


        {!page_loaded  ? ( // so that only products will be loading at this time 
          <div>Loading ... </div>
        ) : (
          <>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-12">
                            {error_message !== '' ? (
                                <div class="alert alert-danger" role="alert">                        
                                    {error_message}
                                </div>
                                ): ('')}
                                {success_message !== '' ? (
                                <div class="alert alert-success" role="alert">                        
                                    {success_message}
                                </div>
                                ): ('')}     
                        </div>    
                    </div>
                    </div>
            </div>
            </>
        ) }


        <div class="row">
            <div class="col-12">
                

                <BootstrapTable
                                data={ orders }
                                search
                                pagination>
                                    <TableHeaderColumn dataField='product' isKey={ true }>Produce name</TableHeaderColumn>
                                    {/* <TableHeaderColumn dataField='tracking_number'>Tracking number</TableHeaderColumn> */}
                                    <TableHeaderColumn dataField='category' >Category</TableHeaderColumn>
                                    <TableHeaderColumn dataField='other_names'>Other produce names</TableHeaderColumn>
                                   
                                    <TableHeaderColumn dataField='status' >Status</TableHeaderColumn>
                                    {/* <TableHeaderColumn dataField='status'>Status</TableHeaderColumn> */}
                                    {/* <TableHeaderColumn dataField='action' dataFormat={link_cell_formatter} isKey={ true }></TableHeaderColumn> */}
                                </BootstrapTable>
            </div>
        </div>

        
      </div>

    </section>

    

    </>
  );
}
