import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import { logout } from './actions/userActions';
import { getOrdersV2 } from './actions/orderActions';
import { Link } from 'react-router-dom';
// import { browserHistory } from 'react-router'
// import createHistory from 'history/createBrowserHistory'
// import browserHistory from "history/createBrowserHistory";
import { retrieveProducts } from './actions/productActions';
import { getNotifications } from './actions/notificationActions';
import { getVerifications } from './actions/verificationActions';


// import Cookie from 'js-cookie';
import jwt_decode from "jwt-decode";
import CookieConsent from "react-cookie-consent";

// eslint-disable-next-line 
import HomeScreen from './screens/HomeScreen';
import Error404Screen from './screens/Error404Screen';
import Error403Screen from './screens/Error403Screen';

import SellerLandingScreen from './screens/SellerLandingScreen';
import RegisterSellerScreen from './screens/RegisterSellerScreen';
import RegisterScreen from './screens/RegisterScreen';
import LoginSellerScreen from './screens/LoginSellerScreen';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import SellerProfileScreen from './screens/SellerProfileScreen';
import SellerDashboardScreen from './screens/SellerDashboardScreen';
import VerifyAccountScreen from './screens/VerifyAccountScreen';
import AccountSettingsScreen from './screens/AccountSettingsScreen';

import SellerProductsScreen from './screens/SellerProductsScreen';
import SellerAddProductsScreen from './screens/SellerAddProductsScreen';
import SellerProductScreen from './screens/SellerProductScreen';
import SellerOrdersScreen from './screens/SellerOrdersScreen';
import SellerOrderScreen from './screens/SellerOrderScreen';
import SellerInventoryAlertsScreen from './screens/SellerInventoryAlertsScreen';
import SellerSalesScreen from './screens/SellerSalesScreen';
import SellerPerfomanceSummaryScreen from './screens/SellerPerfomanceSummaryScreen';
import SellerCaseLogsScreen from './screens/SellerCaseLogsScreen';
import SellerOrderInvitationScreen from './screens/SellerOrderInvitationScreen';
import SellerOrderAcceptScreen from './screens/SellerOrderAcceptScreen';
import SellerOrderDeclineScreen from './screens/SellerOrderDeclineScreen';

import ShopScreen from './screens/ShopScreen';
import SellerShopScreen from './screens/SellerShopScreen';
import ProductScreen from './screens/ProductScreen';
import OrderProductScreen from './screens/OrderProductScreen';
import ResourcesScreen from './screens/ResourcesScreen';

import CartScreen from './screens/CartScreen';
import CheckoutScreen from './screens/CheckoutScreen';
import OrderScreen from './screens/OrderScreen';
import PaymentScreen from './screens/PaymentScreen';
import PayScreen from './screens/PayScreen';
import TrackScreen from './screens/TrackScreen';

import LoginAdminScreen from './screens/LoginAdminScreen';
import StaffMembersScreen from './screens/StaffMembersScreen';
import CategoriesScreen from './screens/CategoriesScreen';
import AddCategoryScreen from './screens/AddCategoryScreen';
import ViewCategoryScreen from './screens/ViewCategoryScreen';
import ProductTypesScreen from './screens/ProductTypesScreen';
import ViewProductTypeScreen from './screens/ViewProductTypeScreen';
import AddProductTypeScreen from './screens/AddProductTypeScreen';
import CurrenciesAndRatesScreen from './screens/CurrenciesAndRatesScreen';
import AddStaffMemberScreen from './screens/AddStaffMemberScreen';
import ViewStaffMemberScreen from './screens/ViewStaffMemberScreen';
import CountriesUploadScreen from './screens/CountriesUploadScreen';
import CountriesScreen from './screens/CountriesScreen';
import AdminOrdersScreen from './screens/AdminOrdersScreen';
import AdminOrderScreen from './screens/AdminOrderScreen';
import AdminOrderItemScreen from './screens/AdminOrderItemScreen';
import AdminProductsScreen from './screens/AdminProductsScreen';
import AdminProductScreen from './screens/AdminProductScreen';
import AdminDashboardScreen from './screens/AdminDashboardScreen';
import SellersScreen from './screens/SellersScreen';
import BuyersScreen from './screens/BuyersScreen';
import GeneralSafetyAdviceScreen from './screens/GeneralSafetyAdviceScreen';

import AdminSellerScreen from './screens/AdminSellerScreen';

import BuyerDashboardScreen from './screens/BuyerDashboardScreen';
import BuyerProfileScreen from './screens/BuyerProfileScreen';
import PaySellerVerifierScreen from './screens/PaySellerVerifierScreen';

import BuyerOrderScreen from './screens/BuyerOrderScreen';

import { Notifications } from 'react-push-notification';
// import addNotification from 'react-push-notification';


import SigninScreen from './screens/SigninScreen';

import TermsScreen from './screens/TermsScreen';
import PrivacyScreen from './screens/PrivacyScreen';
import HowItWorksScreen from './screens/HowItWorksScreen';
import FaqScreen from './screens/FaqScreen';
import ListingPolicyScreen from './screens/ListingPolicyScreen';
import AboutScreen from './screens/AboutScreen';
import RefundPolicyScreen from './screens/RefundPolicyScreen';
import MyPurchasesScreen from './screens/MyPurchasesScreen';

import MyRequestsScreen from './screens/MyRequestsScreen';
import SellerRequestsScreen from './screens/SellerRequestsScreen';

import ContactScreen from './screens/ContactScreen';
import SpecifyNewProductScreen from './screens/SpecifyNewProductScreen';

import SellerRequestedProductsScreen from './screens/SellerRequestedProductsScreen';
import BuyerRequestedProductsScreen from './screens/BuyerRequestedProductsScreen';

import AdminHelpScreen from './screens/AdminHelpScreen';

import VerifierDashboardScreen from './screens/VerifierDashboardScreen';
import PendingVerificationRequestsScreen from './screens/PendingVerificationRequestsScreen';
import CompletedVerificationRequestsScreen from './screens/CompletedVerificationRequestsScreen';
import VerifyFarmerScreen from './screens/VerifyFarmerScreen';
import SellerVerificationDetailsScreen from './screens/SellerVerificationDetailsScreen';
import AdminProfileScreen from './screens/AdminProfileScreen';
import AdminSellerVerificationsHistoryScreen from './screens/AdminSellerVerificationsHistoryScreen';
import VerifierMessagesScreen from './screens/VerifierMessagesScreen';

import MessagesScreen from './screens/messages/MessagesScreen';

import { useDispatch, useSelector } from 'react-redux';
// import { getProductTypes } from './actions/categoryActions';

import { FFD_COUNTRIES_METAS, FFD_DEFAULT_COUNTRY, FFD_COUNTRIES } from './config';


function App() {
  // eslint-disable-next-line 
  const userSignin = useSelector((state) => state.userSignin);
  // const { userInfo } = userSignin;

  const dispatch = useDispatch();

  const cart = useSelector(state => state.cart);

  const { cartItems } = cart;

  // eslint-disable-next-line 
  const [cart_items_count, set_cart_items_count] = useState(0);

  const [new_orders, set_new_orders] = useState([]);
  const [notifications_bell, set_notifications_bell] = useState(false);

  const [messages_count, set_messages_count] = useState(2);

  const [listed_products, set_listed_products] = useState([]);

  const checkLoggedIn = () => {
    const token_string = localStorage.getItem('token');

    // let's decode this token.
    try {
      var userObject = jwt_decode(token_string);
      return userObject;
    } catch (error) {
      return '';
    }
  }

  let user_profile = checkLoggedIn()

  // eslint-disable-next-line 
  // TODO: only happen if seller
  const check_notifications = async (e) => {
    let get_orders;
    try {
      get_orders = await getOrdersV2('id', 'buyer', 'mine', 'Pending');
      if (get_orders.success) {
        let { data } = get_orders;
        set_new_orders(data)
        set_notifications_bell(true)
      }
    } catch (error) {
      // set_error_message(`An error occured fetching new orders: ${error.message} `);
    }

    if (user_profile) {
      let get_notification;
      try {
        get_notification = await getNotifications('id', user_profile._id, 'sender', 'false');

        // console.log(get_notification)

        if (get_notification.success) {
          let { data } = get_notification;
          // set_new_notifications(data)
          if (data.length > 1) {
            for (let i = 0; i < data.length; i++) {
              if (!data[i].read) {
                set_notifications_bell(true)
              }
            }
          }
        }
      } catch (error) {
        // set_error_message(`An error occured fetching new orders: ${error.message} `);
      }
    }



    if (user_profile && (user_profile.roles).includes('verifier')) {
      // check overdue notification items 
      let get_verifications;
      try {
        get_verifications = await getVerifications('id', user_profile._id, 'seller_id', 'Pending')

        console.log(get_verifications)

        if (get_verifications.success) {
          let { data } = get_verifications;


          for (let i = 0; i < data.length; i++) {
            // console.log(data[i]);
            // console.log(new Date(), new Date(new Date(data[i].visit_date)))
            // console.log('after dates')
            if (new Date() > new Date(new Date(data[i].visit_date))) {
              set_notifications_bell(true)
              // console.log('set bell')
            }


            // compare dates 

          }
        }
      } catch (error) {
        // set_error_message(`No pending verification requests found`);
      }
    }


    // try {
    //   const get_seller_products = await retrieveProducts('id', user_profile._id, 'farm', 'product_meta', 'sub_category_meta', 'category_meta', 'product_name', '0', 'quantity_units', 'min_price', 'max_price', 'delivery_zone', 'featured', 'on_sale', 'sort_order');

    //   if (get_seller_products.success) {
    //     const { count, data } = get_seller_products;
    //     _products_count = data.length;
    //     if (count > 0) {
    //       for (let i = 0; i < data.length; i++) {
    //         console.log(data[i].updatedAt)
    //         // eslint-disable-next-line 
    //         if ((i + 1) == data.length) {
    //           _products_last_updated = get_display_date(data[i].updatedAt);
    //           _products_last_updated_warning = true;
    //         }
    //       }
    //     }

    //     let _listed_products = []
    //     for (let i = 0; i < data.length; i++) {
    //       const difference_in_time = (new Date()).getTime() - (new Date(data[i].updatedAt)).getTime()
    //       const difference_in_days = difference_in_time / (1000 * 3600 * 24);

    //       // console.log(data[i].longevity, difference_in_days)
    //       if (data[i].longevity < difference_in_days) {
    //         _listed_products.push(data[i])
    //       }
    //     }

    //     set_listed_products(_listed_products)
    //   } else {
    //     // set_error_message(get_seller_products.message);
    //   }

    //   }
    // } catch (error) {
    //   // set_error_message(`An error occured fetching seller orders: ${error.message} `);
    // }




  }

  const update_cart_items_count = async (e) => {
    let cart_items_length = 0;
    // console.log(cartItems);
    for (let i = 0; i < cartItems.length; i++) {
      cart_items_length += cartItems[i].quantity;

    }
    set_cart_items_count(cart_items_length);
  }

  useEffect(() => {
    check_buyer_country()
    update_cart_items_count()
    check_notifications()
    // eslint-disable-next-line 
  }, []);


  // setInterval( function () { 
  //   // console.log('running every minute', new Date());
  //   //   document.addEventListener('click', () => {
  //   //     addNotification({
  //   //       title: 'A new notification',
  //   //       subtitle: 'New sub title',
  //   //       message: 'This is a message of what one should do. Dont forget hitting the link',
  //   //       theme: 'darkblue',
  //   //       duration: 60000, // a minute
  //   //       backgroundTop: 'green',
  //   //       backgroundBottom: 'darkgreen',
  //   //       colorTop: 'green',
  //   //       colorBottom: 'darkgreen',
  //   //       closeButton: 'Close',
  //   //       native: true 
  //   //     });
  //   // });
  // }, 10000); // 60000 for 

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(logout());
  }

  const loggedIn = checkLoggedIn();

  const open_path = window.location.pathname;
  if (open_path === '/become-a-seller') {
    localStorage.setItem('potential_user_type', 'seller');
  } else {
    localStorage.setItem('potential_user_type', '');
  }

  const potential_user_type = localStorage.getItem('potential_user_type');


  const check_buyer_country = async (e) => {
    let buyer_country_meta = localStorage.getItem('buyer_country_meta')
    console.log('buyer country meta 1', buyer_country_meta)
    if (buyer_country_meta === null) {
      // decode location from IP address 
      let buyer_country_found = ''
      await fetch('https://extreme-ip-lookup.com/json/?key=8TpyKTZZNGcnYDjboh3U')
        .then(res => res.json())
        .then(response => {
          console.log('etreme IP lookup results', response)
          buyer_country_found = response.country;
        })
        .catch((data, status) => {
          // console.log('Request failed:', data);
        });

      if (buyer_country_found === '') {
        buyer_country_meta = FFD_DEFAULT_COUNTRY
      } else {
        buyer_country_meta = buyer_country_found.replace(/ /g, '-')
        buyer_country_meta = buyer_country_meta.replace('/', '-')
        buyer_country_meta = buyer_country_meta.replace('(', '-')
        buyer_country_meta = buyer_country_meta.replace(')', '-')
        buyer_country_meta = buyer_country_meta.toLowerCase()
      }

      if (!FFD_COUNTRIES_METAS.includes(buyer_country_meta)) {
        buyer_country_meta = FFD_DEFAULT_COUNTRY
      }

      console.log('buyer country meta 2', buyer_country_meta)

      localStorage.setItem('buyer_country_meta', buyer_country_meta);

      window.location.reload()

    }





  }

  const change_buyer_country = async (new_country) => {
    console.log('new country', new_country)
    if (new_country === undefined) {
      return
    }

    if (new_country === localStorage.getItem('buyer_country_meta')) {
      return
    }

    await localStorage.removeItem('buyer_city');
    await localStorage.setItem('buyer_country_meta', new_country);


    window.location.reload()

  }

  console.log('logged IN', loggedIn)


  return (
    <BrowserRouter>

      <Notifications />
      <header id="header" class="d-flex align-items-center">
        <div class="container d-flex align-items-center">

          <div class="logo me-auto">
            <Link to="/">
              {/* <img src="/assets/img/logo.png" alt="" class="img-fluid" /> */}
              <h2>Farm Fresh Delights</h2>
            </Link>
          </div>

          {loggedIn === '' || loggedIn === null || loggedIn === undefined ? (
            potential_user_type === 'seller' ? (
              <nav id="navbar" class="navbar">
                <ul>
                  <li><Link class="nav-link scrollto" to="/become-a-seller">Home</Link></li>
                  <li><Link class="nav-link scrollto" to="/login-seller">Login</Link></li>
                  <li><Link class="nav-link scrollto" to="/shop">Shop</Link></li>
                  {/* <li><Link class="nav-link scrollto" to="/register-as-seller">Register</Link></li> */}
                  <li><Link class="nav-link scrollto" to="/contact">Contact Us</Link></li>
                  <li><Link class="nav-link scrollto" to="/register-as-seller?redirect=seller-dashboard">Sell</Link></li>

                </ul>
                <i class="bi bi-list mobile-nav-toggle"></i>
              </nav>
            ) : (
              <nav id="navbar" class="navbar">
                <ul>
                  <li><Link class="nav-link scrollto" to="/">Home</Link></li>
                  <li><Link class="nav-link scrollto" to="/shop">Shop</Link></li>
                  {/* <li><Link class="nav-link scrollto" to="/register">Register</Link></li> */}
                  <li><Link class="nav-link scrollto" to="/shop"><i class="bi bi-search"></i></Link></li>
                  <li><Link class="nav-link scrollto" to="/cart"><sup class="d-none d-lg-block"><b>{cartItems.length}</b></sup><i class="bi bi-basket"></i>  </Link></li>
                  <li><Link class="nav-link scrollto" to="/login">Login</Link></li>
                  <li><Link class="nav-link scrollto" to="/contact">Contact Us</Link></li>
                  <li><Link class="nav-link scrollto" to="/register-as-seller?redirect=seller-dashboard">Sell</Link></li>

                  <li class="dropdown">
                    <a href="#">
                      <span>
                        <img class="" src={`/assets/img/flags/${localStorage.getItem('buyer_country_meta')}.png`} style={{ maxHeight: '25px' }} />
                        &nbsp;
                        {FFD_COUNTRIES.map(c => (
                          c.meta === localStorage.getItem('buyer_country_meta') ? (c.country) : ('')
                        ))}
                      </span>
                      <i class="bi bi-chevron-down"></i>
                    </a>
                    <ul>
                      {FFD_COUNTRIES.map(c => (
                        <button class="btn btn-link logout-button" type="submit" onClick={() => { change_buyer_country(c.meta) }}>
                          <>
                            <img class="" src={`/assets/img/flags/${c.meta}.png`} style={{ maxHeight: '25px' }} />
                            &nbsp;{c.country}
                          </>

                        </button>
                      ))}
                      <li>

                      </li>
                    </ul>
                  </li>

                </ul>
                <i class="bi bi-list mobile-nav-toggle"></i>
              </nav>
            )
          ) : (
            // eslint-disable-next-line
            ((loggedIn.roles).includes('seller') && (!(loggedIn.roles).includes('buyer')) || (loggedIn.roles).includes('buyer') && (loggedIn.roles).includes('seller')) ? ( // check if seller
              <nav id="navbar" class="navbar">
                <ul>
                  <li>
                    <Link class="nav-link scrollto" to="/seller-dashboard">
                      My Dashboard
                      {notifications_bell ? (<sup style={{}}><b><i class="bi bi-bell"></i></b></sup>) : (<></>)}
                    </Link>
                  </li>
                  {/* <li><Link class="nav-link scrollto" to="/messages">Messages {messages_count > 0 ? (<>({messages_count})</>):(<></>)}</Link></li> */}
                  <li><Link class="nav-link scrollto" to="/seller-products">My Products</Link></li>
                  <li><Link class="nav-link scrollto" to="/seller-orders">My Orders</Link></li>
                  {/* <li><Link class="nav-link scrollto" to="/seller-requests">My Requests</Link></li> */}
                  <li><Link class="nav-link scrollto" to="/my-purchases">My Purchases</Link></li>
                  <li><Link class="nav-link scrollto" to="/contact">Contact Us</Link></li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <li class="dropdown"><a href="#"><span><i class="bi bi-user-badge"></i> {loggedIn.first_name} {loggedIn.last_name} </span> <i class="bi bi-chevron-down"></i></a>
                    <ul>
                      <li><Link to="/seller-profile">My Profile</Link></li>
                      <li><Link to="/account-settings">Change Password</Link></li>
                      <li>
                        <form onSubmit={submitHandler} >
                          <button class="btn btn-link logout-button" type="submit">Logout</button>
                        </form>
                      </li>
                    </ul>
                  </li>

                  <li class="dropdown">
                    <a href="#">
                      <span>
                        <img class="" src={`/assets/img/flags/${localStorage.getItem('buyer_country_meta')}.png`} style={{ maxHeight: '25px' }} />
                        &nbsp;
                        {FFD_COUNTRIES.map(c => (
                          c.meta === localStorage.getItem('buyer_country_meta') ? (c.country) : ('')
                        ))}
                      </span>
                      <i class="bi bi-chevron-down"></i>
                    </a>
                    <ul>
                      {FFD_COUNTRIES.map(c => (
                        <button class="btn btn-link logout-button" type="submit" onClick={() => { change_buyer_country(c.meta) }}>
                          <>
                            <img class="" src={`/assets/img/flags/${c.meta}.png`} style={{ maxHeight: '25px' }} />
                            &nbsp;{c.country}
                          </>

                        </button>
                      ))}
                      <li>

                      </li>
                    </ul>
                  </li>
                </ul>
                <i class="bi bi-list mobile-nav-toggle"></i>
              </nav>
            )
              :
              (loggedIn.roles).includes('buyer') && (!(loggedIn.roles).includes('seller')) ? ( // check if buyer
                <nav id="navbar" class="navbar">
                  <ul>
                    <li>
                      <Link class="nav-link scrollto" to="/buyer-dashboard">
                        My Dashboard
                        {notifications_bell ? (<sup style={{}}><b><i class="bi bi-bell"></i></b></sup>) : (<></>)}

                      </Link>
                    </li>
                    {/* <li><Link class="nav-link scrollto" to="/messages">Messages {messages_count > 0 ? (<>({messages_count})</>):(<></>)}</Link></li> */}
                    <li><Link class="nav-link scrollto" to="/shop">Shop</Link></li>

                    <li><Link class="nav-link scrollto" to="/shop"><i class="bi bi-search"></i></Link></li>

                    <li><Link class="nav-link scrollto" to="/cart"><sup><b>{cartItems.length}</b></sup><i class="bi bi-basket"></i>  </Link></li>
                    <li><Link class="nav-link scrollto" to="/my-purchases">My Purchases</Link></li>
                    <li><Link class="nav-link scrollto" to="/my-requests">My Requests</Link></li>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <li class="dropdown"><a href="#"><span><i class="bi bi-user-badge"></i> {loggedIn.first_name} {loggedIn.last_name}</span> <i class="bi bi-chevron-down"></i></a>
                      <ul>
                        <li><Link to="/seller-profile">My Profile</Link></li>
                        <li><Link to="/account-settings">Change Password</Link></li>
                        <li>
                          <form onSubmit={submitHandler} >
                            <button class="btn btn-link logout-button" type="submit">Logout</button>
                          </form>
                        </li>
                      </ul>
                    </li>

                    <li class="dropdown">
                      <a href="#">
                        <span>
                          <img class="" src={`/assets/img/flags/${localStorage.getItem('buyer_country_meta')}.png`} style={{ maxHeight: '25px' }} />
                          &nbsp;
                          {FFD_COUNTRIES.map(c => (
                            c.meta === localStorage.getItem('buyer_country_meta') ? (c.country) : ('')
                          ))}
                        </span>
                        <i class="bi bi-chevron-down"></i>
                      </a>
                      <ul>
                        {FFD_COUNTRIES.map(c => (
                          <button class="btn btn-link logout-button" type="submit" onClick={() => { change_buyer_country(c.meta) }}>
                            <>
                              <img class="" src={`/assets/img/flags/${c.meta}.png`} style={{ maxHeight: '25px' }} />
                              &nbsp;{c.country}
                            </>

                          </button>
                        ))}
                        <li>

                        </li>
                      </ul>
                    </li>
                  </ul>
                  <i class="bi bi-list mobile-nav-toggle"></i>
                </nav>
              )
                :
                (loggedIn.roles).includes('buyer') && (!(loggedIn.roles).includes('seller')) ? ( // check if buyer
                  <nav id="navbar" class="navbar">
                    <ul>
                      <li><Link class="nav-link scrollto" to="/">Home</Link></li>
                      <li><Link class="nav-link scrollto" to="/shop">Shop</Link></li>
                      <li><Link class="nav-link scrollto" to="/contact">Contact Us</Link></li>
                      <li><Link class="nav-link scrollto" to="/register-as-seller?redirect=seller-dashboard">Sell</Link></li>
                      <li><Link class="nav-link scrollto" to="/shop"><i class="bi bi-search"></i></Link></li>
                      <li><Link class="nav-link scrollto" to="/cart"><sup><b>{cartItems.length}</b></sup><i class="bi bi-basket"></i>  </Link></li>
                      <li><Link class="nav-link scrollto" to="/my-purchases">My Purchases</Link></li>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <li class="dropdown"><a href="#"><span><i class="bi bi-user-badge"></i> {loggedIn.first_name} {loggedIn.last_name}</span> <i class="bi bi-chevron-down"></i></a>
                        <ul>
                          <li><Link to="/seller-profile">My Profile</Link></li>
                          <li><Link to="/account-settings">Change Password</Link></li>
                          <li>
                            <form onSubmit={submitHandler} >
                              <button class="btn btn-link logout-button" type="submit">Logout</button>
                            </form>
                          </li>
                        </ul>
                      </li>

                      <li class="dropdown">
                        <a href="#">
                          <span>
                            <img class="" src={`/assets/img/flags/${localStorage.getItem('buyer_country_meta')}.png`} style={{ maxHeight: '25px' }} />
                            &nbsp;
                            {FFD_COUNTRIES.map(c => (
                              c.meta === localStorage.getItem('buyer_country_meta') ? (c.country) : ('')
                            ))}
                          </span>
                          <i class="bi bi-chevron-down"></i>
                        </a>
                        <ul>
                          {FFD_COUNTRIES.map(c => (
                            <button class="btn btn-link logout-button" type="submit" onClick={() => { change_buyer_country(c.meta) }}>
                              <>
                                <img class="" src={`/assets/img/flags/${c.meta}.png`} style={{ maxHeight: '25px' }} />
                                &nbsp;{c.country}
                              </>

                            </button>
                          ))}
                          <li>

                          </li>
                        </ul>
                      </li>
                    </ul>
                    <i class="bi bi-list mobile-nav-toggle"></i>
                  </nav>
                )
                  :
                  (loggedIn.roles).includes('sales-support') ? ( // check if sale-support
                    <nav id="navbar" class="navbar">
                      <ul>
                        <li><Link class="nav-link scrollto" to="/buyer-dashboard">My Dashboard<sup style={{}}><b><i class="bi bi-bell"></i></b></sup></Link></li>
                        {/* eslint-disable-next-line */}
                        <li class="dropdown"><a href="#"><span><i class="bi bi-user-badge"></i> Orders</span> <i class="bi bi-chevron-down"></i></a>
                          <ul>
                            <li><Link to="/sales-orders/Pending">Pending orders</Link></li>
                            <li><Link to="/sales-orders/Confirmed">Confirmed orders</Link></li>
                            <li><Link to="/sales-orders/Rejected">Rejected orders</Link></li>
                          </ul>
                        </li>
                        <li><Link class="nav-link scrollto" to="/admin-products">All products</Link></li>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <li class="dropdown"><a href="#"><span><i class="bi bi-user-badge"></i> {loggedIn.first_name} {loggedIn.last_name}</span> <i class="bi bi-chevron-down"></i></a>
                          <ul>
                            <li><Link to="/admin-profile">My Profile</Link></li>
                            <li><Link to="/account-settings">Change Password</Link></li>
                            <li>
                              <form onSubmit={submitHandler} >
                                <button class="btn btn-link logout-button" type="submit">Logout</button>
                              </form>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <i class="bi bi-list mobile-nav-toggle"></i>
                    </nav>
                  )
                    :
                    (loggedIn.roles).includes('technical-support') ? ( // check if technical support
                      <nav id="navbar" class="navbar">
                        <ul>
                          <li>
                            <Link class="nav-link scrollto" to="/seller-dashboard">
                              My Dashboard
                              {notifications_bell ? (<sup style={{}}><b><i class="bi bi-bell"></i></b></sup>) : (<></>)}

                            </Link>
                          </li>
                          <li><Link class="nav-link scrollto" to="/categories">Categories</Link></li>
                          <li><Link class="nav-link scrollto" to="/product-types">Product types</Link></li>
                          <li><Link class="nav-link scrollto" to="/admin-products">Products</Link></li>
                          <li><Link class="nav-link scrollto" to="/currencies-and-rates">Rates</Link></li>
                          <li><Link class="nav-link scrollto" to="/cities">Cities</Link></li>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <li class="dropdown"><a href="#"><span><i class="bi bi-user-badge"></i> {loggedIn.first_name} {loggedIn.last_name}</span> <i class="bi bi-chevron-down"></i></a>
                            <ul>
                              <li><Link to="/admin-profile">My Profile</Link></li>
                              <li><Link to="/account-settings">Change Password</Link></li>
                              <li>
                                <form onSubmit={submitHandler} >
                                  <button class="btn btn-link logout-button" type="submit">Logout</button>
                                </form>
                              </li>
                            </ul>
                          </li>
                        </ul>
                        <i class="bi bi-list mobile-nav-toggle"></i>
                      </nav>
                    ) : (loggedIn.roles).includes('verifier') ? ( // check if sale-support
                      <nav id="navbar" class="navbar">
                        <ul>
                          <li>
                            <Link class="nav-link scrollto" to="/verifier-dashboard">
                              My Dashboard
                              {/* <sup style={{}}><b><i class="bi bi-bell"></i></b></sup> */}
                            </Link>
                          </li>

                          <li>
                            <Link class="nav-link scrollto" to="/pending-verification-requests">
                              Verify
                              {/* <sup style={{}}><b><i class="bi bi-bell"></i></b></sup> */}
                            </Link>
                          </li>

                          <li>
                            <Link class="nav-link scrollto" to="/notifications">
                              Messages
                              {notifications_bell ? (<sup style={{}}><b><i class="bi bi-bell"></i></b></sup>) : (<></>)}
                            </Link>
                          </li>

                          <li>
                            <Link class="nav-link scrollto" to="/verifications-history">
                              History
                              {/* <sup style={{}}><b><i class="bi bi-bell"></i></b></sup> */}
                            </Link>
                          </li>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <li class="dropdown"><a href="#"><span><i class="bi bi-user-badge"></i> {loggedIn.first_name} {loggedIn.last_name}</span> <i class="bi bi-chevron-down"></i></a>
                            <ul>
                              <li><Link to="/admin-profile">My Profile</Link></li>
                              <li><Link to="/account-settings">Change Password</Link></li>
                              <li>
                                <form onSubmit={submitHandler} >
                                  <button class="btn btn-link logout-button" type="submit">Logout</button>
                                </form>
                              </li>
                            </ul>
                          </li>
                        </ul>
                        <i class="bi bi-list mobile-nav-toggle"></i>
                      </nav>
                    )
                      :
                      (loggedIn.roles).includes('admin') ? ( // check if admin
                        <nav id="navbar" class="navbar">
                          <ul>
                            <li><Link class="nav-link scrollto" to="/admin-dashboard">Dashboard</Link></li>


                            <li class="dropdown"><a href="#"><span><i class="bi bi-user-badge"></i> Users</span> <i class="bi bi-chevron-down"></i></a>
                              <ul>
                                <li><Link to="/staff-members">Staff</Link></li>
                                <li><Link to="/sellers">Sellers</Link></li>
                                <li><Link to="/buyers">Buyers</Link></li>
                              </ul>
                            </li>

                            {/* eslint-disable-next-line */}
                            <li class="dropdown"><a href="#"><span><i class="bi bi-user-badge"></i> Orders</span> <i class="bi bi-chevron-down"></i></a>
                              <ul>
                                <li><Link to="/sales-orders/Pending">Pending orders</Link></li>
                                <li><Link to="/sales-orders/Confirmed">Confirmed orders</Link></li>
                                <li><Link to="/sales-orders/Rejected">Rejected orders</Link></li>
                              </ul>
                            </li>

                            <li><Link class="nav-link scrollto" to="/admin-seller-verifications-history">Seller verifications</Link></li>


                            {/* eslint-disable-next-line */}
                            {/* <li class="dropdown"><a href="#"><span><i class="bi bi-user-badge"></i> Seller Verifications</span> <i class="bi bi-chevron-down"></i></a>
                            <ul>
                              <li><Link to="/admin-seller-verifications-history">Completed</Link></li>
                            </ul>
                          </li> */}



                            {/* eslint-disable-next-line */}
                            <li class="dropdown"><a href="#"><span><i class="bi bi-user-badge"></i> Products</span> <i class="bi bi-chevron-down"></i></a>
                              <ul>
                                <li><Link to="/admin-products">Listed products</Link></li>
                                <li><Link to="/buyer-requested-products">Buyer requested products</Link></li>
                                <li><Link to="/seller-requested-products">Seller requested products</Link></li>
                              </ul>
                            </li>


                            {/* eslint-disable-next-line */}
                            <li class="dropdown"><a href="#"><span><i class="bi bi-user-badge"></i> Configuration</span> <i class="bi bi-chevron-down"></i></a>
                              <ul>
                                <li><Link to="/categories">Categories</Link></li>
                                <li><Link to="/product-types">Product database</Link></li>
                                <li><Link to="/currencies-and-rates">Rates</Link></li>
                                <li><Link to="/countries">Countries, Cities & Suburbs</Link></li>
                                <li><Link to="/upload-countries">Upload Countries, Cities & Suburbs Files</Link></li>
                              </ul>
                            </li>


                            <li><Link class="nav-link scrollto" to="/admin-help">Help</Link></li>





                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <li class="dropdown"><a href="#"><span><i class="bi bi-user-badge"></i> {loggedIn.first_name} {loggedIn.last_name}</span> <i class="bi bi-chevron-down"></i></a>
                              <ul>
                                <li><Link to="/admin-profile">My Profile</Link></li>
                                <li><Link to="/account-settings">Change Password</Link></li>
                                <li>
                                  <form onSubmit={submitHandler} >
                                    <button class="btn btn-link logout-button" type="submit">Logout</button>
                                  </form>
                                </li>
                              </ul>
                            </li>
                          </ul>
                          <i class="bi bi-list mobile-nav-toggle"></i>
                        </nav>
                      )
                        :
                        ('') // the default. which is nothing              
          )}

        </div>
      </header>



      <main id="main">
        {/* <Router history={browserHistory}> */}
        <Switch>
          {/* general         */}
          <Route path="/" exact={true} component={ShopScreen} /> {/* HomeScreen when we decide to get back the HomePage */}

          <Route path="/page-not-found" exact={true} component={Error404Screen} />
          <Route path="/access-forbidden" component={Error403Screen} />

          {/* seller auth, settings and profile */}
          <Route path="/become-a-seller" component={SellerLandingScreen} />
          <Route path="/register-as-seller" component={RegisterSellerScreen} />
          <Route path="/register" component={RegisterScreen} />
          <Route path="/login-seller" component={LoginSellerScreen} />
          {/* <Route path="/login" component={SigninScreen} /> */}
          <Route path="/login" component={LoginSellerScreen} />
          <Route path="/forgot-password" component={ForgotPasswordScreen} />
          <Route path="/reset-password/:token" component={ResetPasswordScreen} />
          <Route path="/seller-profile" component={SellerProfileScreen} />
          <Route path="/seller-dashboard" component={SellerDashboardScreen} />
          <Route path="/buyer-dashboard" component={BuyerDashboardScreen} />
          <Route path="/verify/:token" component={VerifyAccountScreen} />
          <Route path="/account-settings" component={AccountSettingsScreen} />

          {/* seller products  */}
          <Route path="/seller-products" component={SellerProductsScreen} />
          <Route path="/add-produce" component={SellerAddProductsScreen} />
          <Route path="/seller-product/:id" component={SellerProductScreen} />
          <Route path="/seller-order/:id" component={SellerOrderScreen} />
          <Route path="/seller-orders/:status?" component={SellerOrdersScreen} />
          <Route path="/seller-inventory-alerts" component={SellerInventoryAlertsScreen} />
          <Route path="/seller-sales/:period?" component={SellerSalesScreen} />
          <Route path="/seller-perfomance-summary" component={SellerPerfomanceSummaryScreen} />
          <Route path="/seller-case-logs" component={SellerCaseLogsScreen} />
          <Route path="/seller-order-invitation/:id" component={SellerOrderInvitationScreen} />
          <Route path="/seller-accept-invitation/:id" component={SellerOrderAcceptScreen} />
          <Route path="/seller-decline-invitation/:id" component={SellerOrderDeclineScreen} />

          <Route path="/shop/:link_sub_category?/:search_term?" component={ShopScreen} />
          <Route path="/store/:username" component={SellerShopScreen} />
          <Route path="/resources" component={ResourcesScreen} />
          <Route path="/orders/:status/:order_id/:payment_failed_reason?" component={OrderScreen} />
          <Route path="/payment/:gateway/:status/:order_id/:result_text?" component={PaymentScreen} />
          <Route path="/track/:tracking_number/:order_id?" component={TrackScreen} />
          <Route path="/pay/:order_id" component={PayScreen} />
          <Route path="/contact/:category?/:order_id?" component={ContactScreen} />
          <Route path="/product/:meta/:id" component={ProductScreen} />
          <Route path="/order-product/:meta/:id" component={OrderProductScreen} />

          <Route path="/login-admin" component={LoginAdminScreen} />
          <Route path="/staff-members" component={StaffMembersScreen} />
          <Route path="/add-staff-member" component={AddStaffMemberScreen} />
          <Route path="/staff-member/:id" component={ViewStaffMemberScreen} />
          <Route path="/seller/:id" component={AdminSellerScreen} />
          <Route path="/categories" component={CategoriesScreen} />
          <Route path="/add-category" component={AddCategoryScreen} />
          <Route path="/category/:id" component={ViewCategoryScreen} />
          <Route path="/product-types" component={ProductTypesScreen} />
          <Route path="/product-type/:id" component={ViewProductTypeScreen} />
          <Route path="/add-product-type" component={AddProductTypeScreen} />
          <Route path="/currencies-and-rates" component={CurrenciesAndRatesScreen} />
          <Route path="/countries" component={CountriesScreen} />
          <Route path="/upload-countries" component={CountriesUploadScreen} />
          <Route path="/sales-orders/:status?" component={AdminOrdersScreen} />
          <Route path="/sales-order/:id" component={AdminOrderScreen} />
          <Route path="/sales-order-item/:id" component={AdminOrderItemScreen} />
          <Route path="/admin-products" component={AdminProductsScreen} />
          <Route path="/admin-product/:id" component={AdminProductScreen} />
          <Route path="/admin-dashboard" component={AdminDashboardScreen} />

          <Route path="/cart" component={CartScreen} />
          <Route path="/checkout" component={CheckoutScreen} />

          <Route path="/login" component={SigninScreen} />

          <Route path="/terms-and-conditions" component={TermsScreen} />
          <Route path="/privacy-policy" component={PrivacyScreen} />

          <Route path="/how-it-works" component={HowItWorksScreen} />
          <Route path="/frequently-asked-questions" component={FaqScreen} />
          <Route path="/product-listing-policy" component={ListingPolicyScreen} />
          <Route path="/about-us" component={AboutScreen} />
          <Route path="/refund-policy" component={RefundPolicyScreen} />

          <Route path="/my-purchases" component={MyPurchasesScreen} />
          <Route path="/my-requests" component={MyRequestsScreen} />
          <Route path="/seller-requests" component={SellerRequestsScreen} />
          <Route path="/sellers" component={SellersScreen} />
          <Route path="/buyers" component={BuyersScreen} />

          <Route path="/general-safety-advice" component={GeneralSafetyAdviceScreen} />

          <Route path="/specify-new-product/:supplied_name" component={SpecifyNewProductScreen} />
          <Route path="/seller-requested-products" component={SellerRequestedProductsScreen} />
          <Route path="/buyer-requested-products" component={BuyerRequestedProductsScreen} />

          <Route path="/admin-help" component={AdminHelpScreen} />

          <Route path="/buyer-profile" component={BuyerProfileScreen} />

          <Route path="/verifier-dashboard" component={VerifierDashboardScreen} />
          <Route path="/pending-verification-requests" component={PendingVerificationRequestsScreen} />
          <Route path="/verifications-history" component={CompletedVerificationRequestsScreen} />
          <Route path="/verify-seller/:id" component={VerifyFarmerScreen} />
          <Route path="/pay-seller-verifier/:id" component={PaySellerVerifierScreen} />
          <Route path="/seller-verification-details/:id" component={SellerVerificationDetailsScreen} />
          <Route path="/admin-profile" component={AdminProfileScreen} />
          <Route path="/notifications" component={VerifierMessagesScreen} />
          <Route path="/admin-seller-verifications-history" component={AdminSellerVerificationsHistoryScreen} />

          <Route path="/buyer-order/:id" component={BuyerOrderScreen} />

          <Route path="/messages/:order" component={MessagesScreen} />

          <Route component={Error404Screen} />
        </Switch>

        {/* </Router> */}


      </main>


      <footer id="footer">
        <div class="container">
          <div class="copyright footer-links">
            <Link to="/terms-and-conditions">
              Terms &amp; Conditions
            </Link>

            <Link to="/privacy-policy">
              Privacy Policy
            </Link>

            <Link to="/about-us">
              About Us
            </Link>

            <Link to="/shop">
              Shop
            </Link>

            <Link to="/how-it-works">
              How it works
            </Link>

            <Link to="/contact">
              Contact Us
            </Link>
          </div>
          <div class="copyright">
            {/* eslint-disable-next-line */}
            &copy; {(new Date()).getFullYear()}. Farm Fresh Delights
          </div>
        </div>
      </footer>

      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>

      <CookieConsent
        location="bottom"
        buttonText="I accept"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#fff", fontSize: "13px", backgroundColor: "#188802" }}
        expires={150}
      >
        This website uses cookies. By continuing to use this site, you agree to our <b><Link to="/terms-and-conditions">terms of service</Link></b> and our <b><Link to="/privacy-policy">privacy policy</Link></b>. <b><Link to="/terms-and-conditions">Learn more</Link></b>.{" "}
        {/* <span style={{ fontSize: "10px" }}>To remove this message, click on the I accept button.</span> */}
      </CookieConsent>

    </BrowserRouter>
  );
}

export default App;
