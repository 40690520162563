import React, { useEffect, useState } from 'react';
import { addToCart, removeFromCart } from '../actions/cartActions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { API_URL } from '../config';

function CartScreen(props) {

  const cart = useSelector(state => state.cart);

  const { cartItems } = cart;
  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);
  // eslint-disable-next-line
  const [error_message, set_error_message] = useState('');
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState('');

  // const productId = props.match.params.id;
  // const qty = props.location.search ? Number(props.location.search.split("=")[1]) : 1;
  const dispatch = useDispatch();
  const removeFromCartHandler = (productId) => {
    dispatch(removeFromCart(productId));
  }
  useEffect(() => {
    // if (productId) {
    //   dispatch(addToCart(productId, qty));
    // }
  }, []);

  const checkoutHandler = () => {
    props.history.push("/checkout");
  }

  return <section id="about" class="about main-content mt-5 login-content">
  <div class="container-fluid" data-aos="fade-up">
    <div class="row mt-5">
      {/* <div class="col-md-4"></div> */}
      <div class="col-md-12 text-center">
        <h3 class="green-text">Shopping Cart</h3>
        {error_message !== '' ? (
        <div class="col-12">
          <div class="alert alert-danger" role="alert">
            {error_message}
          </div>
        </div>): ('')}

        {success_message !== '' ? (
        <div class="col-12">
          <div class="alert alert-success" role="alert">
            {success_message}
          </div>
        </div>): ('')}
      </div>
      
      <div class="col-md-12">
        
          {
          cartItems.length === 0 ?
            <div>
              Your shopping cart is empty. <Link to="/shop">Shop now</Link>
            </div>
            :
            (
              <>
              <Link to="/shop">
                <button class="btn btn-primary" >
                <i class="bi bi-arrow-left"></i>
                &nbsp;Return to shopping
                </button>
              </Link>              
              <br /><br />

            <div class="table-responsive">
              <table class="table table-bordered">
                {cartItems.map(item =>
                  <tr class="cart-item-row">
                    
                    <td>
                      <select class="add-to-quantity" value={item.quantity} onChange={(e) => dispatch(addToCart(item.product, e.target.value))} data-toggle="tooltip" title="Click to update quantity">
                        {[...Array(item.quantity_in_stock).keys()].map(x =>
                          <option key={x + 1} value={x + 1}>{x + 1}</option>
                        )}
                      </select>
                      &nbsp;{item.units}
                    </td>
                    
                    <td>
                    {/* ${item.image} */}
                      <img class="cart-image" src={`${API_URL}/${item.image}`} alt={`${item.name}`} />
                    </td>
                    <td>
                      <Link to={`/product/${item.product_meta}/${item.product}`}>
                        {item.name}
                      </Link>
                    </td>
                    <td>
                      US${parseFloat(item.price).toFixed(2)}
                    </td>
                    <td>
                      US${(parseFloat(item.quantity) * parseFloat(item.price)).toFixed(2)}
                    </td>
                    
                    <td>
                    <button type="button" class="btn btn-link remove-cart-item" onClick={() => removeFromCartHandler(item.product)} >
                      <i class="bi bi-x-square"></i> Remove item
                    </button>
                    </td>
                    
                  </tr>
            )}
            <tr>
              {/* <th>Total</th> */}
                      <th>{cartItems.length} items</th>
              
              <th></th>
              <th></th>
              <th></th>
              <th>

                US$ {(cartItems.reduce((a, c) => a + c.price * c.quantity, 0)).toFixed(2)}
                
              </th>
              <th></th>
            </tr>

            </table>
            </div>

            <button onClick={checkoutHandler} class="btn btn-primary" disabled={cartItems.length === 0}>
                  Complete purchase
                </button>
            
            </>
            )
        }
      </div> 

      <div class="col-md-4">
        
      </div>
         
      
  </div>
  </div>
</section> 
}

export default CartScreen;