export const CATEGORIES_LIST_REQUEST = 'CATEGORIES_LIST_REQUEST';
export const CATEGORIES_LIST_SUCCESS = 'CATEGORIES_LIST_SUCCESS';
export const CATEGORIES_LIST_FAIL = 'CATEGORIES_LIST_FAIL';

export const CATEGORIES_DETAILS_REQUEST = 'CATEGORIES_DETAILS_REQUEST';
export const CATEGORIES_DETAILS_SUCCESS = 'CATEGORIES_DETAILS_SUCCESS';
export const CATEGORIES_DETAILS_FAIL = 'CATEGORIES_DETAILS_FAIL';

export const CATEGORIES_SAVE_REQUEST = 'CATEGORIES_SAVE_REQUEST';
export const CATEGORIES_SAVE_SUCCESS = 'CATEGORIES_SAVE_SUCCESS';
export const CATEGORIES_SAVE_FAIL = 'CATEGORIES_SAVE_FAIL';

export const CATEGORIES_DELETE_REQUEST = 'CATEGORIES_DELETE_REQUEST';
export const CATEGORIES_DELETE_SUCCESS = 'CATEGORIES_DELETE_SUCCESS';
export const CATEGORIES_DELETE_FAIL = 'CATEGORIES_DELETE_FAIL';
