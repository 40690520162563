import React, { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
import { forgotPassword, updatePassword } from '../actions/userActions';
import jwt_decode from "jwt-decode";
// import { Textbox } from 'react-inputs-validation';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';


function ResetPasswordScreen(props) {
  const [email, setEmail] = useState('');

  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');

  const [action_loading, set_action_loading] = useState(false);

  const [enter_email_again, set_enter_email_again] = useState(false);

  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');

  // const dispatch = useDispatch();
  const token = props.match.params.token ? props.match.params.token : '';
  
  // eslint-disable-next-line
  useEffect(() => {
      if (action_loading === false) {
        set_action_loading(true);
        checkSuppliedToken();
      } 
      // eslint-disable-next-line       
  });

  /**
   * Checks if a supplied token in link can reset password and is valid.
   */
  const checkSuppliedToken = async () =>  {
    set_action_loading(true);
    try {
      await jwt_decode(token);
    } catch (error) {
      set_error_message('Sorry the token is no longer valid. Please enter your email address again to reset your password.'); 
      set_enter_email_again(true);
      set_action_loading(false);
      return;
    }
    
    set_action_loading(false);
    return; 
  }

  /**
   * Sends another email with token to reset password
   * @param {*} e 
   */
  const resendToken = async (e) =>  {
    e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);
    const response = await forgotPassword(email); 
    if (response.success === false) {
        set_error_message(response.message); 
        
    } else {
        set_success_message(response.message);
    }
    set_action_loading(false);

    return; 
  }

  /**
   * Updates password for usersthat had a valid token and have entered new passwords.
   * @param {*} e 
   */
  const updateUserPassword = async (e) =>  {
    e.preventDefault();    

    set_success_message('Please wait whilst we update your password');
    set_error_message('');
    set_action_loading(true);

    if (password !== rePassword) {
      set_success_message('');
      set_error_message('Your two passwords do not match. Please try again.');
      set_action_loading(false);
      return;
    }
    

    // console.log('screen', password, token)
    const response = await updatePassword(password, token); 
    if (response.success === false) {
      set_success_message('');
      set_error_message(response.message); 
        
    } else {
        set_success_message(response.message);
    }
    set_action_loading(false);

    // return; 
  }

  return <section id="about" class="about main-content mt-5 login-content">
    <div class="container-fluid" data-aos="fade-up">
      <div class="row mt-5">
        {/* <div class="col-md-4"></div> */}
        <div class="col-md-12 text-center">
          <h3 class="green-text">RESET PASSWORD</h3>
          {error_message !== '' ? (
          <div class="col-12">
            <div class="alert alert-danger" role="alert">
              {error_message}
            </div>
          </div>): ('')}

          {success_message !== '' ? (
          <div class="col-12">
            <div class="alert alert-success" role="alert">
              {success_message}
            </div>
          </div>): ('')}
        </div>
        {/* <div class="col-md-4"></div> */}
           
        
    </div>

    <div class="row mt-5">
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center">
        {enter_email_again ? (
          <>
            <form onSubmit={resendToken} >
              <div class="form-group my-4">
                <input class="form-control" type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
              </div>
              <div class="form-group my-4">
                <button type="submit" class="btn btn-primary" disabled={action_loading}>
                {
                  !action_loading ? ('Reset Password'):(
                    <>
                      <div class="spinner-border text-orange" role="status">
                      </div> 
                      &nbsp;Please wait
                    </>
                  )
                }
                </button>
              </div>
            </form>
          </>
          ): (
            <>
              <form onSubmit={updateUserPassword} >
                <div class="form-group my-4">
                  <input class="form-control" type="password" id="password" name="password" onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                </div>
              <div class="form-group my-4">
                <input class="form-control" type="password" id="rePassword" name="rePassword" onChange={(e) =>  setRePassword(e.target.value) } placeholder="Confirm Password" />
                {password !== rePassword ? (<small id="emailHelp" class="form-text text-danger">Your two passwords do not match</small>):('')}                
              </div>
                <div class="form-group my-4">
                  {password !== rePassword ? (
                    <button 
                      type="submit" 
                      class="btn btn-primary" 
                      disabled={true}
                    >
                      Change password
                    </button>
                  ):(
                    <button 
                      type="submit" 
                      class="btn btn-primary" 
                      disabled={action_loading}
                    >
                      {!action_loading ? ('Change password'):(
                          <>
                            <div class="spinner-border text-orange" role="status">
                            </div> 
                            &nbsp;Please wait
                          </>
                        )
                      }
                    </button>
                  ) }
              </div>
            </form>
            </>
          )}
        </div>
        <div class="col-md-4"></div>
    </div>
    </div>
  </section> 
}
export default ResetPasswordScreen;