import React, { useState, useEffect } from 'react';
import { validate_role } from '../validations';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { getOrderItems, getOrdersV2, updateOrder, createRecurringOrder, getRecurringOrders, updateRecurringOrder } from '../actions/orderActions';
import { retrieveProducts, getInventoryAlerts } from '../actions/productActions';
import { retrieveBuyerProducts, updateBuyerRequestedProduct} from '../actions/productActions';
import { get_display_date } from '../config';
import { Link } from 'react-router-dom';
import { getProducts } from '../actions/productActions';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getCategories, getProductTypes } from '../actions/categoryActions';
import stringSimilarity from "string-similarity"

function BuyerDashboardScreen(props) {
  let user_profile;
  const permitted_role = 'buyer';
  const check_permissions = validate_role(permitted_role);
  if (check_permissions.permitted === false) {
    let login_link = 'login';
    if (permitted_role === 'buyer') login_link = 'access-forbidden'
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  const [add_recurring_order_dialog, set_add_recurring_order_dialog] = React.useState(false);

  const add_recurring_order_dialog_open = () => {
    set_add_recurring_order_dialog(true);
  };

  const add_recurring_order_dialog_close = () => {
    set_add_recurring_order_dialog(false);
  };

  const [remove_recurring_item_dialog, set_remove_recurring_item_dialog] = React.useState(false);
  const [remove_recurring_purchase_item, set_remove_recurring_purchase_item] = useState({});

  const remove_recurring_item_dialog_open = (_item) => {
    // console.log(_item)
    set_remove_recurring_purchase_item(_item)
    set_remove_recurring_item_dialog(true)
  };

  const remove_recurring_item_dialog_close = () => {
    set_remove_recurring_item_dialog(false);
  };

  const link_cell_formatter = (cell, row) => {
    return (
      <div>
        <button class="btn btn-lg" onClick={() => remove_recurring_item_dialog_open(row)}>Cancel</button>
        {/* <Link to={`${cell}/${row.age}`}>{cell}</Link> */}
      </div>);
  }

  const [search_product_found, set_search_product_found] = useState(false);
  const [alternatively_search_product, set_alternatively_search_product] = useState(false);

  const [searched_product, set_searched_product] = useState('');
  const [possible_search_alternatives, set_possible_search_alternatives] = useState([]);

  // eslint-disable-next-line 
  const [error_message, set_error_message] = useState('');
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState('');
  const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);

  const [new_orders, set_new_orders] = useState([]);


  const [buyer_requested, set_buyer_requested] = useState([]);

  const [all_products, set_all_products] = useState([]);


  const [recurring_orders, set_recurring_orders] = useState([]);

  const [all_categories, set_all_categories] = useState([]);
  // eslint-disable-next-line
  const [all_product_types, set_all_product_types] = useState([]);
  const [all_product_types_in_alphabetical_order, set_all_product_types_in_alphabetical_order] = useState([]);

  const [display_sub_categories, set_display_sub_categories] = useState([]);
  // eslint-disable-next-line
  const [display_product_types, set_display_product_types] = useState([]);
  const [display_units, set_display_units] = useState([]);

  const [add_category, set_add_category] = useState('');
  const [add_sub_category, set_add_sub_category] = useState('');
  const [add_product, set_add_product] = useState('');
  const [add_product_id, set_add_product_id] = useState('');
  const [add_product_name, set_add_product_name] = useState('');
  // eslint-disable-next-line
  const [add_description, set_add_description] = useState('');
  const [add_units, set_add_units] = useState('');
  const [add_quantity, set_add_quantity] = useState('');
  const [add_repeat_period, set_add_repeat_period] = useState('daily');
  const [repeat_periods, set_repeat_periods] = useState(
    ['daily', 'weekly', 'fortnightly', 'monthly']
  );


  const [active_orders, set_active_orders] = useState('');
  const [products_count, set_products_count] = useState('');
  const [products_last_updated, set_products_last_updated] = useState('');
  const [products_last_updated_warning, set_products_last_updated_warning] = useState(false);
  // eslint-disable-next-line
  const [inventory_alerts_count, set_inventory_alerts_count] = useState('');
  // eslint-disable-next-line
  const [inventory_alerts_warning, set_inventory_alerts_warning] = useState(false);
  // eslint-disable-next-line
  const [inventory_alerts_text, set_inventory_alerts_text] = useState('');
  const [sales_history_value, set_sales_history_value] = useState('');
  // eslint-disable-next-line
  const [perfomance_summary_rating, set_perfomance_summary_rating] = useState('');
  // eslint-disable-next-line
  const [perfomance_summary_rating_last_update, set_perfomance_summary_rating_last_update] = useState('');
  // eslint-disable-next-line
  const [case_logs, set_case_logs] = useState('');


  const category_changed = (new_category) => {
    let _display_sub_categories = [];
    let _display_product_types = [];

    // console.log(new_category, new_category)

    set_add_category(new_category);
    set_add_sub_category('');

    if (new_category !== '') {
      for (let i = 0; i < all_categories.length; i++) {
        if (all_categories[i]._id === new_category) {
          _display_sub_categories = all_categories[i].sub_categories;
        }
      }

      for (let i = 0; i < all_product_types.length; i++) {
        if (all_product_types[i].category_id === new_category) {
          _display_product_types.push(all_product_types[i]);
        }
      }
    }

    set_display_sub_categories(_display_sub_categories);
    set_display_product_types(_display_product_types);
  }

  const sub_category_changed = (new_sub_category) => {
    let _display_product_types = [];

    set_add_sub_category(new_sub_category);

    if (new_sub_category !== '') {
      for (let i = 0; i < all_product_types.length; i++) {
        if (all_product_types[i].sub_category_meta === new_sub_category) {
          _display_product_types.push(all_product_types[i]);
        }
      }
    }

    set_display_product_types(_display_product_types);
  }

  const product_type_changed = (new_product_type) => {
    let _display_units = [];

    set_add_product(new_product_type);

    // console.log(new_product_type)

    if (new_product_type !== '') {
      for (let i = 0; i < all_product_types.length; i++) {
        if (all_product_types[i].product_meta === new_product_type) {
          set_add_product_id(all_product_types[i]._id)
          set_add_product_name(all_product_types[i].product)
          // console.log('found')
          // console.log(all_product_types[i]._id, all_product_types[i].units);
          _display_units = all_product_types[i].units;
        }
      }
    }

    // console.log('display units', _display_units)

    set_display_units(_display_units);
  }


  const product_type_changed_with_search = (new_product_type) => {
    let _display_units = [];

    set_add_product(new_product_type)
    set_possible_search_alternatives([])

    let _found = false
    if (new_product_type !== '') {
      for (let i = 0; i < all_product_types.length; i++) {
        if (all_product_types[i].product === new_product_type) {
          // console.log('found', all_product_types[i].category_id, all_product_types[i].sub_category_meta)
          set_add_product_id(all_product_types[i]._id)
          set_add_product_name(all_product_types[i].product)
          category_changed(all_product_types[i].category_id)
          sub_category_changed(all_product_types[i].sub_category_meta)
          _display_units = all_product_types[i].units
          _found = true
        }
      }
    }

    let _possible_search_alternatives = []
    if (_found === false) {
      // find possible matches 

      // const best_match = stringSimilarity.findBestMatch(searched_product, all_product_types_in_alphabetical_order);
      // console.log(best_match)
      for (let i = 0; i < all_product_types_in_alphabetical_order.length; i++) {
        const similarity = stringSimilarity.compareTwoStrings(searched_product, all_product_types_in_alphabetical_order[i]);
        if (similarity > 0.4) {
          _possible_search_alternatives.push(all_product_types_in_alphabetical_order[i])
        }
      }
      set_possible_search_alternatives(_possible_search_alternatives)
    }

    set_search_product_found(_found)
    set_display_units(_display_units);
  }



  const get_user_profile = async () => {
    let get_buyer_requested;
    let _data = []
    try {
       get_buyer_requested = await retrieveBuyerProducts('id', 'product', 'buyer', 'status');
       // console.log(get_buyer_requested)
       // console.log('get orders', get_buyer_requested);
       if (get_buyer_requested.success) {
         let { data } = get_buyer_requested;     
         for (let i=0; i < data.length; i++) {
            //  console.log(data[i]);
           data[i].product = data[i].product
           data[i].quantity = data[i].quantity
           data[i].units = data[i].units
           data[i].status = data[i].status
           data[i].buyer_details = data[i].buyer_details
           data[i].location = data[i].location

           if (data[i].status === 'Available' && data[i].read === false) {
            _data.push(data[i])
           }

           


          //  console.log(data[i])
           // data[i].action = <Link class="" to={`/track/${data[i].tracking_number}/${data[i]._id}`}><i class="bi bi-truck"></i> Track order</Link>
          //  data[i].action = <button class="btn btn-danger btn-lg" onClick={()=>cancelRequest(data[i]._id)}>Delete request</button>
         }
         set_buyer_requested(_data);
       } else {
         set_error_message('Requests history is empty.');
       }
     } catch (error) {
      console.log(error.message)
      set_error_message('Requests history is empty.');
     }


    let get_orders;
    try {
      get_orders = await getOrdersV2('id', 'mine', 'seller', 'status');
      if (get_orders.success) {
        let { data } = get_orders;
        set_new_orders(data)
      }
    } catch (error) {
      set_error_message(`An error occured fetching new orders: ${error.message} `);
    }

    let get_recurring_orders;
    try {
      get_recurring_orders = await getRecurringOrders('id', 'mine', 'status');
      if (get_recurring_orders.success) {
        let { data } = get_recurring_orders;

        let all_products_ = []

        // console.log(data)

        for (let i = 0; i < data.length; i++) {
          console.log(data[i])
          all_products_.push({
            product_name: data[i].product_details.product,
            id: data[i]._id,
            repeat_period: data[i].repeat_period,
            price: 0,
            quantity: data[i].quantity,
            // category: 'jjj',
            action: <button class="btn btn-lg" onClick={() => remove_recurring_item_dialog_open(data[i])}><i class="bi bi-pencil"></i> Cancel</button>
            // <Link class="" to={`/seller-product/${data[i]._id}`}><i class="bi bi-pencil"></i> Cancel</Link>
          })
        }

        console.log(all_products_)

        set_recurring_orders(all_products_)

      }
    } catch (error) {
      set_error_message(`An error occured fetching new orders: ${error.message} `);
    }


    let get_all_categories;
    try {
      get_all_categories = await getCategories('id', 'meta', 'active');
      if (get_all_categories.success === false) {
        set_error_message(get_all_categories.message);
      }
      else {
        const { data } = get_all_categories;
        set_all_categories(data);
      }
    } catch (error) {
      set_error_message(`An error occured fetching categories: ${error.message} `);
    }

    let get_all_product_types;
    try {
      get_all_product_types = await getProductTypes('id', 'meta', 'active', 'search_term', 'category_id', 'sub_category_meta');
      if (get_all_product_types.success === false) {
        set_error_message(get_all_product_types.message);
      }
      else {
        const { data } = get_all_product_types;
        // console.log(data);
        set_all_product_types(data);

        let _all_product_types_in_alphabetical_order = []
        for (let i = 0; i < data.length; i++) {
          _all_product_types_in_alphabetical_order.push(data[i].product)
        }

        set_all_product_types_in_alphabetical_order(_all_product_types_in_alphabetical_order.sort())

      }
    } catch (error) {
      set_error_message(`An error occured fetching product types: ${error.message} `);
    }

    // let _active_orders = 0;
    // let _products_count = 0;
    // let _products_last_updated = '';
    // let _products_last_updated_warning = false;
    // let _inventory_alerts_count = 0;
    // let _inventory_alerts_warning = false;
    // let _inventory_alerts_text = '';
    // let _sales_history_value = 0;
    // let _perfomance_summary_rating = 0;
    // let _perfomance_summary_rating_last_update = '';
    // let _case_logs = 0;


    // let get_all_categories;

    // try {
    //   get_all_categories = await getCategories('id', 'meta', 'active');
    //   if (get_all_categories.success === false) {
    //     set_error_message(get_all_categories.message);
    //   }
    //   else {
    //     const { data } = get_all_categories;
    //     set_all_categories(data);
    //   }
    // } catch (error) {
    //   set_error_message(`An error occured fetching categories: ${error.message} `);
    // }

    // let get_all_product_types;
    // try {
    //   get_all_product_types = await getProductTypes('id', 'meta', 'active', 'search_term', 'category_id', 'sub_category_meta');
    //   if (get_all_product_types.success === false) {
    //     set_error_message(get_all_product_types.message);
    //   }
    //   else {
    //     const { data } = get_all_product_types;
    //     // console.log(data);
    //     set_all_product_types(data);

    //     let _all_product_types_in_alphabetical_order = []
    //     for (let i = 0; i < data.length; i++) {
    //       _all_product_types_in_alphabetical_order.push(data[i].product)
    //     }

    //     set_all_product_types_in_alphabetical_order(_all_product_types_in_alphabetical_order.sort())

    //   }
    // } catch (error) {
    //   set_error_message(`An error occured fetching product types: ${error.message} `);
    // }

    // set_all_products([])
    // let get_retrieved_products;
    // try {
    //   // console.log('in try')
    //   get_retrieved_products = await getProducts('id', user_profile._id, 'farm', 'product_meta', 'sub_category_meta', 'category_meta', 'product_name', 'quantity', 'quantity_units', 'min_price', 'max_price', 'delivery_zone', 'featured', 'on_sale', 'sort_order');
    //   // console.log('ret prod', get_retrieved_products)
    //   if (get_retrieved_products.success === false) {
    //     set_error_message(get_retrieved_products.message);
    //   }
    //   else {
    //     const { data } = get_retrieved_products;
    //     // console.log('retrieved products', data);
    //     let all_products_ = []

    //     for (let i = 0; i < data.length; i++) {
    //       all_products_.push({
    //         product_name: data[i].product_name,
    //         id: data[i]._id,
    //         price: 'weekly',
    //         quantity: data[i].quantity,
    //         category: data[i].category,
    //         action: <button class="btn btn-lg" onClick={() => remove_recurring_item_dialog_open(data[i])}><i class="bi bi-pencil"></i> Cancel</button>
    //         // <Link class="" to={`/seller-product/${data[i]._id}`}><i class="bi bi-pencil"></i> Cancel</Link>
    //       })
    //     }

    //     set_all_products(all_products_)
    //   }
    // } catch (error) {
    //   console.log('error', error.message)
    //   set_error_message(`An error occured fetching products: ${error.message} `);
    // }

    // try {
    //   const get_seller_orders = await getOrderItems('id', 'order_id', user_profile._id, 'product', 'status');
    //   if (get_seller_orders.success) {
    //     const { count, data } = get_seller_orders;
    //     if (count > 0) {
    //       for (let i = 0; i < data.length; i++) {
    //         if (data[i].status !== 'paid' || data[i].status !== 'collected') {
    //           _active_orders++;
    //         }

    //         if (data[i].status !== 'paid') {
    //           _sales_history_value += (parseFloat(data[i].quantity) * parseFloat(data[i].price));
    //         }
    //       }
    //     }
    //   } else {
    //     // set_error_message(get_seller_orders.message);
    //   }
    // } catch (error) {
    //   // set_error_message(`An error occured fetching seller orders: ${error.message} `);
    // }


    // try {
    //   const get_seller_products = await retrieveProducts('id', user_profile._id, 'farm', 'product_meta', 'sub_category_meta', 'category_meta', 'product_name', '0', 'quantity_units', 'min_price', 'max_price', 'delivery_zone', 'featured', 'on_sale', 'sort_order');

    //   if (get_seller_products.success) {
    //     const { count, data } = get_seller_products;
    //     _products_count = data.length;
    //     if (count > 0) {
    //       for (let i = 0; i < data.length; i++) {
    //         // eslint-disable-next-line 
    //         if ((i + 1) == data.length) {
    //           _products_last_updated = get_display_date(data[i].updatedAt);
    //           _products_last_updated_warning = true;
    //         }
    //       }
    //     }
    //   } else {
    //     // set_error_message(get_seller_products.message);
    //   }
    // } catch (error) {
    //   // set_error_message(`An error occured fetching seller orders: ${error.message} `);
    // }

    // try {
    //   const get_inventory_alerts = await getInventoryAlerts();
    //   if (get_inventory_alerts.success) {
    //     const { count, data } = get_inventory_alerts;
    //     _inventory_alerts_count = count;
    //     if (count > 0) {
    //       _inventory_alerts_warning = true;
    //       if (count > 2) {
    //         set_inventory_alerts_text('Over 2 products low')
    //       } else {
    //         for (let i = 0; i < data.length; i++) {
    //           _inventory_alerts_text = `${_inventory_alerts_text} ${data[i].product_name} `;
    //         }
    //         _inventory_alerts_text = `${_inventory_alerts_text} low`;
    //       }

    //     }
    //   } else {
    //     // set_error_message(get_inventory_alerts.message);
    //   }
    // } catch (error) {
    //   // set_error_message(`An error occured fetching inventory alerts: ${error.message} `);
    // }

    // _active_orders = _active_orders < 10 ? `0${_active_orders}` : _active_orders;
    // _products_count = _products_count < 10 ? `0${_products_count}` : _products_count;
    // _inventory_alerts_count = _inventory_alerts_count < 10 ? `0${_inventory_alerts_count}` : _inventory_alerts_count;
    // _sales_history_value = _sales_history_value < 10 ? `0${_sales_history_value}` : _sales_history_value;
    // _perfomance_summary_rating = _perfomance_summary_rating < 10 ? `0${_perfomance_summary_rating}` : _perfomance_summary_rating;
    // _case_logs = _case_logs < 10 ? `0${_case_logs}` : _case_logs;

    // set_active_orders(_active_orders);
    // set_products_count(_products_count);
    // set_products_last_updated(_products_last_updated);
    // set_products_last_updated_warning(_products_last_updated_warning);
    // set_inventory_alerts_count(_inventory_alerts_count);
    // set_inventory_alerts_warning(_inventory_alerts_warning);
    // set_inventory_alerts_text(_inventory_alerts_text);
    // set_sales_history_value(_sales_history_value);
    // set_perfomance_summary_rating(_perfomance_summary_rating);
    // set_perfomance_summary_rating_last_update(_perfomance_summary_rating_last_update);
    // set_case_logs(_case_logs);

    return;
  }

  useEffect(() => {
    if (page_loaded === false) {
      get_user_profile();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  const add_recurring_purchase = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    // console.log(add_product, add_category)
    // return;

    if (parseFloat(add_quantity) < 0) {
      set_error_message('Quantity cannot be less than 0')
      set_action_loading(false)
      return
    }

    // let units = add_units
    // if (units_method === 'complex') {
    //   units = `${units_size}${add_units} ${units_container}`
    // }

    let _product_name = ''
    // for (let i = 0; i < all_product_types.length; i++) {
    //   if (all_product_types[i].product_meta === new_product_type) {
    //     set_add_product_id(all_product_types[i]._id)
    //     set_add_product_name(all_product_types[i].product)
    //     // console.log('found')
    //     // console.log(all_product_types[i]._id, all_product_types[i].units);
    //     _display_units = all_product_types[i].units;
    //   }
    // }

    const update_data = {
      category_id: add_category,
      sub_category: add_sub_category,
      product_id: add_product_id,
      product_meta: add_product,
      // units,
      product: add_product_name,
      quantity: add_quantity,

    };

    // console.log(update_data);

    const response = await createRecurringOrder(add_product_id, add_quantity, add_repeat_period);
    // console.log(response)
    // return;
    if (response.success === false) {
      set_error_message(response.message);

    } else {
      await get_user_profile()
      add_recurring_order_dialog_close()
      set_success_message('Recurring order successfully added');

    }
    set_action_loading(false);



    return;
  }

  const read_buyer_order_notification = async (order_id) => {
    set_error_message('')
    set_success_message('')
    let update_data = {
      buyer_confirmation: '',
      buyer_confirmation_read: true,
    }

    let make_action;
    try {
      make_action = await updateOrder(update_data, order_id, 'read_buyer_notification');
      if (make_action.success) {
        set_success_message('Notification marked as read. It will not pop up again')

        await get_user_profile()

        return
      } else {
        set_error_message(make_action.message)
      }
    } catch (error) {
      set_error_message(`An error occured fetching new orders: ${error.message} `);
    }
  }

  const read_buyer_requested_notification = async (order_id, _product, redirect) => {
    set_error_message('')
    set_success_message('')
    let update_data = {
      read: true,
    }

    let make_action;
    try {
      make_action = await updateBuyerRequestedProduct(update_data, order_id, 'read');
      if (make_action.success) {
        // set_success_message('Notification marked as read. It will not pop up again')

        if (redirect) {
          props.history.push(`/shop/sub_cat/${_product}`)
        } else {
          await get_user_profile()
        }

        // await get_user_profile()

        return
      } else {
        set_error_message(make_action.message)
      }
    } catch (error) {
      set_error_message(`An error occured fetching new orders: ${error.message} `);
    }
  }

  const remove_recurring_order = async (order_id) => {
    set_error_message('')
    set_success_message('')
    let update_data = {
      status: 'Cancelled',
      buyer: ''
    }

    let make_action;
    try {
      make_action = await updateRecurringOrder(update_data, order_id, 'cancel');
      if (make_action.success) {
       
        set_success_message('Order successfully cancelled')
        await get_user_profile()
        remove_recurring_item_dialog_close()
        
        

        return
      } else {
        set_error_message(make_action.message)
      }
    } catch (error) {
      set_error_message(`An error occured fetching new orders: ${error.message} `);
    }
  }



  return (
    <>
      <section id="contact" class="contact" style={{ backgroundColor: '#f8f8f8' }}>
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2><span class="black-text">My</span> <span class="green-text">Dashboard</span></h2>
          </div>


          {!page_loaded ? ( // so that only products will be loading at this time 
            <div>Loading ... </div>
          ) : (
            <>
              <div class="row">
                <div class="col-12">
                  {error_message !== '' ? (
                    <div class="alert alert-danger" role="alert">
                      {error_message}
                    </div>
                  ) : ('')}
                  {success_message !== '' ? (
                    <div class="alert alert-success" role="alert">
                      {success_message}
                    </div>
                  ) : ('')}
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 m-5">

                {buyer_requested.length > 0 ? (
                    buyer_requested.map(no => (
                        <p class="alert alert-success">
                          You requested {no.product}. A new seller in {no.location} has just listed Product {no.product}. Do you want to see the latest listings of {no.product} now <button type="button" class="btn btn-success btn-sm"
                            style={{ color: "#fff", fontSize: "12px" }}
                            onClick={() => read_buyer_requested_notification(no._id, no.product, true)}
                          >Yes</button> / <button type="button" class="btn btn-success btn-sm"
                          style={{ color: "#fff", fontSize: "12px" }}
                          onClick={() => read_buyer_requested_notification(no._id, no.product, false)}
                        >No</button>. To stop receiving these notifications, remove the request from your dashboard.
                         
                        </p>
                    ))
                  ) : ('')}

                  {new_orders.length > 0 ? (
                    new_orders.map(no => (

                      !no.buyer_confirmation_read && no.buyer_confirmation === 'confirm_order' ? (
                        <p class="alert alert-success">
                          {no.seller_details.display_name} has accepted your request for buying {no.quantity} {no.product_details.price_per} of {no.product_details.product_name} which you made {get_display_date(no.createdAt)}.  
                          {/* <Link to={`/messages/${no.seller}`} class="btn btn-success btn-sm"
                          style={{ color: "#fff", fontSize: "12px" }}>Message seller</Link> */}

                          &nbsp;
                          <button type="button" class="btn btn-success btn-sm"
                            style={{ color: "#fff", fontSize: "12px" }}
                            onClick={() => read_buyer_order_notification(no._id)}
                          >Mark as read</button>
                        </p>
                      ) : ('')
                    ))

                  ) : ('')}


                  {new_orders.length > 0 ? (
                    new_orders.map(no => (
                      !no.buyer_confirmation_read && no.buyer_confirmation === 'reject_order' ? (
                        <p class="alert alert-danger">
                          {no.seller_details.display_name} has declined your request for buying {no.quantity} {no.product_details.price_per} of {no.product_details.product_name} which you made {get_display_date(no.createdAt)}. You can try to buy from another seller.
                          <button type="button" class="btn btn-success btn-sm"
                            style={{ color: "#fff", fontSize: "12px" }}
                            onClick={() => read_buyer_order_notification(no._id)}
                          >Mark as read</button>
                        </p>
                      ) : ('')
                    ))
                  ) : ('')}



                </div>

                <h2>Recurrent purchases</h2>
                <div class="row">
                  <div class="col-md-12">
                    {recurring_orders.length > 0 ? (
                      <BootstrapTable
                        data={recurring_orders}
                        pagination>
                        <TableHeaderColumn dataField='product_name'>Produce name</TableHeaderColumn>
                        <TableHeaderColumn dataField='quantity' >Quantity</TableHeaderColumn>
                        <TableHeaderColumn dataField='repeat_period'>Repeat period</TableHeaderColumn>
                        {/* <TableHeaderColumn dataField='status'>Status</TableHeaderColumn> */}
                        <TableHeaderColumn dataField='action' dataFormat={link_cell_formatter} isKey={true}></TableHeaderColumn>
                      </BootstrapTable>

                    ) : ('')}

                    <button class="btn btn-success btn-lg" onClick={() => add_recurring_order_dialog_open()}>Add recurring purchase</button>
                  </div>
                </div>
                {/* <div class="col-md-3">
                  <div class="info-box product-box dashboard-box">
                    <Link to="seller-orders/active">
                    <div class="text-right mb-3 mr-3">
                      <span class="grey-text dashboard-box-text">
                        &nbsp;
                        </span>
                      <br/>
                    </div>   
                    <span class="grey-text dashboard-box-heading">Active Orders</span>
                    <br/>
<span class="black-text dashboard-box-count">{active_orders}</span>
                    <br/>
                    <span class="grey-text dashboard-box-text">&nbsp;</span>
                    </Link>
                  </div>                    
                </div>
                <div class="col-md-1"></div> */}


                {/* <div class="col-md-3">
                  <div class="info-box product-box dashboard-box">
                  <Link to="seller-products">
                    <div class="text-right mb-3 mr-3">
                    {products_last_updated_warning ? (<span class="grey-text dashboard-box-text">
                        Last updated: {products_last_updated} <img src="/assets/img/warning.png" alt="warning" />
                        &nbsp;
                        </span>):('') } 
                      <br/>
                    </div>                  
                    <span class="grey-text dashboard-box-heading">Products Summary</span>
                    <br/>
<span class="black-text dashboard-box-count">{products_count}</span>
                    <br/>
                    <span class="grey-text dashboard-box-text">Products on sale</span>
                    </Link>
                  </div>                    
                </div>
                <div class="col-md-1"></div>
 */}


                {/* <div class="col-md-3">
                  <div class="info-box product-box dashboard-box">
                  <Link to="seller-sales">
                    <div class="text-right mb-3 mr-3">
                      <span class="grey-text dashboard-box-text">
                        Total sales to date
                        &nbsp;
                        </span>
                      <br/>
                    </div>                  
                    <span class="grey-text dashboard-box-heading">Sales History</span>
                    <br/>
<span class="green-text dashboard-box-count">${sales_history_value}</span>
                    <br/>
                    <span class="grey-text dashboard-box-text">Amount</span>
                    </Link>
                  </div>                    
                </div>
                <div class="col-md-1"></div> */}

              </div>
            </>
          )}

        </div>
      </section>


      <Dialog open={add_recurring_order_dialog} onClose={add_recurring_order_dialog_close} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Enter recurring item details </DialogTitle>
        <DialogContent>
          <DialogContentText>

            {alternatively_search_product ? (
              <div class="form-group col-md-12 my-3">
                <label for="">
                  Product
                  &nbsp;&nbsp;
                  <span>
                    <button type="button" class="btn btn-success btn-lg" onClick={() => set_alternatively_search_product(false)}>
                      Select product by category/sub category
                    </button>
                    <br /><br />
                  </span>
                </label>
                <input list="brow" class="form-control" onChange={(e) => {
                  set_searched_product(e.target.value)
                  product_type_changed_with_search(e.target.value);
                }}
                  value={searched_product} />
                <datalist id="brow" >
                  {all_product_types_in_alphabetical_order.map((c) => (
                    <option value={c} />
                  ))}
                </datalist>

                {search_product_found ? ('') : (
                  searched_product === '' ? ('') : (
                    possible_search_alternatives.length < 1 ? (
                      <span>
                        {searched_product} not found - please search again, or <Link to={`/specify-new-product/${searched_product}`}>Contact Us</Link> describing the product you would like to sell
                      </span>
                    ) : (
                      <>
                        <span class="mt-3">
                          Do you mean?
                          <ul>
                            {possible_search_alternatives.map(psa =>
                            (<li class="green-text" onClick={() => {
                              set_searched_product(psa)
                              product_type_changed_with_search(psa)
                            }}>{psa}</li>))
                            }
                          </ul>
                        </span>
                      </>
                    )

                  )
                )}

              </div>
            ) : (
              <>
                <div class="form-group col-md-12 my-3">
                  <label for="">
                    Category
                    &nbsp;&nbsp;
                    <span>
                      <button type="button" class="btn btn-success btn-lg" onClick={() => set_alternatively_search_product(true)}>
                        Alternatively, search directly for product
                      </button>
                      <br /><br />
                    </span>
                  </label>
                  <select class="form-control" name="add_category" id="add_category" onChange={(e) => category_changed(e.target.value)}>
                    <option value="">Select category</option>
                    {all_categories.map((c) => (
                      <option key={c._id} value={c._id}>{c.category}</option>
                    ))}
                  </select>
                </div>

                <div class="form-group col-md-12 my-3">
                  <label for="">Sub category</label>
                  <select class="form-control" name="add_sub_category" id="add_sub_category" onChange={(e) => sub_category_changed(e.target.value)}>
                    <option value="">Select sub category</option>
                    {display_sub_categories.map((c) => (
                      <option key={c.sub_category_meta} value={c.sub_category_meta}>{c.sub_category}</option>
                    ))}
                  </select>
                </div>

                <div class="form-group col-md-12 my-3">
                  <label for="">
                    Product
                  </label>
                  <select class="form-control" name="add_product" id="brow" onChange={(e) => product_type_changed(e.target.value)}>
                    <option value="">Select product</option>
                    {display_product_types.map((c) => (
                      <option key={c.product_meta} value={c.product_meta}>{c.product}</option>
                    ))}
                  </select>
                </div>


              </>
            )}

            <div class="form-group col-md-12 my-3">
              <label for="">
                Quantity
              </label>
              <input min="1" class="form-control" type="number" value={add_quantity} onChange={(e) => set_add_quantity(e.target.value)} />
            </div>


            <div class="form-group col-md-12 my-3">
              <label for="">
                Repeat period
              </label>
              <select class="form-control" name="add_product" id="brow" onChange={(e) => set_add_repeat_period(e.target.value)}>
                <option value={add_repeat_period}>{add_repeat_period}</option>
                {repeat_periods.map((c) => (
                  c !== add_repeat_period ? (<option key={c} value={c}>{c}</option>) : ('')
                ))}
              </select>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <form onSubmit={add_recurring_purchase}>
            <button
              class="btn btn-lg btn-success"
              type="button"
              onClick={add_recurring_order_dialog_close} color="primary">
              Cancel
            </button>
            &nbsp;&nbsp;
            <button
              class="btn btn-lg btn-success"
              type="submit"
              // onClick={removeCityDetails}
              color="primary">
              Add recurring purchase
            </button>
          </form>
        </DialogActions>
      </Dialog>


      <Dialog open={remove_recurring_item_dialog} onClose={remove_recurring_item_dialog_close} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Removing {remove_recurring_purchase_item.product_name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove <b>{remove_recurring_purchase_item.product_name}</b> from recurring purchases?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            class="btn btn-lg btn-success"
            type="submit"
            onClick={remove_recurring_item_dialog_close} color="primary">
            Cancel
          </button>

          <button
            class="btn btn-lg btn-success"
            type="submit"
            onClick={()=>remove_recurring_order(remove_recurring_purchase_item.id)} 
            color="primary">
            Yes, I'm sure
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default BuyerDashboardScreen;
