import {
  CATEGORIES_LIST_REQUEST,
  CATEGORIES_LIST_SUCCESS,
  CATEGORIES_LIST_FAIL,
  CATEGORIES_DETAILS_REQUEST,
  CATEGORIES_DETAILS_SUCCESS,
  CATEGORIES_DETAILS_FAIL,
  CATEGORIES_SAVE_REQUEST,
  CATEGORIES_SAVE_SUCCESS,
  CATEGORIES_SAVE_FAIL,
  CATEGORIES_DELETE_REQUEST,
  CATEGORIES_DELETE_SUCCESS,
  CATEGORIES_DELETE_FAIL,
} from '../constants/categoryConstants';

function categoryListReducer(state = { categories: [] }, action) {
  switch (action.type) {
    case CATEGORIES_LIST_REQUEST:
      return { loading: true, categories: [] };
    case CATEGORIES_LIST_SUCCESS:
      return { loading: false, categories: action.payload };
    case CATEGORIES_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function categoryDetailsReducer(state = { category: { reviews: [] } }, action) {
  switch (action.type) {
    case CATEGORIES_DETAILS_REQUEST:
      return { loading: true };
    case CATEGORIES_DETAILS_SUCCESS:
      return { loading: false, category: action.payload };
    case CATEGORIES_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function categoryDeleteReducer(state = { category: {} }, action) {
  switch (action.type) {
    case CATEGORIES_DELETE_REQUEST:
      return { loading: true };
    case CATEGORIES_DELETE_SUCCESS:
      return { loading: false, category: action.payload, success: true };
    case CATEGORIES_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function categorySaveReducer(state = { category: {} }, action) {
  switch (action.type) {
    case CATEGORIES_SAVE_REQUEST:
      return { loading: true };
    case CATEGORIES_SAVE_SUCCESS:
      return { loading: false, success: true, category: action.payload };
    case CATEGORIES_SAVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export {
  categoryListReducer,
  categoryDetailsReducer,
  categorySaveReducer,
  categoryDeleteReducer,
};
