import React, { useEffect, useState } from 'react';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { getOrders, payForOrder } from '../actions/orderActions';
import { Link } from 'react-router-dom';


function PayScreen(props) {
  const order_id = props.match.params.order_id ? props.match.params.order_id : '';
  
  const [action_loading, set_action_loading] = useState(false);
  const [page_data_loaded, set_page_data_loaded] = useState(false);

  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');
  // eslint-disable-next-line
  const [zwl_value, set_zwl_value] = useState('');
  // eslint-disable-next-line
  const [usd_value, set_usd_value] = useState('');
  // eslint-disable-next-line
  const [order_data, set_order_data] = useState('');
  // eslint-disable-next-line
  const [payment_method, set_payment_method] = useState('');
  const [order_items, set_order_items] = useState([]);
  
  // eslint-disable-next-line
  useEffect(() => {
      if (page_data_loaded === false) {
        set_action_loading(true);
        load_page_data();
        set_page_data_loaded(true);
      }        
  });

  /**
   * Get order data.
   */
  const load_page_data = async () => { 
    let get_all_orders;

    try {
       get_all_orders = await getOrders(order_id, 'role');
       if (get_all_orders.success === false) {
         set_error_message(get_all_orders.message);
       }
       else {
         const { data } = get_all_orders;

         set_zwl_value(data[0].prices.ZW);
         set_usd_value(data[0].prices.US);
         set_order_data(data[0]);
         console.log('order details', data[0]);
         set_order_items(data[0].products);
       }
     } catch (error) {
       set_error_message(`An error occured fetching order details: ${error.message} `);
     }

     set_action_loading(false);
   return;
 }

 const complete_purchase = async (pay_using) =>  {
  if (page_data_loaded === false) {
      return;
  }

  

  set_success_message('');
  set_error_message('');
  set_action_loading(true);

  if (pay_using === '') {
    set_error_message('Please click a payment method to pay for order again.');
    set_action_loading(false);
    return;
  }


  const response = await payForOrder(pay_using, order_id); 
  if (response.success === false) {
      set_error_message(response.message); 
  } else {
    if (pay_using === 'Paynow') {
      window.location.replace(`${response.paynow_link}`);
    } else if (pay_using === 'PayPal') {
      window.location.replace(`${response.paypal_link}`);
    } else {
       props.history.push(`/orders/fail`);
    }     
  }

  set_action_loading(false);

  return; 
}
  return (
    <section id="about" class="about main-content mt-5 login-content">
        <div class="container-fluid" data-aos="fade-up">
          <div class="row mt-5">
            {/* <div class="col-md-4"></div> */}
            <div class="col-md-12 text-center mb-5">
              <h3 class="green-text">Retry payment for your order <span class="orange-text">{order_id}</span>.</h3>


              {error_message !== '' ? (
              <div class="col-12">
                <div class="alert alert-danger" role="alert">
                  {error_message}
                </div>
              </div>): ('')}

              {success_message !== '' ? (
              <div class="col-12">
                <div class="alert alert-success" role="alert">
                  {success_message}
                </div>
              </div>): ('')}



              <p class="my-5">
                {
                  !action_loading ? (
                  <>
                    <button 
                                  type="submit" 
                                  class="btn btn-primary btn-checkout" 
                                  onClick={() => { set_payment_method('PayPal'); complete_purchase('PayPal') }}
                                >
                                  <img src="/assets/img/paypal-logo.png" alt="PayPal" class="img-fluid img-checkout" />
                                </button>
      
                                <button 
                                  type="submit" 
                                  class="btn btn-primary btn-checkout" 
                                  onClick={() => { set_payment_method('Paynow'); complete_purchase('Paynow') }}
                                >
                                  <img src="/assets/img/paynow-logo.png" alt="Paynow" class="img-fluid img-checkout" />
                                </button>
                                </>):(
                                  <>
                                  <div class="spinner-border text-orange" role="status">
                                  </div> 
                                  &nbsp;Please wait                      
                                </>
                                )
                }

                
              </p>

              <h3>Order summary</h3>
              
              <div class="table-responsive text-left">
              <table class="table table-bordered">

              <tr  style={{fontWeight: 'bold'}}>
              <td>Quantity</td>              
              <td>Item</td>
              <td>
                Item Cost
              </td>
              <td>
                Subtotal
              </td>
            </tr>


                {order_items.map(item =>
                  <tr>
                    <td>
                      {item.quantity} {item.units}
                    </td>
                    <td>
                      <Link to={`/product/${item.product_meta}/${item.product}`}>
                        {item.name}
                      </Link>
                    </td>
                    <td>
                      US${(item.price).toFixed(2)}
                    </td>
                    <td>
                      US${(item.price * item.quantity).toFixed(2)}
                    </td>
                    
                  </tr>
            )}
            <tr style={{fontWeight: 'bold'}}>
                      <td>{order_items.length} items</td>
              
              <td></td>
              <td></td>
              <td>
                US$ {usd_value}
                <br/>
                ZW$ {zwl_value}
              </td>
              <td></td>
            </tr>

            </table>
            </div>


              
              
            </div>             
        </div>
        </div>
      </section>
  )}
export default PayScreen;