import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { validate_role } from '../validations';
import { getAdminsV2 } from '../actions/userActions';


function StaffMembersScreen(props) {
  // eslint-disable-next-line
  let user_profile;
  const permitted_role = 'admin';
  const check_permissions = validate_role(permitted_role);
  if (check_permissions.permitted === false) {
    let login_link = 'login';
    if (permitted_role === 'admin') login_link = 'access-forbidden'
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  // eslint-disable-next-line
  const [error_message, set_error_message] = useState('');
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState('');
  const [page_loaded, set_page_loaded] = useState(false);
  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);

  const [admins, set_admins] = useState([]);
  const [technical_support, set_technical_support] = useState([]);
  const [sales_support, set_sales_support] = useState([]);
  const [field_support, set_field_support] = useState([]);
  const [delivery, set_delivery] = useState([]);
  const [verifier, set_verifier] = useState([]);

  const [status, set_status] = useState('status');


  const productList = useSelector((state) => state.productList);
  const { loading, error } = productList;

  const toggle_staff = async (new_status) => { 
    let get_admins;
  
    // "admin" "technical-support", "sales-support", "field-support", "delivery"
     try {
       get_admins = await getAdminsV2('id', 'admin', new_status);
       if (get_admins.success) {
         const { data } = get_admins;
         set_admins(data);
       }
  
       get_admins = await getAdminsV2('id', 'technical-support', new_status);
       if (get_admins.success) {
         const { data } = get_admins;
         set_technical_support(data);
       }
  
       get_admins = await getAdminsV2('id', 'sales-support', new_status);
       if (get_admins.success) {
         const { data } = get_admins;
         set_sales_support(data);
       }
  
       get_admins = await getAdminsV2('id', 'field-support', new_status);
       if (get_admins.success) {
         const { data } = get_admins;
         set_field_support(data);
       }

       get_admins = await getAdminsV2('id', 'verifier', new_status);
       if (get_admins.success) {
         const { data } = get_admins;
         set_verifier(data);
       }
  
       get_admins = await getAdminsV2('id', 'delivery', new_status);
       if (get_admins.success) {
         const { data } = get_admins;
         set_delivery(data);
       }
     } catch (error) {
       set_error_message(`An error occured fetching staff members: ${error.message} `);
     }
  
   return;
  }

  const load_page_data = async () => { 
    let get_admins;

    // "admin" "technical-support", "sales-support", "field-support", "delivery"
     try {
       get_admins = await getAdminsV2('id', 'admin', status);
       if (get_admins.success) {
         const { data } = get_admins;
         set_admins(data);
       }

       get_admins = await getAdminsV2('id', 'technical-support', status);
       if (get_admins.success) {
         const { data } = get_admins;
         set_technical_support(data);
       }

       get_admins = await getAdminsV2('id', 'sales-support', status);
       if (get_admins.success) {
         const { data } = get_admins;
         set_sales_support(data);
       }

       get_admins = await getAdminsV2('id', 'field-support', status);
       if (get_admins.success) {
         const { data } = get_admins;
         set_field_support(data);
       }

       get_admins = await getAdminsV2('id', 'verifier', status);
      //  console.log('get admins', get_admins)
       if (get_admins.success) {
         const { data } = get_admins;
        //  console.log('data', data)
         set_verifier(data);
       }

       get_admins = await getAdminsV2('id', 'delivery', status);
       if (get_admins.success) {
         const { data } = get_admins;
         set_delivery(data);
       }
     } catch (error) {
       set_error_message(`An error occured fetching staff members: ${error.message} `);
     }

   return;
 }

 

 // eslint-disable-next-line
  const dispatch = useDispatch();
  useEffect(() => {
    if (page_loaded === false ) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
    <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2><span class="green-text">Staff</span> <span class="orange-text">members</span></h2>
        </div>

        <div class="row">
          <div class="col-12 mb-5">
            <Link to="/add-staff-member" class="btn btn-primary"><i class="bi bi-plus"> </i>Add staff member</Link>
          </div>
          {/* <div class="col-12 mb-5">
            {
              status === 'active' ? (
                <button 
                          class="btn btn-primary" 
                          type="button"
                          onClick={ () => {  set_status('status'); toggle_staff('status') }}
                          disabled={action_loading}
                        >
                         <i class="bi bi-eye"> </i>  Show all
                        </button>
              ):(
                <button 
                          class="btn btn-primary" 
                          type="submit"
                          onClick={ () => {  set_status('active'); toggle_staff('active') }}
                          disabled={action_loading}
                        >
                          <i class="bi bi-eye"> </i>  Show active only
                        </button>
              )
            }
          </div>  */}
        </div>

        


        {loading ? ( // so that only products will be loading at this time 
          <div>Loading ... </div>
        ) : error ? (
        <div class="row">
          <div class="col-12">
            <div class="alert alert-danger" role="alert">
                No staff members found. Please reload page to get some. 
            </div>
          </div>
        </div>
        ) : (
          <div class="row">
            
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>First name</th>
                    <th>Surname</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Location</th>
                    <th>Status</th>
                    
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                
                <tr>
                    <th>System administrators</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                {admins.length > 0 ? (
                  admins.map((s) => (  
                    <>            
                      <tr key={s._id} >
                        <td>{s.first_name}</td>
                        <td>{s.last_name}</td>
                        <td>{s.phone}</td>
                        <td>{s.email}</td>
                        <td>{s.suburb} {s.city !== '' && s.city !== undefined ? (`, ${s.city}`):(``)}</td>
                        <td>{s.status}</td>
                        <td><Link to={`/staff-member/${s._id}`}>View employee</Link></td>
                      </tr>                    
                    </>
                  ))
                ):(<tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>)}

<tr>
                    <th>Technical support</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                {technical_support.length > 0 ? (
                  technical_support.map((s) => (  
                    <>            
                      <tr key={s._id} >
                        <td>{s.first_name}</td>
                        <td>{s.last_name}</td>
                        <td>{s.phone}</td>
                        <td>{s.email}</td>
                        <td>{s.suburb} {s.city !== '' && s.city !== undefined ? (`, ${s.city}`):(``)}</td>
                        <td>{s.status}</td>
                        <td><Link to={`/staff-member/${s._id}`}>View employee</Link></td>
                      </tr>                    
                    </>
                  ))
                ):(<tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>)}

<tr>
                    <th>Sales support</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                {sales_support.length > 0 ? (
                  sales_support.map((s) => (  
                    <>            
                      <tr key={s._id} >
                        <td>{s.first_name}</td>
                        <td>{s.last_name}</td>
                        <td>{s.phone}</td>
                        <td>{s.email}</td>
                        <td>{s.suburb} {s.city !== '' && s.city !== undefined ? (`, ${s.city}`):(``)}</td>
                        <td>{s.status}</td>
                        <td><Link to={`/staff-member/${s._id}`}>View employee</Link></td>
                      </tr>                    
                    </>
                  ))
                ):(<tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>)}

<tr>
                    <th>Field support</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                {field_support.length > 0 ? (
                  field_support.map((s) => (  
                    <>            
                      <tr key={s._id} >
                        <td>{s.first_name}</td>
                        <td>{s.last_name}</td>
                        <td>{s.phone}</td>
                        <td>{s.email}</td>
                        <td>{s.suburb} {s.city !== '' && s.city !== undefined ? (`, ${s.city}`):(``)}</td>
                        <td>{s.status}</td>
                        <td><Link to={`/staff-member/${s._id}`}>View employee</Link></td>
                      </tr>                    
                    </>
                  ))
                ):(<tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>)}

<tr>
                    <th>Delivery</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                {delivery.length > 0 ? (
                  delivery.map((s) => (  
                    <>            
                      <tr key={s._id} >
                        <td>{s.first_name}</td>
                        <td>{s.last_name}</td>
                        <td>{s.phone}</td>
                        <td>{s.email}</td>
                        <td>{s.suburb} {s.city !== '' && s.city !== undefined ? (`, ${s.city}`):(``)}</td>
                        <td>{s.status}</td>
                        <td><Link to={`/staff-member/${s._id}`}>View employee</Link></td>
                      </tr>                    
                    </>
                  ))
                ):(<tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>)}

                <tr>
                    <th>Verifier</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                {verifier.length > 0 ? (
                  verifier.map((s) => (  
                    <>            
                      <tr key={s._id} >
                        <td>{s.first_name}</td>
                        <td>{s.last_name}</td>
                        <td>{s.phone}</td>
                        <td>{s.email}</td>
                        <td>{s.suburb} {s.city !== '' && s.city !== undefined ? (`, ${s.city}`):(``)}</td>
                        <td>{s.status}</td>
                        <td><Link to={`/staff-member/${s._id}`}>View employee</Link></td>
                      </tr>                    
                    </>
                  ))
                ):(<tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>)}


            </tbody>
            </table>
          </div>
        </div>
        )}
        
      </div>
    </section>
    </>
  );
}
export default StaffMembersScreen;
