import React, { useState, useEffect, useRef } from 'react';
import { validate_multiple_roles } from '../validations';
import { getAdmin, updateUserDetails } from '../actions/userActions';
import { getCities, getCountries, getSuburbs } from '../actions/categoryActions';
import { Textbox } from 'react-inputs-validation';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import axios from 'axios';
import { APP_URL, API_URL, API_KEY, token_string } from '../config'
import { Link } from 'react-router-dom';

function AdminProfileScreen(props) {
  let user_profile;
  const permitted_roles = ['verifier', 'admin', 'technical-support'];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = 'access-forbidden';
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;

    // if (!(user_profile.roles).includes('seller')) {
    //   props.history.push('buyer-profile')
    // }
  }

  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');
  const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);


  const [cities, set_cities] = useState([]);
  const [countries, set_countries] = useState([]);
  const [suburbs, set_suburbs] = useState([]);

  const [first_name, set_first_name] = useState('');
  const [username, set_username] = useState('');
  const [last_name, set_last_name] = useState('');
  const [trading_name, set_trading_name] = useState('');
  const [phone, set_phone] = useState('');
  const [email, set_email] = useState('');
  const [seller_type, set_seller_type] = useState('Farm');

  const [telephone, set_telephone] = useState('');
  const [display_name, set_display_name] = useState('');
  const [description, set_description] = useState('');
  const [address_line1, set_address_line1] = useState('');
  const [address_line2, set_address_line2] = useState('');
  const [suburb, set_suburb] = useState('');
  const [city, set_city] = useState('Select');
  const [province, set_province] = useState('');
  const [country, set_country] = useState('');
  const [country_meta, set_country_meta] = useState('');
  const [city_meta, set_city_meta] = useState('');
  // eslint-disable-next-line
  const [seller_image, set_seller_image] = useState('');

  const [provide_delivery, set_provide_delivery] = useState(false);
  const [delivery_charge_type, set_delivery_charge_type] = useState('');
  const [delivery_charge, set_delivery_charge] = useState('');
  const [delivery_purchase_minimum, set_delivery_purchase_minimum] = useState('');
  const [delivery_maximum_radius, set_delivery_maximum_radius] = useState('');

  const [organic_check, set_organic_check] = useState(false);
  const [organic_check_status, set_organic_check_status] = useState(false);
  const [admin_verified_status, set_admin_verified_status] = useState('');
  const [admin_verified_check, set_admin_verified_check] = useState(false);

  const [latitude, set_latitude] = useState('');
  const [longitude, set_longitude] = useState('');

  const [admin_verification, set_admin_verification] = useState({
    status: 'Not Verified',
    comments: '',
  });


  // eslint-disable-next-line
  const [first_name_invalid, set_first_name_invalid] = useState(true);
  // eslint-disable-next-line
  const [last_name_invalid, set_last_name_invalid] = useState(true);
  // eslint-disable-next-line
  const [trading_name_invalid, set_trading_name_invalid] = useState(true);
  // eslint-disable-next-line
  const [phone_invalid, set_phone_invalid] = useState(true);
  // eslint-disable-next-line
  const [email_invalid, set_email_invalid] = useState(true);
  // eslint-disable-next-line
  const [seller_type_invalid, set_seller_type_invalid] = useState(true);
  // eslint-disable-next-line
  const [telephone_invalid, set_telephone_invalid] = useState(true);
  // eslint-disable-next-line
  const [display_name_invalid, set_display_name_invalid] = useState(true);
  // eslint-disable-next-line
  const [description_invalid, set_description_invalid] = useState(true);
  // eslint-disable-next-line
  const [address_line1_invalid, set_address_line1_invalid] = useState(true);
  // eslint-disable-next-line
  const [address_line2_invalid, set_address_line2_invalid] = useState(true);
  // eslint-disable-next-line
  const [suburb_invalid, set_suburb_invalid] = useState(true);
  // eslint-disable-next-line
  const [city_invalid, set_city_invalid] = useState(true);
  // eslint-disable-next-line
  const [province_invalid, set_province_invalid] = useState(true);
  // eslint-disable-next-line
  const [country_invalid, set_country_invalid] = useState(false);
  // eslint-disable-next-line
  const [onsite_invalid, set_onsite_invalid] = useState(true);

  // eslint-disable-next-line
  const [user_profile_in_db, set_user_profile_in_db] = useState('');

  const [file, setFile] = useState(''); // storing the uploaded file
  // eslint-disable-next-line
  const [progress, setProgess] = useState(0); // progess bar
  const el = useRef(); // accesing input element    
  const handleChange = (e) => {
    setProgess(0)
    const file = e.target.files[0]; // accessing file
    setFile(file); // storing file
  }


  /**
   * 
   * @returns updates a client profile picture
   */
  // eslint-disable-next-line 
  const update_client_profile_picture = async (e) => {
    e.preventDefault();
    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    const formData = new FormData();
    formData.append('api_key', API_KEY);
    formData.append('file', file); // appending file
    formData.append('update_type', 'picture');
    formData.append('id', user_profile._id);
    // console.log('about to axio')
    await axios.patch(`${API_URL}/sellers`,
      formData, {
      headers: {
        Authorization: 'Bearer ' + token_string
      },
    }, {
      onUploadProgress: (ProgressEvent) => {
        let progress = Math.round(
          ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
        setProgess(progress);
      }
    }).then(res => {
      const { data } = res;
      console.log(data)
      if (data.success === true) {
        set_success_message('Profile picture successfully updated');
      } else if (data.success === false) {
        set_error_message(data.message);
      } else {
        set_error_message(`An error occured updating profile picture`);
      }
      // console.log(data, 'then')
    }).catch(
      // console.log('exc')
      // set_error_message('An error occured updating profile picture')
    );

    await get_user_profile();

    set_action_loading(false);
    return;
  }




  const get_user_profile = async () => {
    const { _id } = user_profile;
    // console.log(`user id ${_id}`);

    let this_user_profile;
    try {
      this_user_profile = await getAdmin(_id);

      console.log(this_user_profile)

      if (this_user_profile.success === false) {
        set_error_message('An error occured retrieving your profile. Please reload page to try again');
        // console.log('error', error_message);
      } else {
        const user_details = this_user_profile.data[0];
        set_user_profile_in_db(user_details);
        set_page_loaded(true);
        // console.log('get user profile', user_profile_in_db);

        const { seller_details, delivery, organic, approved } = user_details;



        set_first_name(user_details.first_name);
        set_username(user_details.username);
        set_last_name(user_details.last_name);
        set_phone(user_details.phone);
        set_email(user_details.email);
        set_telephone(user_details.telephone);
        set_display_name(user_details.display_name);

        set_seller_image(user_details.image);

        
        // console.log(user_details.image)
      }

    } catch (error) {
      console.log(error.message)
      set_error_message('An error occured fetching seller profile');
    }

    // latitude and longitude check 

    // country check 

    let get_cities;
    try {
      get_cities = await getCities('id', 'meta', 'status');
      if (get_cities.success) {
        const { data } = get_cities;
        set_cities(data);
      }
    } catch (error) {
      // set_error_message(`An error occured fetching cities: ${error.message} `);
    }

    let get_countries;
    try {
      get_countries = await getCountries('id', 'meta', 'status');
      if (get_countries.success) {
        const { data } = get_countries;
        set_countries(data);
      }
    } catch (error) {
      // set_error_message(`An error occured fetching countries: ${error.message} `);
    }

    let get_suburbs;
    try {
      get_suburbs = await getSuburbs('id', 'meta', 'city', 'status');
      if (get_suburbs.success) {
        const { data } = get_suburbs;
        set_suburbs(data);
      }
    } catch (error) {
      // set_error_message(`An error occured fetching suburbs: ${error.message} `);
    }

    return;
  }

  /**
   * Updates user profile
   * @param {*} e 
   */
  const updateProfile = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    const update_data = {
      first_name,
      last_name,
      phone,
      email,
      telephone,
      display_name,
    };

    const response = await updateUserDetails(update_data, 'basic_detail');
    if (response.success === false) {
      set_error_message(response.message);

    } else {
      set_success_message('Account details successfully updated.');
    }
    set_action_loading(false);

    return;
  }


  const requestVerification = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    const update_data = {};

    const response = await updateUserDetails(update_data, 'request_verification');
    if (response.success === false) {
      set_error_message(response.message);

    } else {
      await get_user_profile()
      set_success_message('Verification request successfully sent.')

    }
    set_action_loading(false);

    return;
  }

  useEffect(() => {
    if (page_loaded === false) {
      get_user_profile();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  const country_changed = async (new_country) => {
    set_country(new_country)
    set_city('')
    set_suburb('')

    for (let i = 0; i < countries.length; i++) {
      if (countries[i].country === new_country) {
        set_country_meta(countries[i].meta)
        return;
      }
    }
  }

  const city_changed = async (new_city) => {
    set_city(new_city)
    set_suburb('')

    for (let i = 0; i < cities.length; i++) {
      if (cities[i].city === new_city) {
        // console.log(cities[i].meta)
        set_city_meta(cities[i].meta)
        return;
      }
    }
  }

  const organic_check_changed = async (new_organic_check) => {
    let _organic_check = new_organic_check
    if (new_organic_check === 'true') {
      _organic_check = true
    } else if (new_organic_check === 'false') {
      _organic_check = false
    }

    set_organic_check(_organic_check)
  }

  const provide_delivery_changed = async (new_provide_delivery) => {
    let _provide_delivery = new_provide_delivery
    if (new_provide_delivery === 'true') {
      _provide_delivery = true
    } else if (new_provide_delivery === 'false') {
      _provide_delivery = false
    }

    set_provide_delivery(_provide_delivery)
  }



  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2><span class="green-text">My</span> <span class="orange-text">Profile</span></h2>
          </div>


          {!page_loaded ? ( // so that only products will be loading at this time 
            <div>Loading ... </div>
          ) : (
            <>
              <div class="row">
                <div class="col-12">
                  {error_message !== '' ? (
                    <div class="alert alert-danger" role="alert">
                      {error_message}
                    </div>
                  ) : ('')}
                  {success_message !== '' ? (
                    <div class="alert alert-success" role="alert">
                      {success_message}
                    </div>
                  ) : ('')}
                </div>
              </div>

              <div class="row">
                <div class="col-md-3">
                  <div class="text-center">
                    {
                      seller_image === '' || seller_image === null || seller_image === undefined ? (
                        <img src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png" class="avatar img-circle img-thumbnail" alt="avatar" />
                      ) : (
                        <img src={`${API_URL}/${seller_image}`} class="avatar img-circle img-thumbnail" alt="avatar" />
                      )
                    }

                    <h4 class="mt-5">Upload a new profile photo</h4>

                    <form onSubmit={update_client_profile_picture}>
                      <input type="file" ref={el} onChange={handleChange} class="text-center center-block file-upload form-control" />

                      <button
                        class="btn btn-lg btn-success mt-1"
                        type="submit"
                        disabled={action_loading} >
                        Upload
                      </button>
                    </form>


                  </div>
                </div>

                <div class="col-md-9">
                  <hr />
                  <form onSubmit={updateProfile}>
                    <h3>Personal details</h3>
                    <div class="row">
                      <div class="form-group col-md-4 my-3">
                        <label for="first_name">Email</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "email",
                            name: "email",
                            type: "text",
                            placeholder: "Email"
                          }}
                          // validate={validate}
                          validationCallback={res =>
                            set_email_invalid(res)
                          }
                          value={email}
                          disabled={true}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(email, e) => {
                            set_email(e.target.value);
                          }}
                          // onBlur={e => { console.log(e);}}
                          // onFocus={(e) => {console.log(e);}}
                          // onClick={(e) => {console.log(e);}}
                          validationOption={{
                            name: "email",
                            check: true,
                            required: true,
                            type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2, 
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{18}|\d{15}$/,
                            // regMsg: 'failed in reg.test(${value})',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="last_name">First name</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "Name",
                            name: "Name",
                            type: "text",
                            placeholder: "First name"
                          }}
                          // validate={validate}
                          validationCallback={res =>
                            set_first_name_invalid(res)
                          }
                          value={first_name}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(first_name, e) => {
                            set_first_name(e.target.value);
                          }}
                          // onBlur={e => {console.log(e); }}
                          // onFocus={(e) => {console.log(e);}}
                          // onClick={(e) => {console.log(e);}}
                          validationOption={{
                            name: "Name",
                            check: true,
                            required: true,
                            type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2, 
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{18}|\d{15}$/,
                            // regMsg: 'failed in reg.test(${value})',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>


                      <div class="form-group col-md-4 my-3">
                        <label for="phone">Surname</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "Surname",
                            name: "Surname",
                            type: "text",
                            placeholder: "Surname"
                          }}
                          // validate={validate}
                          validationCallback={res =>
                            set_last_name_invalid(res)
                          }
                          value={last_name}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(last_name, e) => {
                            set_last_name(e.target.value);
                          }}
                          onBlur={e => {
                            console.log(e);
                          }}
                          // onFocus={(e) => {console.log(e);}}
                          // onClick={(e) => {console.log(e);}}
                          validationOption={{
                            name: "Surname",
                            check: true,
                            required: true,
                            type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2, 
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{18}|\d{15}$/,
                            // regMsg: 'failed in reg.test(${value})',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      </div>
                    <div class="form-group col-12 my-3">
                      <br />
                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        disabled={action_loading}
                      // check invalids 
                      >
                        <i class="glyphicon glyphicon-ok-sign"></i> Save details
                      </button>
                      {/* <button class="btn btn-lg" type="reset"><i class="glyphicon glyphicon-repeat"></i> Reset</button> */}
                    </div>
                  </form>

                  {/* <hr /> */}
                </div>
              </div>
            </>
          )}

        </div>
      </section>
    </>
  );
}
export default AdminProfileScreen;
